import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardUpdateService {
  private cardUpdatedSubject = new Subject<void>();
  cardUpdated$ = this.cardUpdatedSubject.asObservable();

  notifyCardUpdated() {
    this.cardUpdatedSubject.next();
  }
}
