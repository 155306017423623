import {Component, ElementRef, Injectable, ViewChild} from '@angular/core';
import {User} from "../models/user";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthFirebaseService} from "../service/auth-firebase.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider} from "@angular/fire/auth";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "../models/subscription";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../service/user.service";

@Component({
  selector: 'app-my-wallets',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})

@Injectable({
  providedIn: 'root',
})
export class MyProfileComponent {

  updateMailForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  updatePhoneForm: UntypedFormGroup;
  updatePhoneValidationCodeForm: UntypedFormGroup;
  errorFlowMsg: string;
  errorPasswordMsg: string;
  dataAvailable = false;
  emailInvalid = false;
  passwordInvalid = false;
  errorMsg: string;
  today = new Date();
  user: User;
  subscriptions: Subscription[];
  updateType: string;
  captcha = false;
  phoneInvalid = false;
  verificationId: string;

  @ViewChild('updateMailModal') updateMailModal: ElementRef;
  @ViewChild('updatingEmailAwaiting') updatingEmailAwaiting: ElementRef;
  @ViewChild('updatingPhoneAwaiting') updatingPhoneAwaiting: ElementRef;
  @ViewChild('updatingAccountResult') updatingAccountResult: ElementRef;
  @ViewChild('updatePhoneModal') updatePhoneModal: ElementRef;
  @ViewChild('updatePhoneCodeModal') updatePhoneCodeModal: ElementRef;
  @ViewChild('updatingAccountResultPhone') updatingAccountResultPhone: ElementRef;

  @ViewChild('errorModal') errorModal: ElementRef;
  @ViewChild('passwordModal') passwordModal: ElementRef;
  constructor(
    private fireService: AuthFirebaseService,
    private modal: NgbModal,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private userService: UserService
  ) {
    this.initForm();
    const userProfileData = this.activateRoute.snapshot.data['userData']
    if (userProfileData === undefined) {
      this.route.navigate(['/account'])
    } else {
      this.user = userProfileData[0]
      this.subscriptions = userProfileData[1]
      this.dataAvailable = true;
    }
  }

  initForm(): void {
    this.updateMailForm = this.fb.group({
      newMail: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    this.updatePhoneForm = this.fb.group({
      newPhone: new UntypedFormControl('', [Validators.required])
    });

    this.updatePhoneValidationCodeForm = this.fb.group({
      validationCode: new UntypedFormControl('', [Validators.required])
    });

    this.passwordForm = this.fb.group({
      password: new UntypedFormControl('', [Validators.required])
    });

  }
/*
  reAuthenticate() {
    this.passwordForm.controls.password.setValue('');
    this.updateMailForm.controls.newMail.setValue('');
    this.errorFlowMsg = '';
    const user = this.fireService.getUserFire();
    if (user) {
      const userProviders = user.providerData;
      if (userProviders.length > 0) {
        const userPasswordProvider = userProviders.find(v => v.providerId == 'password')
        if (userPasswordProvider == undefined || (userPasswordProvider != undefined && userProviders.length > 1)) {
          this.reAuthenticateWithProvider(userProviders)
        } else {
          this.openPassworProviderModal()
        }
      } else {
        this.errorFlowMsg = 'no provider found'
        this.openErrorModal()
      }
    } else {
      this.errorFlowMsg = 'no user found'
      this.openErrorModal()
    }
  }
*/
  reAuthenticate(type: string) {
    if(type !== "email" && type !== "phone"){
      this.errorFlowMsg = 'invalid type'
      this.openErrorModal()
      return
    }
    this.updateType = type;
    this.passwordForm.controls.password.setValue('');
    this.updateMailForm.controls.newMail.setValue('');
    this.errorFlowMsg = '';
    const user = this.fireService.getUserFire();
    if (user) {
      const userProviders = user.providerData;
      if (userProviders.length > 0) {
        const userPasswordProvider = userProviders.find(v => v.providerId == 'password')
        if (userPasswordProvider == undefined || (userPasswordProvider != undefined && userProviders.length > 1)) {
          this.reAuthenticateWithProvider(userProviders)
        } else {
          this.openPassworProviderModal()
        }
      } else {
        this.errorFlowMsg = 'no provider found'
        this.openErrorModal()
      }
    } else {
      this.errorFlowMsg = 'no user found'
      this.openErrorModal()
    }
  }

  reAuthenticateWithProvider(providers) {
    const provider = this.selectProvider(providers)
    const user = this.fireService.getUserFire();
    if (user) {
      this.fireService._reauthenticateWithPopup(provider).then(
        () => {
          if(this.updateType === "email"){
            this.openUpdateMailModal()
          } else {
            this.openPhoneModal()
          }
        }
      ).catch(
        reason => {
          this.errorFlowMsg = reason
          this.openErrorModal()
        }
      )
    } else {
      this.errorFlowMsg = 'no user found';
      this.openErrorModal()
    }
  }

  openPhoneModal() {
    this.captcha = false;
    this.updatePhoneForm.controls.newPhone.setValue('');
    this.updatePhoneValidationCodeForm.controls.validationCode.setValue('');
    this.openModal(this.updatePhoneModal)
  }

  openPassworProviderModal() {
    this.openModal(this.passwordModal)
  }

  openErrorModal() {
    this.openModal(this.errorModal)
  }

  openModal(template): void {
    this.closeModal();
    this.modal.open(template, { centered: true, windowClass: 'my-modal-content' });
  }

  closeModal(): void {
    this.modal.dismissAll();
  }

  openUpdateMailModal() {
    this.openModal(this.updateMailModal)
  }

  selectProvider(providers) {
    const isGoogle = providers.find(v => v.providerId === 'google.com') != undefined
    const isApple = providers.find(v => v.providerId == 'apple.com') != undefined
    const isFacebook = providers.find(v => v.providerId == 'facebook.com') != undefined
    if (isGoogle) {
      return new GoogleAuthProvider()
    } else if (isApple) {
      return new OAuthProvider('apple.com')
    } else if (isFacebook) {
      return new FacebookAuthProvider()
    }
  }

  updateMail(): void {
    this.emailInvalid = false;
    this.errorMsg = ''
    if (this.updateMailForm.valid) {
      const newMail = this.updateMailForm.controls.newMail.value
      this.openModal(this.updatingEmailAwaiting)
      this.fireService._updateEmail(newMail).then(
        () => {
          this.openModal(this.updatingAccountResult)
          this.fireService._sendVerificationMail()
        }
      ).catch(reason => {
        this.errorMsg = reason
        this.openModal(this.updatingAccountResult)
      })
    } else {
      this.emailInvalid = true;
    }
  }

  closeResultModal(): void {
    if (this.errorMsg) {
      this.closeModal();
      setTimeout(() => {
        this.errorMsg = '';
      }, 500);
    } else {
      this.closeModal();
      this.logout();
    }
  }

  logout(): void {
    this.route.navigate(['/logout']);
  }

  reAuthenticateWithPassword() {
    this.errorPasswordMsg = '';
    const user = this.fireService.getUserFire()
    if (user) {
      this.fireService._reauthenticateWithCredential(
        EmailAuthProvider.credential(user.email, this.passwordForm.controls.password.value)
      ).then(() => {
          if(this.updateType === "email"){
            this.openUpdateMailModal()
          } else {
            this.openPhoneModal()
          }
        }
      ).catch(
        reason => {
          this.errorPasswordMsg = reason
          this.openErrorModal()
        }
      )
    } else {
      this.errorFlowMsg = 'No user'
      this.openErrorModal()
    }
  }

  getStatus(sub: Subscription) {
    if (sub.startDate > this.today) {
      return this.translate.instant('myProfile.waiting')
    } else if (this.today > sub.endDate) {
      return this.translate.instant('myProfile.expired')
    } else {
      return this.translate.instant('myProfile.active')
    }
  }


  updatephone() {
    this.phoneInvalid = false;
    this.errorMsg = ''
    if (this.updatePhoneForm.valid) {
      const recaptchaContainer = document.getElementById('recaptcha-container');
      const recaptchaParameters = {
        size: 'normal',
        callback: (_) => {
          this.openModal(this.updatePhoneCodeModal)
        }
      };
      const phoneNumber = this.updatePhoneForm.controls.newPhone.value
      this.captcha = true;
      this.fireService._verifyPhoneNumber(recaptchaContainer, recaptchaParameters, phoneNumber).then(
        (verificationId) => {
          this.verificationId = verificationId
        }
      ).catch(reason => {
        this.errorMsg = reason
        this.openModal(this.updatingAccountResultPhone)
      })
    } else {
      this.phoneInvalid = true;
    }
  }

  valideCode() {
    const code = this.updatePhoneValidationCodeForm.controls.validationCode.value
    this.fireService._getPhoneAuthCredential(this.verificationId, code).then(
      (credential) => {
        this.openModal(this.updatingPhoneAwaiting)
        this.fireService._updatePhoneNumber(credential).then(
          () => {
            this.userService.updateProfilePhone(this.updatePhoneForm.controls.newPhone.value).subscribe(() => {
              console.log('phone updated')
              setTimeout(() => {
                this.route.navigate(['/logout'])
              }, 2000)
            })
            this.openModal(this.updatingAccountResultPhone)
          }).catch(reason => {
          this.errorMsg = reason
          this.openModal(this.updatingAccountResultPhone)
        })
      }
    ).catch(reason => {
      this.errorMsg = reason
      this.openModal(this.updatingAccountResultPhone)
    })
  }
}
