<div class="main-container">
  <div class="container" [ngClass]="{ 'h-100': maxForms !== 4 }">
    <div class="header-container">
      <div class="club-icon-container">
        <img
          src="https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/promotion/{{
            imgPartner
          }}.png"
          alt="partner club"
          width="100"
          height="67"
        />
      </div>
      <h4 class="title">
        {{ 'voucher.have_code' | translate }} {{ partner }} ?
      </h4>
      <ul>
        <li>Un seul couple code/email est obligatoire.</li>
        <li>Le mail doit être lié au compte gymlib associé au code.</li>
        <li>
          Chaque couple code/email doit appertenir à une personne differente.
        </li>
        <li>Chaque code ajouté permet de réduire le restant à payer.</li>
      </ul>
      <p>
        <a
          href="https://support.anybuddyapp.com/fr/article/reserver-avec-vos-pass-gymlib-dfydrj/"
          target="_blank"
          >Plus d'information sur notre page support</a
        >
      </p>
    </div>
    <div class="form-container">
      <div *ngFor="let formGym of formsGymlib; let i = index" class="d-flex">
        <div class="form-container">
          <form [formGroup]="formGym" class="form">
            <input
              type="text"
              class="input"
              [formControlName]="'id'"
              placeholder="{{
                'voucher.player_code' | translate : { number: i + 1 }
              }}"
            />
            <span
              class="error"
              *ngIf="
                isFieldInvalid(formGym, 'id') &&
                formGym.controls.id.hasError('required')
              "
              role="alert"
              aria-live="assertive"
              >{{ 'voucher.field_required' | translate }}</span
            >
            <span
              class="error"
              *ngIf="
                isFieldInvalid(formGym, 'id') &&
                formGym.controls.id.hasError('maxLength')
              "
              role="alert"
              aria-live="assertive"
              >{{ 'voucher.max_length' | translate }}</span
            >
            <span
              class="error"
              *ngIf="
                isFieldInvalid(formGym, 'id') &&
                formGym.controls.id.hasError('allowedCharacters')
              "
              role="alert"
              aria-live="assertive"
              >{{ 'voucher.allowed_characters' | translate }}</span
            >
            <span
              class="error"
              *ngIf="
                isFieldInvalid(formGym, 'id') &&
                formGym.controls.id.hasError('duplicateId')
              "
              role="alert"
              aria-live="assertive"
              >{{ 'voucher.duplicate_id' | translate }}</span
            >
            <input
              type="text"
              class="input"
              [formControlName]="'mail'"
              placeholder="{{
                'voucher.player_mail' | translate : { number: i + 1 }
              }}"
            />
            <span
              class="error"
              *ngIf="
                isFieldInvalid(formGym, 'mail') &&
                formGym.controls.mail.hasError('required')
              "
              role="alert"
              aria-live="assertive"
              >{{ 'voucher.field_required' | translate }}</span
            >
            <span
              class="error"
              *ngIf="
                isFieldInvalid(formGym, 'mail') &&
                formGym.controls.mail.hasError('email')
              "
              role="alert"
              aria-live="assertive"
              >{{ 'voucher.invalid_mail' | translate }}</span
            >
            <span
              class="error"
              *ngIf="
                isFieldInvalid(formGym, 'mail') &&
                formGym.controls.mail.hasError('duplicateEmail')
              "
              role="alert"
              aria-live="assertive"
              >{{ 'voucher.duplicate_email' | translate }}</span
            >
          </form>
        </div>
      </div>
      <div class="error-container" *ngIf="error">
        <span class="errorRequest">
          {{ errorMsg || ('voucher.request_error' | translate) }}
        </span>
      </div>

      <div class="d-flex my-3">
        <button
          [disabled]="loading || !areAllFormsValid()"
          class="submit mx-auto"
          data-dismiss="modal"
          (click)="onSubmit()"
        >
          <ng-container *ngIf="!loading">
            {{ 'voucher.confirm' | translate }}
          </ng-container>
          <div *ngIf="loading" class="loading-spinner"></div>
        </button>
      </div>
    </div>
  </div>
</div>
