// List of activity
export declare interface ActivityInfo {
  activity: string;
  formatedName: string;
  icon: string;
}

export const ACTIVITY_LIST: ActivityInfo [] = [
{ activity: 'tennis', formatedName: 'Tennis', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1624005059/assets/activities/decathlon_tennis.jpg' },
{ activity: 'badminton', formatedName: 'Badminton', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1624005059/assets/activities/decathlon_badminton.jpg' },
{ activity: 'padel', formatedName: 'Padel', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1624005059/assets/activities/decathlon_padel.jpg' },
{ activity: 'squash', formatedName: 'Squash', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1624005059/assets/activities/decathlon_squash.jpg' },
{ activity: 'fitness', formatedName: 'Fitness', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1628844055/assets/activities/decathlon_fitness.jpg' },
{ activity: 'team-training', formatedName: 'Team Training', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1628844055/assets/activities/decathlon_fitness.jpg' },
{ activity: 'muscle-strengthening', formatedName: 'Muscle Strengthening', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1632122480/assets/activities/decathlon_musculaire.jpg' },
{ activity: 'cardio', formatedName: 'Cardio', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1632122480/assets/activities/decathlon_cardio.jpg' },
{ activity: 'danse', formatedName: 'Danse', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1632122480/assets/activities/decathlon_danse.jpg' },
{ activity: 'relaxation-well-being', formatedName: 'Relaxation Well Being', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1632122480/assets/activities/decathlon_detente.jpg' },
];

export const RAQUETTE_ACTIVITY_LIST: ActivityInfo [] = [
  { activity: 'tennis', formatedName: 'Tennis', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1624005059/assets/activities/decathlon_tennis.jpg' },
  { activity: 'badminton', formatedName: 'Badminton', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1624005059/assets/activities/decathlon_badminton.jpg' },
  { activity: 'padel', formatedName: 'Padel', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1624005059/assets/activities/decathlon_padel.jpg' },
  { activity: 'squash', formatedName: 'Squash', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1624005059/assets/activities/decathlon_squash.jpg' }
];

export const FITNESS_ACTIVITY_LIST: ActivityInfo [] = [
  { activity: 'fitness', formatedName: 'Fitness', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1628844055/assets/activities/decathlon_fitness.jpg' },
  { activity: 'team-training', formatedName: 'Team Training', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1628844055/assets/activities/decathlon_fitness.jpg' },
  { activity: 'muscle-strengthening', formatedName: 'Muscle Strengthening', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1632122480/assets/activities/decathlon_musculaire.jpg' },
  { activity: 'cardio', formatedName: 'Cardio', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1632122480/assets/activities/decathlon_cardio.jpg' },
  { activity: 'danse', formatedName: 'Danse', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1632122480/assets/activities/decathlon_danse.jpg' },
  { activity: 'relaxation-well-being', formatedName: 'Relaxation Well Being', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1632122480/assets/activities/decathlon_detente.jpg' },
  { activity: 'aquagym', formatedName: 'Aquagym', icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1632122480/assets/activities/decathlon_detente.jpg' },
];
