import { Blog } from "./blog";

export class PostMeta {
  constructor(
    public title: string,
    public description: string,
  ) { }
}
export class Post {
  /* eslint-disable @typescript-eslint/dot-notation */
  constructor(
    public title: string,
    public content: string,
    public excerpt: string,
    public meta: PostMeta,
    public link: string,
    public related: Blog[],
    public image: string,
  ) { }

  public static fromJson(json: object): Post {
    if (json) {
      return new Post(
        json['title']['rendered'],
        json['content']['rendered'],
        json['excerpt']['rendered'],
        new PostMeta(json['meta']['jetpack_seo_html_title'], json['meta']['advanced_seo_description']),
        json['link'],
        json['jetpack-related-posts'].map(p => Blog.RelatedfromJson(p)),
        json['jetpack_featured_media_url'],
      );
    } else {
      return null;
    }
  }
  /* eslint-enable @typescript-eslint/dot-notation */
}
