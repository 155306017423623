import { HttpResponse } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from "ngx-device-detector";
import { Blog } from "../models/blog";
import { WordPressService } from "../service/wordpress.service";
import { BreakpointService } from "../../shared/services/breakpoint.service";

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss'],
})
export class BlogListComponent implements OnInit {
  blogs: Blog[];
  isMobileResolution: boolean;
  loading = true;
  error = null;

  currentPage = 1;
  offSet = 9;
  totalBlogs = 0;
  totalPage = 0;

  constructor(
    private wordpressService: WordPressService,
    private deviceService: DeviceDetectorService,
    private breakpointService: BreakpointService,

  ) {
    this.breakpointService.isMobile().subscribe(isMobile => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  ngOnInit(): void {
    this.getBlogs()
  }

  changePage(page: number) {
    this.currentPage = page;
    this.getBlogs()
  }

  private getBlogs() {
    this.loading = true;
    this.blogs = [];
    this.wordpressService.getBlogs(this.currentPage, this.offSet).subscribe({
      next: (blogs: HttpResponse<any>) => {
        this.totalBlogs = Number(blogs.headers.get('x-wp-total'));
        blogs.body.map(blogs => {
          this.blogs.push(Blog.fromJson(blogs));
        })
        this.loading = false;
        this.totalPage = Math.ceil(this.totalBlogs / this.offSet);
      },
      error: (error) => {
        this.loading = false;
        this.error = error.message;
        console.error(error);
      }
    })
  }
}
