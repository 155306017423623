<div class="global">
  <app-top-navbar class="nav-bar" *ngIf="!isMobileResolution" [country]="country" [isYellowLogo]="false">
    <div class="row" app-top-navbar-right>
      <a [routerLink]="['/' + language, 'on-boarding']" class="w-auto">
        <button class="navbar-btn border-0 me-0" routerLinkActive="active">
          {{'menuComponent.add_your_center' | translate}}
        </button>
      </a>
      <app-button-account-disconnected class="w-auto"></app-button-account-disconnected>
    </div>

  </app-top-navbar>

  <div class="d-flex-column" style="flex-grow: 1">

    <div class="validation-buddy-container">
      <div class="validation-content">
        <div class="superbuddy">
          <span class="super">{{ 'succes_resa.super' | translate}}</span>
          <span class="buddy">{{ 'succes_resa.buddy' | translate}}</span>
        </div>
        <h2 class="title">{{ (awaitingValidation? 'succes_resa.title_awaiting' : 'succes_resa.title') | translate}}</h2>
        <p class="success-resa-text">{{ (awaitingValidation? 'succes_resa.content_awaiting' :'succes_resa.content') | translate}}</p>
      </div>
    </div>

    <div class="container justify-content-center pt-3">
      <div class="choice-container">

        <div class="valid-resa choice">
          <div class="resa-img-container">
            <img src="{{awaitingValidation?
            'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/icon/enattente_resa.svg' :
            'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677169402/assets/buddy/buddy_amoureux.svg'}}
            " [class.w-140]="awaitingValidation">
          </div>
          <p>{{ 'succes_resa.succes_resa_text' | translate | uppercase }}</p>
          <button (click)="Resa()" class="btn btn-resa rounded-pill">{{ 'succes_resa.btn_resa' | translate}}</button>
        </div>

        <div class="tell-buddy choice">
          <div class="tell-budy-img-container">
            <img ngSrc="v1677169402/assets/icon/partenaire.png" alt="reservation confirmée" width="338" height="338">
          </div>
          <p>{{ 'succes_resa.warn_mate' | translate | uppercase }}</p>
          <button id="copy" (click)="mate()" class="btn btn-resa rounded-pill">{{ 'succes_resa.share_resa' | translate}}
            <span class="copiedtext" aria-hidden="true">{{ 'succes_resa.copied' | translate}}</span>
          </button>
        </div>

        <div class="dl-app choice">
          <div class="dl-app-img-container">
            <img ngSrc="v1677169402/assets/icon/dl-app.png" alt="reservation confirmée" width="338" height="338">
          </div>
          <p>{{ 'succes_resa.download_app' | translate | uppercase }}</p>
          <a (click)="downloadAppClick()" href="https://anybuddygo.page.link/app" target="_blank"><button class="btn btn-resa rounded-pill">{{ 'succes_resa.dl_app' | translate}}</button></a>
        </div>
      </div>
    </div>
  </div>
</div>
