<div class="container-main my-5">
  <div class="row w-100 h-100 m-0 content">
    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-5 mx-auto max-width">
      <div class="login-card">
        <div class="title-card">
          <h1 class="title">
            {{ 'white_label_register.registration_title' | translate }}
          </h1>
        </div>
        <ng-container *ngTemplateOutlet="loginForm"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #loginForm>
  <div class="login-form">

    <div class="input-elements col-12 col-md-10">
      {{ 'white_label_register.i_am' | translate }}
      <div style="display: flex; align-items: center;">
        <span class="required mx-2">*</span>
        <select class="custom-input" [formControl]="membership">
          <option value="membre_pacha">{{ 'white_label_register.member_pacha' | translate }}</option>
          <option value="frequence">{{ 'white_label_register.frequence' | translate }}</option>
          <option value="non_membre">{{ 'white_label_register.non_member' | translate }}</option>
        </select>
      </div>
      <div class="text-danger">
        <div class="d-flex">
          <p class="mx-auto error-msg">
            {{ getErrorMessage(this.membership, 'membership') }}
          </p>
        </div>
      </div>
    </div>

    <div class="row connexion-option" *ngIf="this.membership.value === 'membre_pacha' ||this.membership.value === 'frequence'">
      <div class="input-elements col-12 col-md-10 my-4">
        <div class="form-check">
          <label class="form-check-label">
            {{ 'white_label_register.member_message' | translate }}
          </label>
        </div>

        <div>
          <input
            class="custom-input-not-required mt-3"
            placeholder="{{ 'white_label_register.memberId' | translate }} "
            type="text"
            [formControl]="memberId"
            inputmode="numeric"
            pattern="^[0-9]*$"
            maxlength="6"
          />
        </div>
        <div class="text-danger">
          <div class="d-flex">
            <p class="mx-auto error-msg">
              {{ getErrorMessage(this.memberId, 'memberId') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="input-elements col-12 col-md-10">
      <div style="display: flex; align-items: center;">
      <span class="required mx-2">*</span>
      <input
        class="custom-input"
        placeholder="{{ 'white_label_register.firstName' | translate }}"
        type="text"
        [formControl]="firstName"
      />
      </div>
      <div class="text-danger">
        <div class="d-flex">
          <p class="mx-auto error-msg">
            {{ getErrorMessage(this.firstName, 'firstname') }}
          </p>
        </div>
      </div>
    </div>

    <div class="input-elements col-12 col-md-10">
      <div style="display: flex; align-items: center;">
        <span class="required mx-2">*</span>
      <input
        class="custom-input"
        placeholder="{{ 'white_label_register.lastName' | translate }}"
        type="text"
        [formControl]="lastName"
      />
      </div>
      <div class="text-danger">
        <div class="d-flex">
          <p class="mx-auto error-msg">
            {{ getErrorMessage(this.lastName, 'lastname') }}
          </p>
        </div>
      </div>
    </div>

    <div class="input-elements col-12 col-md-10">
      <div style="display: flex; align-items: center;">
        <span class="required mx-2">*</span>
      <input
        class="custom-input"
        placeholder="{{ 'white_label_register.dateofbirth' | translate }}"
        type="date"
        [formControl]="dateOfBirth"
      />
      </div>
      <div class="text-danger">
        <div class="d-flex">
          <p class="mx-auto error-msg">
            {{ getErrorMessage(this.dateOfBirth, 'dateofbirth') }}
          </p>
        </div>
      </div>
    </div>
    <div class="input-elements col-12 col-md-10">
      <div style="display: flex; align-items: center;">
        <span class="required mx-2">*</span>
      <input
        class="custom-input"
        placeholder="{{ 'white_label_register.register_email' | translate }}"
        type="email"
        [formControl]="email"
      />
      </div>
      <div class="text-danger">
        <div class="d-flex">
          <p class="mx-auto error-msg">
            {{ getErrorMessage(this.email, 'email') }}
          </p>
        </div>
      </div>
    </div>

    <div class="input-elements col-12 col-md-10">
      <div style="display: flex; align-items: center;">
        <span class="required mx-2">*</span>
      <input
        class="custom-input"
        placeholder="{{ 'white_label_register.password' | translate }}"
        [formControl]="password"
        type="password"
        required
      />
      </div>
      <div class="text-danger">
        <div class="d-flex">
          <p class="mx-auto error-msg">
            {{ getErrorMessage(this.password, 'password') }}
          </p>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="text-danger">
        <div *ngIf="error" class="d-flex">
          <p class="mx-auto error-msg">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-8 mx-auto mt-2 mb-3">
      <button
        class="email-link-btn"
        (click)="signIn()"
        [disabled]="isRegister"
        [ngClass]="{ 'btn-disabled': isRegister }"
      >
        {{ 'white_label_register.registration_title' | translate }}
      </button>
    </div>
    <div class="col-12">
      <div class="container-forget-pwd d-flex mt-3">
        <a routerLink="/login" class="mx-auto">
          <span class="forget-pwd">{{
            'white_label_register.connect_link' | translate
          }}</span>
        </a>
      </div>
    </div>
  </div>
</ng-template>
