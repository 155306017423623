import { Component, Injectable } from '@angular/core';
import {CentersService} from "../service/center.service";
import {Wallets} from "../models/wallets";

@Component({
  selector: 'app-my-wallets',
  templateUrl: './my-wallets.component.html',
  styleUrls: ['./my-wallets.component.scss']
})

@Injectable({
  providedIn: 'root',
})
export class MyWalletsComponent {

  wallets: Wallets;
  dataAvailable = false;
  globalWalletAmount: number;
  globalWalletCurrency: string;
  constructor(
    private centerService: CentersService,
  ) {
    this.getWallet();
  }

  getWallet(): void {
    this.centerService.getWallet().subscribe({
      next: (data) => {
        this.wallets = data;
        if(this.wallets.globalWallet) {
          this.globalWalletAmount = this.wallets.globalWallet.amount;
          this.globalWalletCurrency = this.wallets.globalWallet.currency;
        } else {
          this.globalWalletAmount = 0;
          this.globalWalletCurrency = 'EUR';
        }
        this.dataAvailable = true;
      }, error: (error) => {
        console.log(error);
      }
    });
  }

}
