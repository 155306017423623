<div class="d-flex align-items-center" *ngIf="!footer">
  <div *ngIf="selectedLang && langs" ngbDropdown class="select-language">
    <button class="btn lang-select" [class.black-text]="yellow" [id]="idSelector2" ngbDropdownToggle>
      <span class="country-code">
        {{ selectedLang.languageCode | uppercase}}
      </span>
    </button>
    <div ngbDropdownMenu attr.aria-labelledby="{{idSelector2}}">
      <span class="dropdown-item language-option"> {{ "languagesComponent.language" | translate }} : </span>
      <button ngbDropdownItem *ngFor="let lang of showedLangList" (click)="onSwitchLang(lang.trueLanguageCode)"
        class="language-option">
        <span>
          {{ lang.languageCode | uppercase }}
        </span>
      </button>
    </div>
  </div>
</div>


<div *ngIf="footer">
  <div>
    <h2 class="modal-title">{{'languagesCountriesLang.chooseCountry' | translate}}</h2>

    <div class="row flex-wrap mt-3">
      <div class="col-6 mb-2" *ngFor="let country of countries">
        <button (click)="onSwitchCountry(country.countryCode)" class="language-selector"
          [ngClass]=" isCountryChosen(country) ? 'language-selector-selected' : ''">
          <img [ngClass]="flagOnly ? 'flag-icon-dropdown' : 'flag-icon'" loading="lazy" src="{{country.icon}}"
            alt="{{country.name}} Flag" width="40" height="27">
          <span>{{ ('languagesCountriesLang.' + country.name + '_ctry') | translate }}</span>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="selectedLangFooter">
    <h2 class="modal-title">{{'languagesCountriesLang.chooseLang' | translate}}</h2>

    <div class="row flex-wrap mt-3">
      <div class="col-6 mb-2" *ngFor="let lang of showedLangList">
        <button (click)="onSwitchLang(lang.trueLanguageCode)" class="language-selector"
          [ngClass]=" isLangageChosen(lang) ? 'language-selector-selected' : ''">
          <span>{{('languagesCountriesLang.' + lang.languageCode) | translate}} ({{ lang.trueLanguageCode | uppercase
            }})
            <svg *ngIf="isLangageChosen(lang)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
              height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="rgba(255,195,0,1)" />
            </svg>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>