<!-- favorite list -->
<div class="container">
  <div class="header">
    <h1 class="title"> {{ 'my_bookings.my_account' | translate }} </h1>
  </div>
  <hr>
  <div class="title-container mt-5 mb-2">
    <h2 class="sub-title">{{ 'wallets.my_wallets' | translate }}</h2>
    <p class="text">{{ 'wallets.find_wallets' | translate }}</p>
  </div>
  <ng-container *ngIf="!dataAvailable">
    <app-loader></app-loader>
  </ng-container>
  <div *ngIf="dataAvailable">
    <div  class="row no-favoris-container" *ngIf="globalWalletAmount>0">
      <div class="col-12 col-md-2 image-container px-4">
        <img class="h-auto" ngSrc="v1677169402/assets/buddy/buddy_clin_doeil_165x165.png" width="128" height="158">
        <h5 class="d-block d-md-none">{{'wallets.available_anybuddy' | translate}}</h5>
      </div>
      <div class="col-12 col-md-5 text-container mt-3 mt-md-0 px-4 d-flex">
        <h5 class="d-none d-md-block m-auto">{{'wallets.available_anybuddy' | translate}}</h5>
      </div>
      <div class="col-12 col-md-5 button-container mt-3 mb-3 mt-md-0 mb-md-0 px-4">
        <span class="amount m-auto" [ngClass]="{'filledWallet': globalWalletAmount>0, 'emptyWallet': globalWalletAmount == 0}">{{globalWalletAmount/100 | currency: globalWalletCurrency}}</span>
      </div>
    </div>

    <div>
      <div class="p-0 m-0" *ngIf="wallets.centersWallets.length> 0 ">

        <div class="my-2 title-container">
          <h4 class="text-center sub-title mt-3">{{ 'wallets.your_center_wallet' | translate}}</h4>
        </div>

        <div class="row no-favoris-container mt-3 d-flex" *ngFor="let wallet of wallets.centersWallets">
          <div class="col-12 col-md-7 d-flex">
            <ul>
              <li class="mx-auto mx-md-0" *ngFor="let center of wallet.centers">{{center.centerName}}</li>
            </ul>
          </div>
          <div class="col-12 col-md-5 d-flex">
            <span class="amountCenter m-auto" [ngClass]="{'filledWallet': wallet.amount>0, 'emptyWallet': wallet.amount == 0}">{{wallet.amount/100 | currency: wallet.currency}}</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="globalWalletAmount <=0 && wallets.centersWallets.length == 0" class="d-flex mt-4">
      <span class="m-auto no-wallet">{{'wallets.no_wallets' | translate}}</span>
    </div>

  </div>
</div>

