import {Injectable} from '@angular/core';
import {RouterService} from "./router.service";
import {BehaviorSubject} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class WhiteLabelFooterService{

  private showFooterSubject = new BehaviorSubject(true);
  public showFooterObservable = this.showFooterSubject.asObservable();

  constructor(
    private routerService: RouterService,
  ) {
    this.routerService.currentUrlObservable.subscribe(()=> {
      this.footerVisible();
    });
  }

  footerVisible(): void {
    this.showFooterSubject.next(true);
  }

  footerInvisible(): void {
    this.showFooterSubject.next(false);
  }

}
