import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Post } from '../models/post';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WordPressService {

  private host = `${environment.host}/api`;

  constructor(private http: HttpClient) { }
  loadEmbedText(type: string, sport: string, city?: string): Observable<Post[]> {
    let url = `${this.host}/wp-json/wp/v2/pages?slug=embed-${type}-${sport}`;
    if (city) {
      url = `${this.host}/wp-json/wp/v2/pages?slug=embed-${type}-${sport}-${city}`;
    }
    return this.http.get<Post[]>(url)
      .pipe(
        map(res => res.map(post => Post.fromJson(post)))
      );
  }

  loadPage(slug: string): Observable<Post> {
    const url = `${this.host}/wp-json/wp/v2/pages?slug=${slug}`;
    return this.http.get<Post>(url)
      .pipe(
        map(res => Post.fromJson(res[0]))
      );
  }

  loadPost(slug: string): Observable<Post> {
    const url = `${this.host}/wp-json/wp/v2/posts?slug=${slug}`;
    return this.http.get<Post[]>(url)
      .pipe(
        map(res => res && res.length ? Post.fromJson(res[0]) : null)
      );
  }

  getBlogs(page: number, perPage = 9): Observable<any> {
    const params = new HttpParams()
      .set('page', page)
      .set('per_page', perPage);

    const url = `${this.host}/wp-json/wp/v2/posts`;
    return this.http.get<any>(url, {params: params, observe: 'response'});
  }
}
