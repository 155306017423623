import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { SearchService } from '../service/search.service';
/* eslint-disable */
@Injectable({
  providedIn: 'root'
})

export class CityResolver implements Resolve<any> {

  constructor(private searchService: SearchService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.searchService.getGeonameBySlug(route.paramMap.get('city')).pipe(
      map((res: any) => {
        if (res) {
          return {
            slug: res["slug"],
            location: res["location"],
            cityCodePostale: res["name"]
          };
        }
      })
    );
  }
}
