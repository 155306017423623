import { Component, Injectable } from '@angular/core';
import { Center } from '../models/center';
import { FavoriteService } from '../service/favorite.service';

@Component({
  selector: 'app-my-favorite',
  templateUrl: './my-favorite.component.html',
  styleUrls: ['./my-favorite.component.scss']
})

@Injectable({
  providedIn: 'root',
})
export class MyFavoriteComponent {

  favoriteList: Array<Center>;
  dataAvailable = false;

  constructor(
    private favoriteService: FavoriteService,
  ) { 
    this.updateList();
  }

  manageFavorite(id: string): void {
    this.favoriteService.addOrRemoveFavoriteIfExist(id);
    this.updateList();
  }

  updateList(): void {
    this.favoriteList = this.favoriteService.getFavoriteCenter();
  }

}
