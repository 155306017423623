<div class="main">
    <div class="banner">
        <span>{{'center_infos_club.not_bookable' | translate  : {name: center.name} }}
            <a href="https://pro.anybuddyapp.com/inscription" (click)="addClubInLocalStorage()" class="ms-2 link">
                {{'center_infos_club.reference' | translate }}
            </a>
        </span>
    </div>
    <div class="content row">
        <div class="booking-container col-12 col-md-6 col-lg-5 col-xl-4">
            <div class="name-phone-container-mobile">
                <div class="d-flex flex-column mb-3" *ngIf="center && center.longDescription">
                    <span class="title"> {{'center_infos_club.description' | translate }}</span>
                    <span class="text-justify" [innerHtml]="center.longDescription"></span>
                </div>
                <div class="d-flex flex-column mb-2" *ngIf="isLoggedIn">
                    <span class="title"> {{'center_infos_club.contact' | translate }}</span>
                    <div class="d-flex">
                        <span> {{'center_infos_club.phone' | translate }} : </span>
                        <a class="phone" type="tel" href="tel:{{center.phoneNumber}}">{{center.phoneNumber}}</a>
                    </div>
                </div>
            </div>
            <div class="booking-content">
                <span class="title mb-2"> {{'center_infos_club.booking_title' | translate }}</span>
                <span class="mb-2"> {{'center_infos_club.booking_content' | translate }}</span>
                <span *ngIf="!isLoggedIn" class="mb-4"> <i class="note"> {{'center_infos_club.booking_note' | translate }} </i> </span>
                <a [routerLink]="['/' + country + '/login?redirectUrl='+getUrl]" *ngIf="!isLoggedIn">
                    <button class="booking-btn-login">
                        {{'center_infos_club.booking_btn_login' | translate }}
                    </button>
                </a>
                <div *ngIf="isLoggedIn">
                    <div *ngIf="error === null">
                        <div class="section date-container">
                            <span class="section-txt"> {{'center_infos_club.booking_date' | translate }} </span>
                            <div class="calandar select-custom" #calendarDrop="ngbDropdown" ngbDropdown [autoClose]="false" (openChange)="onCalendarOpen($event, slotDrop)" >
                                <span id="dropdownBasic1" ngbDropdownToggle class="date-picker">{{ dayDisplay }}</span>
                                <div class="calendar-container dropdown-container" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <div ngbDropdownItem class="calendar">
                                        <ngb-datepicker #dp [(ngModel)]="model" [minDate]="startDate" [startDate]="model"></ngb-datepicker>
                                        <div class="buttons-container">
                                            <button class="btn-close" (click)="calendarDrop.close()" > {{'menuComponent.close' | translate}} </button>
                                            <button class="btn-apply" [ngStyle]="{'background-color':'#8c6fff'}" (click)="onDateChanged(calendarDrop)" > {{'menuComponent.apply' | translate}} </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section time-container">
                            <span class="section-txt"> {{'center_infos_club.booking_hours' | translate }} </span>
                            <div #slotDrop="ngbDropdown" ngbDropdown (openChange)="onTimeSlotOpen($event, calendarDrop)"  class="select-custom">
                                <span id="dropdownBasic1" ngbDropdownToggle class="date-picker">{{ slotSelected }}</span>
                                <div class="items-container dropdown-container" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <div *ngFor="let hour of hours"
                                        ngbDropdownItem
                                        class="items"
                                        (click)="onTimeChanged(hour, slotDrop)">
                                            {{ hour }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn-inform-club" (click)="onSubmitDemand()"> {{'center_infos_club.inform_club' | translate }}  </button>
                    </div>
                    <div *ngIf="error === true || error === false">
                        <div *ngIf="error === true" class="res-container">
                            <div class="icon-res icon-res-err">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" fill="rgba(255,97,97,1)"/></svg>
                            </div>
                            <span class="msg-res msg-res-err"> {{errMsg}} </span>
                        </div>
                        <div *ngIf="error === false" class="res-container">
                            <div class="icon-res icon-res-valid">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z"/><path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="rgba(80,227,194,1)"/></svg>
                            </div>
                            <span class="msg-res msg-res-valid">  {{'center_infos_club.valid_request' | translate }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="infos-content col-12 col-md-6 col-lg-7 col-xl-8">
            <div class="name-phone-container">
                <div class="d-flex flex-column mb-3" *ngIf="center && center.longDescription">
                    <span class="title"> {{'center_infos_club.description' | translate }}</span>
                    <span  class="text-justify"  [innerHtml]="center.longDescription"></span>
                </div>
                <div class="d-flex flex-column mb-2" *ngIf="isLoggedIn">
                    <span class="title"> {{'center_infos_club.contact' | translate }}</span>
                    <div class="d-flex">
                        <span> {{'center_infos_club.phone' | translate }} : </span>
                        <a class="phone" type="tel"  href="tel:{{center.phoneNumber}}">  {{center.phoneNumber}} </a>
                    </div>
                </div>
            </div>
            <div class="offer-container">
                <p class="text-grey title">{{'center_infos_club.you_are' | translate : {name: center.name} }}</p>
                <p>{{'center_infos_club.booking_online' | translate }}</p>
                <div class="advantage-item">
                    <span class="me-3">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 6.757l-2 2V4h-9v5H5v11h14v-2.757l2-2v5.765a.993.993 0 0 1-.993.992H3.993A1 1 0 0 1 3 20.993V8l6.003-6h10.995C20.55 2 21 2.455 21 2.992v3.765zm.778 2.05l1.414 1.415L15.414 18l-1.416-.002.002-1.412 7.778-7.778z" fill="rgba(255,195,0,1)"/></svg>
                    </span>
                    <span>
                        {{'center_infos_club.display_your_infos' | translate }}
                    </span>
                </div>
                <div class="advantage-item">
                    <span class="me-3">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M7.39 16.539a8 8 0 1 1 9.221 0l2.083 4.76a.5.5 0 0 1-.459.701H5.765a.5.5 0 0 1-.459-.7l2.083-4.761zm6.735-.693l1.332-.941a6 6 0 1 0-6.913 0l1.331.941L8.058 20h7.884l-1.817-4.154zM8.119 10.97l1.94-.485a2 2 0 0 0 3.882 0l1.94.485a4.002 4.002 0 0 1-7.762 0z" fill="rgba(255,195,0,1)"/></svg>
                    </span>
                    <span>
                        {{'center_infos_club.raise_reservation' | translate }}
                    </span>
                </div>
                <a [routerLink]="['/' + country + 'on-boarding']">
                    <button class="btn-partner">
                        {{'center_infos_club.ref_club_AB' | translate }}
                    </button>
                </a>
            </div>
            <div class="opening-hours-container" *ngIf="center && center.openingHours && numDays.length > 0">
                <p class="title">  {{'center_infos_club.opening_hours' | translate }} </p>
                <ul class="opening-hours">
                    <li class="day-hours" *ngFor="let day of numDays">
                        <div class="day">
                            <div class="bullet"></div>
                            <span>{{days[day - 1]}} </span>
                        </div>
                        <span class="hours">
                             {{center.openingHours[day].open}} - {{center.openingHours[day].close}}
                        </span>
                    </li>
                </ul>
            </div>
            <div class="center-reco">
                <app-center-recommendations [center]="center"></app-center-recommendations>
            </div>
        </div>
    </div>
</div>
