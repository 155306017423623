<!-- favorite list -->
<div class="container">
  <div class="header">
    <h1 class="title"> {{ 'my_bookings.my_account' | translate }} </h1>
  </div>
  <hr>
  <div class="title-container mt-5 mb-5">
    <h2 class="sub-title">{{ 'my_favorite.my_favorite_title' | translate }}</h2>
    <p class="text">{{ 'my_favorite.my_favorite_subtitle' | translate }}</p>
  </div>
  <div class="card-container">
    <!-- LOADER -->
    <!-- <ng-container *ngIf="!dataAvailable">
      <app-loader></app-loader>
    </ng-container> -->
    <div class="card-content" *ngFor="let center of favoriteList">
      <a [routerLink]="['/' + center.getPath()]">
        <div class="club-pic">
          <img src="{{center.headerPhoto['688x688']}}" alt="club-picture">
        </div>
        <div class="favorite" (click)="$event.stopPropagation();$event.preventDefault();manageFavorite(center.id)">
          <svg xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 115.11 114" width="32" height="32">
              <path fill="none" d="M0,0H115.11V114H0V0Z" />
              <path fill="rgba(255,97,97,1)" d="M58.32,21.51c11.09-10.02,28.22-9.69,38.91,1.08,10.68,10.77,11.05,27.93,1.11,39.12l-40.03,40.34L18.29,61.72c-9.93-11.19-9.56-28.38,1.11-39.12,10.69-10.75,27.79-11.11,38.91-1.08Z" />
              <path fill="#fff" d="M57.93,21.3c11.32-10.16,28.81-9.82,39.71,1.1,10.9,10.93,11.28,28.33,1.14,39.69l-40.86,40.92L17.07,62.08c-10.14-11.35-9.76-28.79,1.14-39.69,10.91-10.91,28.37-11.28,39.72-1.1Zm32.89,7.91c-7.23-7.24-18.89-7.53-26.45-.74l-6.43,5.77-6.44-5.77c-7.59-6.8-19.23-6.5-26.47,.74-7.18,7.18-7.54,18.67-.93,26.27l33.83,33.88,33.83-33.87c6.62-7.6,6.26-19.08-.93-26.28Z" />
          </svg>
        </div>
        <div class="club-name">
          <p>{{center.name}}</p>
        </div>
        <div class="rating" *ngIf="center.rating">
          <app-star-rating
            [rating]="center.rating"
            [ratingCount]="center.ratingCount"
            [white]="true"
            [small]="true">
          </app-star-rating>
        </div>
      </a>
    </div>
    <div *ngIf="favoriteList.length === 0" class="row no-favoris-container">
      <div class="col-12 col-md-2 image-container px-4">
        <img class="h-auto" ngSrc="v1677169402/assets/buddy/LOVE_BUDDY.svg" width="128" height="158">
        <h5 class="d-block d-md-none">{{'my_favorite.no_favorite_club_title' | translate}}</h5>
      </div>
      <div class="col-12 col-md-5 text-container mt-3 mt-md-0 px-4">
        <h5 class="d-none d-md-block">{{'my_favorite.no_favorite_club_title' | translate}}</h5>
        <p>{{'my_favorite.no_favorite_club_text' | translate}}</p>
      </div>
      <div class="col-12 col-md-5 button-container mt-3 mb-3 mt-md-0 mb-md-0 px-4">
        <button [routerLink]="'/reservation-sport'" class="col-12 col-md-10">{{'my_favorite.see_clubs' | translate}}</button>
      </div>
    </div>
  </div>
  <hr>
</div>
