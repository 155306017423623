import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { TypeGround } from '../search/search.component';
import { UserPreferenceService } from '../service/user-preferences.service';
/* eslint-disable */
enum MenuItem {
  List = 'list',
  Map = 'map',
  Filter = 'filter'
}

@Component({
  selector: 'app-menu-bottom',
  templateUrl: './menu-bottom.component.html',
  styleUrls: ['./menu-bottom.component.scss']
})
export class MenuBottomComponent implements OnInit, OnChanges {

  @Input() city: string;
  @Output() isFilter = new EventEmitter<boolean>();

  public routerLink = '';
  public menuItem = MenuItem;
  public menuItemSelected: MenuItem;

  private sport: string
  private typeGround = TypeGround;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private localize: LocalizeRouterService,
    private translate: TranslateService,
    private userPreferenceService: UserPreferenceService
  ) { }

  ngOnInit(): void {
    if (this.activateRoute.routeConfig.component.name === 'CenterAvailabilityComponent') {
      this.menuItemSelected = this.menuItem.List;
    } else if (this.activateRoute.routeConfig.component.name === 'MapComponent') {
      this.menuItemSelected = this.menuItem.Map;
    }
    if (this.activateRoute.snapshot.paramMap.get('sport')) {
      this.sport = this.activateRoute.snapshot.paramMap.get('sport');
    } else {
      this.sport = this.activateRoute.snapshot.url[0].path.split('-')[2];
    }
    if (!this.city) {
      this.city = this.activateRoute.snapshot.paramMap.get('city');
    }
    this.activateRoute.params.subscribe(
      res => {
        this.city = res.city;
      }
    )
  }

  public onChangeSelectMenu(menuSelected: MenuItem): void {
    this.menuItemSelected = menuSelected;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sport !== undefined && changes.sport.currentValue !== 'Sport') {

      this.routerLink = '/reservation-' + this.sport + '/' + this.city + '?activity=' + this.sport;
    }
    else {
      this.routerLink = '/reservation-sport/' + this.city;
    }
  }

  navigateTo(): void {
    if (this.sport !== "undefined") {
      let sport: string;
      if (this.userPreferenceService.getCountry() !== 'fr') {
        sport = this.translate.instant(`keySport.${this.sport}`);
      } else {
        sport = this.sport;
      }
      let ground: TypeGround = sport === "tennis" ? this.typeGround.Court : this.typeGround.Terrain;
      if (sport === 'fitness') {
        ground = this.typeGround.Session;
      }
      let translatedPath: any = this.localize.translateRoute(`reservation-${ground}-${sport}`);
      if (this.userPreferenceService.getLang() !== 'fr') {
        this.router.navigate([`${this.userPreferenceService.getLang()}/${translatedPath}/${this.city}`], { queryParams: { ...this.activateRoute.snapshot.queryParams } });
      }
      else {
        this.router.navigate([`${translatedPath}/${this.city}`], { queryParams: { ...this.activateRoute.snapshot.queryParams } });
      }
    } else {
      let translatedPath: any = this.localize.translateRoute(`reservation-sport`);
      if (this.userPreferenceService.getLang() !== 'fr') {
        this.router.navigate([`${this.userPreferenceService.getLang()}/${translatedPath}/${this.city}`], { queryParams: { ...this.activateRoute.snapshot.queryParams } });
      }
      else {
        this.router.navigate([`${translatedPath}/${this.city}`], { queryParams: { ...this.activateRoute.snapshot.queryParams } });
      }
    }
  }

  navigateToMap(): void {
    if (this.userPreferenceService.getLang() !== 'fr') {
      this.router.navigate([`${this.userPreferenceService.getLang()}/reservation/${this.sport}/map/${this.city}`], { queryParams: { ...this.activateRoute.snapshot.queryParams } });
    } else {
      this.router.navigate([`reservation/${this.sport}/map/${this.city}`], { queryParams: { ...this.activateRoute.snapshot.queryParams } });
    }
  }

  openFilter() {
    this.isFilter.emit(true);
  }
}
