<div class="main-container align-items-center mt-3">
    <button *ngIf="isScrollable && !isMobileResolution" class="btn" (click)="onPrev()">
        <img ngSrc="v1677166288/assets/arrow/arrow-left.png" alt="arrow left" class="arrow" width="96" height="96">
    </button>
    <div #slider class="calendar-scroll-div">
        <div  class="d-flex flex-row flex-nowrap pb-4" *ngIf="slots!==undefined && slots.length>0">
            <ng-container *ngFor="let date of slots; let i = index" >
                <a (click)="newDateEvent.emit(date)" [routerLink]="['/' + center.getPath(), translatePathBooking, sportSelected]" [queryParams]="{date: date['startDateTime']}">
                    <div class="availability justify-content-center" >
                        <div class="slot">
                            <span class="start-slot"> {{getHours(date["startDateTime"])}} </span>
                        </div>
                        <div class="price">
                            <span> {{getMinPrice(date["services"])/100|currency:currency:'symbol':'1.2-2':'fr'}}</span>
                        </div>
                    </div>
                </a>
            </ng-container>
        </div>
    </div>
    <button *ngIf="isScrollable && !isMobileResolution" class="btn ms-auto ms-4" (click)="onNext()">
        <img ngSrc="v1677166288/assets/arrow/arrow-right.png" alt="arrow left" class="arrow" width="96" height="96">
    </button>
</div>
