import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})

export class TopNavBarComponent implements OnInit {

  @Input() isMobileResolution = false;
  @Input() country: string;
  @Input() isTopBarFixed: string;
  @Input() isYellowLogo = true;
  @Input() hided = false;
  @Input() yellow = false;

  @Input() idSelector1Langage = 'dropdownCountry';
  @Input() idSelector2Langage = 'dropdownLangage';
  public sidebarShow = false;
  languageChoosed;

  public languageFooter: string;
  constructor(
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private ngbModale: NgbModal,
  ) {
    this.languageFooter = this.userPreferenceService.getCountry();
  }

  ngOnInit(): void {
    this.languageFooter = this.userPreferenceService.getCountry();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
    this.userPreferenceService.getChoosedLanguage().subscribe(l => {
      this.languageChoosed = l;
    });
  }

  openLangageModal(modal: TemplateRef<any>): void {
    this.ngbModale.open(modal, {windowClass: 'custom-modal'});
    this.sidebarShow = !this.sidebarShow;
  }

}
