import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthFirebaseService } from '../service/auth-firebase.service';

@Component({
  selector: 'app-fire-base-action',
  templateUrl: './fire-base-action.component.html',
  styleUrls: ['./fire-base-action.component.scss']
})
export class FireBaseActionComponent implements OnInit {
  constructor(
    private router: Router,
    private authFirebaseService: AuthFirebaseService,
    private route: ActivatedRoute
  ) {}

  mode = '';
  showError = false;
  showVerifMail = false;
  errorMsg = '';

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.mode) {
        this.mode = params.mode;
        this.transferToPage(this.mode, params);
      } else {
        this.showError = true;
        this.errorMsg = '';
      }
    });
  }

  transferToPage(mode: string, params): void {
    switch (mode) {
      case 'signIn':
        this.router.navigate(['/complete-sign-up'], { queryParams: params });
        break;
      case 'resetPassword':
        this.router.navigate(['/pwchangeform'], { queryParams: params });
        break;
      case 'verifyEmail':
        this.verifiMail(params.oobCode);
        break;
      case 'verifyAndChangeEmail':
        this.verifiMail(params.oobCode);
        break;
      case 'recoverEmail':
        this.verifiMail(params.oobCode);
        break;
    }
  }

  verifiMail(code: string): void {
    this.authFirebaseService.getAuthState().subscribe((user) => {
      if (user) {
        if (!user.emailVerified) {
          this.authFirebaseService
            ._applyActionCode(code)
            .then(() => {
              this.showVerifMail = true;
            })
            .catch((error) => {
              this.errorMsg = error.message;
              this.showError = true;
            });
        } else {
          this.showVerifMail = true;
        }
      } else {
        this.errorMsg = 'You are not logged in';
        this.showError = true;
      }
    });

  }
}
