import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Credentials } from '../../marketplace/models/credentials';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    private registrationUrl: string = environment.organiserApiUrl + '/v1/auth/register';
    private headers = new HttpHeaders().set('Content-Type', 'application/json');
    constructor(private http: HttpClient) {}

    registerUser(provider: string, credentials?: Credentials): Observable<any> {
      const json : any = {
        email: credentials.username,
        password: credentials.password,
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        dateOfBirth: credentials.dateOfBirth,
        provider: provider,
      };
      if (credentials.memberId) {
        json.memberId = credentials.memberId;
      }
      return this.http.post(`${this.registrationUrl}`, JSON.stringify(json), { headers: this.headers, observe: 'response' });    }
}