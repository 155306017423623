import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-multiple-stars-rating',
  templateUrl: './multiple-stars-rating.component.html',
  styleUrls: ['./multiple-stars-rating.component.scss']
})
export class MultipleStarsRatingComponent {
  @Input() rating: number;

  numbers: number[] = new Array(5);

}
