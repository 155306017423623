<div class="card-filter" [ngClass]="{'card-box': !isMobileResolution, 'follow-availabilities': !isMobileResolution}">
  <h3 class="sport-available-title">{{ 'sport.available_sports' | translate }}</h3>

  <div class="selectInfo mb-2 " [ngClass]="{'d-flex': isMobileResolution}">
    <button *ngIf="isScrollableSport && isMobileResolution" class="btn" (click)="onPrev('sportSlider')">
      <img ngSrc="v1677166288/assets/arrow/arrow-left.png" alt="arrow left" class="arrow" width="96" height="96">
    </button>
    <div class="sport-container" [ngClass]="{'flex-wrap-custom': isMobileResolution,'flex-wrap': !isMobileResolution, 'pb-2': isMobileResolution}"  #sportSlider>
      <ng-container *ngFor="let sportinfo of sports">
        <div [routerLink]="isWhiteLabel ? ['/club', centerId, 'reservation', sportinfo.id] : ['/' + url + '/' + sportinfo.id]" queryParamsHandling="merge"
        [ngClass]="{'sport-selected': sportSelected === sportinfo.id, 'sport': sportSelected !== sportinfo.id, 'col-lg-4 col-md-6 col-sm-12': true}"
        *ngIf="(sportinfo.id !== 'beach-tennis'  && sportinfo.id !== 'table-tennis')"
        (click)="changeSelectedSport(sportinfo.id)">
        <img class="sport-image" [src]="sportinfo.icon" width="80" height="83" alt={{sportinfo.id}}>
        <h4 style="font-size: 1rem;">{{sportinfo.name}}</h4>
    </div>

      </ng-container>
    </div>
    <button *ngIf="isScrollableSport && isMobileResolution" class="btn" (click)="onNext('sportSlider')">
      <img ngSrc="v1677166288/assets/arrow/arrow-right.png" alt="arrow right" class="arrow" width="96" height="96">
    </button>
</div>



  <div [ngClass]="{'d-flex': isMobileResolution}">

    <div class="buttonDiv" *ngIf="isMobileResolution && filtersCharacteristiques && filtersCharacteristiques.length > 0">
      <button [attr.data-notifications]="1 >0 ? 1: null" class="buttonFilter"
              (click)="openFilterModal(filterContent)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17zM9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>
        <div class="select-filter">
          {{ 'center_availabilities.refine_search' | translate }}
        </div>
      </button>
    </div>

    <div class="select-types" *ngIf="subActivities && subActivities.length > 1">
      <div class="d-flex activity" *ngIf="!isMobileResolution">
        <h4 class="activity-title">{{"availabilities.ativities_types" | translate: {sport: sportSelected} }}</h4><div class="selected-activity d-flex ms-2"><span class="m-auto">{{subActivitiesSelected.length}}</span></div>
      </div>
      <div class="type-container" [ngClass]="{'type-container-mobile': isMobileResolution}">
        <div class="row">
            <div class="col-12">
                <div class="service-type" (click)="selectServiceTypeOrActivity(subActivitiesSelected,'all')" [ngClass]="serviceTypeOrActivityIsSelected(subActivitiesSelected,'all') ? 'service-selected':''">
                {{ "availabilities.all_sport_type" | translate: {sport: sportSelected} }}
                </div>
            </div>
            <ng-container *ngFor="let type of activitiyList">
                <div class="col-6">
                    <div class="service-type" (click)="selectServiceTypeOrActivity(subActivitiesSelected,type.id)" [ngClass]="serviceTypeOrActivityIsSelected(subActivitiesSelected,type.id) ? 'service-selected':''">
                    {{type.name}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
  </div>
  <div *ngIf="!isMobileResolution">
    <ng-content *ngTemplateOutlet="filter"></ng-content>
  </div>
</div>

<ng-template #filterContent let-modal>
  <div class="modal-header">
    <div class="filtre">{{ 'filters.filters' | translate}}</div>
    <button type="button" style="
      background: white;
      border: aliceblue;
  " aria-label="Close" (click)="closeModalFilter()"> <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24" width="24" height="24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
    </svg></button>
  </div>
  <div *ngIf="isMobileResolution">
    <ng-content *ngTemplateOutlet="filter"></ng-content>
  </div>
  <div class="p-3 d-flex justify-content-center footer" style="margin-top: 9%;" *ngIf="filtersCharacteristiques.length > 0">
    <button class="submit-filter" (click)="senAvailabilitiesMobile()">{{'filters.display_result' | translate}} </button>
  </div>
</ng-template>

<ng-template #filter>
  <form *ngIf="filterForm" [formGroup]="filterForm" class="card-filter">
    <ngb-accordion #acc="ngbAccordion" [activeIds]="activePanelIds">
      <ngb-panel *ngFor="let characteristique of filtersCharacteristiques; let i = index" id="panel-{{i}}">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
            <div class="m-0 expansion-title">{{characteristique.name}}</div>
            <button ngbPanelToggle class="p-0 btn-arrow">
              <img class="arrow-up" ngSrc="v1677166288/assets/arrow/arrow-up.png" *ngIf="opened" alt="arrow-up" width="36" height="36">
              <img class="arrow-down" ngSrc="v1677166288/assets/arrow/arrow-down.png" *ngIf="!opened" alt="arrow-down" width="36" height="36">
            </button>
          </div>

        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container *ngFor="let options of characteristique.options; let i = index">
            <div class="col-12 p-0">
              <div class="d-flex justify-content-between">
                <label class="checkbox-label" *ngIf="options.name !== 'profs'">{{options.name}}</label>
                <input type="checkbox" class="custom-control-input"
                       id="{{options.id}}" value="{{options.id}}" (change)="onCheckChange($event)">
                <label class="custom-control-label" for="{{options.id}}"></label>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </form>
</ng-template>
