<ng-container>
  <app-loader *ngIf="loading"></app-loader>
</ng-container>

<app-top-navbar
  *ngIf="!isMobileResolution"
  [isMobileResolution]="isMobileResolution"
  [country]="country">
  <div
    class="d-flex justify-content-between align-items-center ms-auto"
    app-top-navbar-right
    *ngIf="!isMobileResolution">
    <a [routerLink]="['/' + country, 'on-boarding']">
      <button class="navbar-btn navbar-btn-light me-0">
        {{'menuComponent.add_your_center' | translate}}
      </button>
    </a>
    <app-button-account-disconnected></app-button-account-disconnected>
  </div>
</app-top-navbar>


<div *ngIf="!error && !loading" class="container px-lg-5">

  <div class="my-3">
    <h1 class="text-center">Nos articles</h1>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4 my-3" *ngFor="let blog of blogs">
      <div class="card click h-100" [routerLink]="'/blog/' + blog.slug">
        <img class="card-img-top card-image" [src]="blog.image ? blog.image : 'https://softlead.ro/assets/media/default_no_photo.png'" alt="photo qui represente l'article">
        <div class="card-body">
          <h2 class="card-title" [innerHTML]="blog.title"></h2>
          <div class="card-text" [innerHTML]="blog.intro"></div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <ngb-pagination class="m-auto" [collectionSize]="totalBlogs"  [(page)]="currentPage" [pageSize]="offSet" [maxSize]="5" (pageChange)="changePage($event)" [boundaryLinks]="true"></ngb-pagination>
    </div>
  </div>
</div>

<div *ngIf="error && !loading" class="error">
  {{error}}
</div>

<div class="d-flex flex-column section-footer"
     [ngClass]="{'mt-5': isMobileResolution, 'mt-2 mb-3 mx-5 px-5 ': !isMobileResolution}">
  <app-footer [isMobileResolution]="isMobileResolution"></app-footer>
</div>
