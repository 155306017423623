import { Center } from './center';
import { Places } from './places';
import { Vouchers } from './vouchers';
import { User } from './user';
export class Reservation {
  public static fromJson(json): Reservation {
    return new Reservation(
      json.id,
      json.status,
      json.start,
      json.end,
      json.duration,
      json.priceCents,
      json.priceCentsTotal,
      json.currencyCode,
      json.currencySymbol,
      json.facilityId,
      json.facilityName,
      json.facilityTypeName,
      json.isIndoor,
      json.activityName,
      json.createdAt,
      json.center,
      json.paidPrice,
      json.facilitySurface,
      json.reservedBy,
      json.clientSecret,
      json.instructions,
      json.vouchers,
      json.places,
      json.serviceFee,
      json.serviceFeeText,
      json.importantMsg,
      json.isCancellable,
      json.eventId,
      json.name,
    );
  }

  constructor(
    public id: string,
    public status: string,
    public start: string,
    public end: string,
    public duration: string,
    public priceCents: string,
    public priceCentsTotal: number,
    public currencyCode: string,
    public currencySymbol: string,
    public facilityId: string,
    public facilityName: string,
    public facilityTypeName: string,
    public isIndoor: string,
    public activityName: string,
    public createdAt: string,
    public center: Center,
    public paidPrice: string,
    public facilitySurface: string,
    public reservedBy: User[],
    public clientSecret: string,
    public instructions: string[],
    public vouchers: Vouchers[],
    public places: Places[],
    public serviceFee: number,
    public serviceFeeText: string,
    public importantMsg: string,
    public isCancellable: boolean,
    public eventId: string,
    public name?: string,
  ) { }

}
