/* eslint-disable */
export class AllSessionForm {
  public static fromJson(json: object): AllSessionForm {
    return new AllSessionForm(
      json['reservationToken'],
      json['playerId'],
      json['playerName'],
      json['start'],
      json['end'],
      json['centerName'],
      json['centerAddress'],
      json['urlCenter'],
      json['companyName']
    );
  }
  constructor(
    public reservationToken: string,
    public playerId: string,
    public playerName: string,
    public start: string,
    public end: string,
    public centerName: string,
    public centerAddress: string,
    public urlCenter?: string,
    public companyName?: string
  ) {
  }
}
