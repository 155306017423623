import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Search } from '../models/search';

@Injectable({
  providedIn: 'root'
})

export class SearchService {

  private searchUrl = environment.bookingApiUrl + '/v1/search';
  private geoUrl = environment.bookingApiUrl + '/v1/geosearch';
  private allAccentLetters = {
    á: 'a',
    â: 'a',
    é: 'e',
    è: 'e',
    ê: 'e',
    í: 'i',
    î: 'i',
    ó: 'o',
    ô: 'o',
    ú: 'u',
    ü: 'u',
    û: 'u',
    ñ: 'n'
  };

  constructor(private http: HttpClient) { }

  searchQuery(searchQuery: string): Observable<Search> {
    return this.http.get<Search>(`${this.searchUrl}?query=${searchQuery}`);

  }
  searchQueryByType(searchQuery: string, typeFilter: string): Observable<Search> {
    return this.http.get<Search>(`${this.searchUrl}?query=${searchQuery}&types=${typeFilter}`);
  }
  getGeoname(location: string): Observable<any> {
    return this.http.get<any>(`${this.geoUrl}?location=${location}`);
  }
  getGeonameBySlug(slug: string): Observable<any> {
    return this.http.get<any>(`${this.geoUrl}?slug=${slug}`);
  }

  public formatSlugCity(city: string): string {
    const slugData = city.trim().replace('\'', '').toLowerCase();
    const slugFormat = slugData.replace(/[^\w ]/g, char => {
      if (char !== '-') {
        const letter = this.allAccentLetters[char];
        return letter;
      }
      return char;
    });
    const slug = slugFormat.split(' ').join('-');
    return slug;
  }
}
