import {inject} from "@angular/core";
import { ResolveFn } from '@angular/router';
import {forkJoin} from "rxjs";
import {UserService} from "../service/user.service";
import {CentersService} from "../service/center.service";

export const MyProfileResolver: ResolveFn<any[]> = (route, state) => {
  const userService: UserService = inject(UserService);
  const centersService: CentersService = inject(CentersService);
  const requests = [userService.getMe() , centersService.getUserMeSubscriptions()];
  return forkJoin(requests);
};
