<!-- favorite list -->
<div class="container">
  <div class="header">
    <h1 class="title"> {{ 'my_bookings.my_account' | translate }} </h1>
  </div>
  <hr>
  <div class="title-container mt-5 mb-2">
    <h2 class="sub-title">{{ 'my_preferences.my_preferences_title' | translate }}</h2>
    <p class="text">{{ 'my_preferences.my_preferences_subtitle' | translate }}</p>
  </div>

  <h2 class="mb-3">{{ 'my_preferences.marketing' | translate }}</h2>

  <div class="col-12 col-md-10 p-0 m-0">
    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" class="custom-accordion">
      <ngb-panel title="{{ 'my_preferences.marketing_title' | translate }}">
        <ng-template ngbPanelContent>
          <div>
            <form [formGroup]="marketingForm" class="pb-1 card-padding">
              <div *ngFor="let type of marketingType">
                <input class="custom-control-input" type="checkbox" [value]="type.id" [id]="type.id"
                       (change)="onCheckboxChange($event)" [checked]="isChecked(type.id,marketingForm.controls.selectedMarketingCommunication)"
                       formArrayName="selectedMarketingCommunication">
                <label class="custom-control-label" [htmlFor]="type.id" >{{ ('my_preferences.' + type.text) | translate }}</label>
              </div>
            </form>
            <div class="d-flex">
              <button class="m-auto btn btn-save" (click)="saveMarketingData()">{{ 'my_preferences.save' | translate }}</button>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <p class="text-center" *ngIf="msgMarketing" [ngClass]="errorMsgMarketing ? 'invalid' : 'valid' ">{{msgMarketing}}</p>
  </div>

  <ng-container *ngIf="isMarket === true">
    <hr>

    <h2 class="mb-3">{{ 'my_preferences.account_management' | translate }}</h2>
    <button class="btn btn-save" (click)="reAuthenticate('email')">{{ 'my_preferences.updateMail' | translate}}</button>
    <hr>
    <button class="btn btn-save" (click)="reAuthenticate('phone')">{{ 'my_preferences.updatePhone' | translate}}</button>
    <hr>
    <button class="btn btn-danger btn-size" (click)="askAccountDeletion()">{{ 'menuComponent.supressAccount' | translate}}</button>
  </ng-container>
</div>

<ng-template #deleteAccountModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'menuComponent.deleteAccount' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{'menuComponent.supressingMsg' | translate}}
    </p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger me-auto col-5" (click)="deleteAccount()">{{ 'menuComponent.yes' | translate}}</button>
    <button type="button" class="btn btn-dark ms-auto col-5" (click)="modal.close()">{{ 'menuComponent.no' | translate}}</button>
  </div>
</ng-template>

<ng-template #deleteAwaitingAccount>
  <div class="modal-body">
    <p class="w-100 text-center">{{ 'menuComponent.deletingAccount' | translate}}</p>
    <div class="loader m-auto"></div>
  </div>
</ng-template>

<ng-template #deleteAccountResult class="my-modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'menuComponent.deleteAccount' | translate}}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{ errorMsg ? errorMsg : ('menuComponent.deletionSuccess' | translate) }}
    </p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger m-auto col-5" (click)="closeResultModal()">{{ 'menuComponent.close' | translate}}</button>
  </div>
</ng-template>

<ng-template #updateMailModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingEmailTitle' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{'Veuillez entrer votre nouvelle adresse email'}}
    </p>
    <form [formGroup]="updateMailForm" class="pb-1 card-padding d-flex">
      <input class="newMail" [formControlName]="'newMail'">
    </form>
    <p class="error text-center" *ngIf="emailInvalid">{{ 'my_preferences.invalidField' | translate}}</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-dark m-auto col-5" (click)="updateMail()">{{ 'my_preferences.update' | translate}}</button>
  </div>
</ng-template>

<ng-template #updatingEmailAwaiting>
  <div class="modal-body">
    <p class="w-100 text-center">{{ 'my_preferences.updatingEmail' | translate}}</p>
    <div class="loader m-auto"></div>
  </div>
</ng-template>

<ng-template #updatingAccountResult class="my-modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingEmailTitle' | translate}}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{ errorMsg ? errorMsg : ('my_preferences.updatingEmailSuccess' | translate) }}
    </p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger m-auto col-5" (click)="closeResultModal()">{{ 'menuComponent.close' | translate}}</button>
  </div>
</ng-template>

<ng-template #updatingAccountResultPhone class="my-modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingPhoneTitle' | translate}}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{ errorMsg ? errorMsg : ('my_preferences.updatingPhoneSuccess' | translate) }}
    </p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger m-auto col-5" (click)="closeResultModal()">{{ 'menuComponent.close' | translate}}</button>
  </div>
</ng-template>

<ng-template #errorModal class="my-modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.error_valid' | translate}}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">{{ errorFlowMsg }}</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger m-auto col-5" (click)="closeResultModal()">{{ 'menuComponent.close' | translate}}</button>
  </div>
</ng-template>

<ng-template #passwordModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.checkPassword' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">{{'my_preferences.password' | translate}}</p>
    <form [formGroup]="passwordForm" class="pb-1 card-padding d-flex">
      <input class="newMail" type="password" [formControlName]="'password'">
    </form>
    <p class="error text-center" *ngIf="passwordInvalid">{{ 'my_preferences.invalidField' | translate}}</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-dark m-auto col-5" (click)="reAuthenticateWithPassword()">{{ 'my_preferences.check' | translate}}</button>
  </div>
  <p *ngIf="errorPasswordMsg" class="error text-center">{{errorPasswordMsg}}</p>
</ng-template>

<ng-template #updatePhoneModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingPhoneTitle' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{'my_preferences.enterNewPhone' | translate}}
    </p>
    <form [formGroup]="updatePhoneForm" class="pb-1 card-padding d-flex">
      <input class="newMail" [formControlName]="'newPhone'" [placeholder]="'+33XXXXXXXXX'"  [disabled]="captcha">
    </form>
    <p class="error text-center" *ngIf="emailInvalid">{{ 'my_preferences.invalidField' | translate}}</p>

    <div *ngIf="captcha">
      <p class="text-center">{{'my_preferences.completeCaptcha' | translate}}</p>
    </div>
    <div class="d-flex">
      <div class="mx-auto" id="recaptcha-container"></div>
    </div>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-dark m-auto col-5" [disabled]="captcha" (click)="updatephone()">{{ 'my_preferences.update' | translate}}</button>
  </div>
</ng-template>

<ng-template #updatePhoneCodeModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingPhoneTitle' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">{{'my_preferences.verifCode' | translate}}</p>
    <form [formGroup]="updatePhoneValidationCodeForm" class="pb-1 card-padding d-flex">
      <input class="newMail" [formControlName]="'validationCode'">
    </form>
    <p class="error text-center" *ngIf="emailInvalid">{{ 'my_preferences.invalidField' | translate}}</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-dark m-auto col-5" (click)="valideCode()">{{ 'my_preferences.update' | translate}}</button>
  </div>
</ng-template>

<ng-template #updatingPhoneAwaiting>
  <div class="modal-body">
    <p class="w-100 text-center">{{ 'my_preferences.updatingPhone' | translate}}</p>
    <div class="loader m-auto"></div>
  </div>
</ng-template>
