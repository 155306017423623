export class Promotion {
  constructor(
    public type: string,
    public code1: string,
    public email: string,
    public code2: string,
    public email2: string,
    public code3: string,
    public email3: string,
    public code4: string,
    public email4: string
  ) { }
}
