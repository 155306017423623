/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Center } from '../../marketplace/models/center';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelCenterService {
  private centerUrl: string = environment.bookingApiUrl + '/v1/centers';
  public centers: Center[];
  whiteLabelCenter: Center;
  selectedCenterId: string;
  constructor(private http: HttpClient) {}

  getCenterByDomain(domain: string): Observable<Center[]> {
    return this.http
      .get<Center[]>(`${this.centerUrl}/domains/${domain}`)
      .pipe(map((res) => res.map((center) => Center.fromJson(center))));
  }

  fetchWhiteLabelCenter(domain: string): Observable<Center> {
    return this.getCenterByDomain(domain).pipe(
      tap({
        next: (centers) => {
          const selectedCenter = centers.find(
            (center) => center.id === this.selectedCenterId
          );
          if (selectedCenter) {
            this.whiteLabelCenter = selectedCenter;
          } else {
            console.error('pas de centre');
          }
        },
        error: (_) => console.error('pas de centre') // window.location.href = 'https://www.anybuddyapp.com', // environment.host,
      }),
      map((centers) =>
        centers.find((center) => center.id === this.selectedCenterId)
      )
    );
  }

  getWhiteLabelCenter(): Center {
    return this.whiteLabelCenter;
  }

  getWhiteLabelCenterLogo(): string {
    if (
      this.whiteLabelCenter &&
      this.whiteLabelCenter.profilePhoto['192x192']
    ) {
      let originalUrl = this.whiteLabelCenter.profilePhoto['192x192'];
      let modifiedUrl = originalUrl.replace(
        '/w_192,h_192,c_fill/c_scale,w_auto/dpr_auto',
        '/f_auto,q_auto,w_200'
      );
      return modifiedUrl;
    }
    return '';
  }

  getWhiteLabelCenterId(): string {
    if (this.whiteLabelCenter && this.whiteLabelCenter.id) {
      return this.whiteLabelCenter.id;
    }
    return '';
  }

  getWhiteLabelCenterName(): string {
    if (this.whiteLabelCenter && this.whiteLabelCenter.name) {
      return this.whiteLabelCenter.name;
    }
    return '';
  }

  getOrFetchWhiteLabelCenters(domain: string): Observable<Center[]> {
    if (this.centers) {
      return new Observable<Center[]>((observer) => {
        observer.next(this.centers);
        observer.complete();
      });
    } else {
      return this.getCenterByDomain(domain).pipe(
        tap({
          next: (centers) => {
            this.centers = centers;
          },
          error: (_) => console.error('pas de centre') // window.location.href = 'https://www.anybuddyapp.com', // environment.host,
        }),
        map((centers) => centers)
      );
    }
  }
}
