import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { Center } from '../models/center';
import { CenterFacilities } from '../models/center-facilities';
import { Places } from '../models/places';
import { Reservation } from '../models/reservation';
import { Service } from '../models/service';
import { Vouchers } from '../models/vouchers';
import { CentersService } from '../service/center.service';
import { ReservationsService } from '../service/reservations.service';
import { TagManagerService } from '../service/tag-manager.service';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { AppContextService } from '../../shared/services/app-context.service';
import { repeat, skipWhile, take } from 'rxjs';

enum Status {
  Fail = 'failed',
  Cancel = 'cancelled',
  Confirmed = 'confirmed',
  Waiting = 'awaitingValidation'
}
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {
  resaId: string;
  resa: Observable<Reservation>;
  reservation: Reservation;

  facilities: CenterFacilities[];
  facility: CenterFacilities;

  centerId: string;
  center: Center;
  centerService: Service;

  vouchers: Vouchers[];
  places: Places[];

  date: string;
  instructions: string[];
  price: any;
  googleMapLink: string;

  isLittleWidth = false;
  dataAvailable = false;
  hideTitle = true;
  isCancel = false;
  notCancelableError: string;
  isError = false;
  isWhiteLabel: boolean;
  public status = Status;
  displayName: string;
  @ViewChild('errorInvoice') errorInvoice: TemplateRef<any>;
  invoiceErrorMsg: string;
  resaSuccess = false;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private reservationsService: ReservationsService,
    private centersService: CentersService,
    private translateService: TranslateService,
    private tagManager: TagManagerService,
    private authFirebaseService: AuthFirebaseService,
    private appContextService: AppContextService
  ) {
    this.isWhiteLabel = this.appContextService.getisWhiteLabel();
  }

  ngOnInit(): void {
    if (window.innerWidth < 1024) {
      this.isLittleWidth = true;
    } else {
      this.isLittleWidth = false;
    }

    this.resaId = this.route.snapshot.paramMap.get('id');
    this.loadReservationUntilGoodStatus();
  }

  private loadReservationUntilGoodStatus(): void {
    this.reservationsService
      .getReservation(this.resaId)
      .pipe(
        repeat({ delay: 1000 }),
        skipWhile(
          (response) => response.status === 'awaitingPaymentCapturable'
        ),
        take(1)
      )
      .subscribe({
        next: (response) => {
          // Traitez la réponse du service ici
          this.loadReservation(response);
        },
        error: (error) => {
          // Gérez les erreurs ici
          console.error("Erreur lors de l'appel du service : ", error);
        }
      });
  }

  private loadReservation(reservation: Reservation) {
    if (
      reservation.status === 'confirmed' ||
      reservation.status === 'awaitingValidation'
    ) {
      this.resaSuccess = true;
    } else {
      this.resaSuccess = false;
    }
    this.reservation = reservation;
    this.vouchers = reservation.vouchers;
    this.places = reservation.places;
    this.center = reservation.center;
    this.createGoogleMapLink();
    this.price = reservation.priceCentsTotal;
    const nonWalletVoucher = this.vouchers.filter((v) => v.type != 'wallet');
    nonWalletVoucher.map((v) => (this.price += v.discountAmount));
    this.centersService
      .listCenterFacilities(this.center.id)
      .subscribe((facilities) => {
        this.facilities = facilities;
        this.facilities.forEach((facility) => {
          if (facility.id === this.reservation.facilityId) {
            this.facility = facility;
            this.displayName = this.getDisplayName(this.facility);
            this.dataAvailable = true;
          }
        });
      });
    this.tagManager.pushReservationDetails({
      user_id: this.authFirebaseService.getUserDataId(),
      reservation_id: this.reservation.id,
      center_id: this.reservation.center.id,
      activity_id: this.reservation.activityName
    });
  }

  public openFeeInfoModal(feeInfo): void {
    if (
      this.reservation.serviceFeeText &&
      this.reservation.serviceFeeText !== ''
    ) {
      this.modalService.dismissAll();
      this.modalService.open(feeInfo, { centered: true });
    }
  }

  widthSize(event): void {
    if (event.target.innerWidth < 1024) {
      this.isLittleWidth = true;
    } else {
      this.isLittleWidth = false;
    }
  }

  createGoogleMapLink(): void {
    this.googleMapLink = `https://www.google.com/maps/search/?api=1&query=${this.center.location.lat},${this.center.location.lon}`;
  }

  openCancelReservation(content): void {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  public cancelReservation(modal: NgbActiveModal): void {
    if (
      this.reservation.status === this.status.Cancel ||
      this.reservation.status === this.status.Fail
    ) {
      this.notCancelableError = this.translateService.instant(
        'my_resa.already_cancelled'
      );
      this.isError = true;
    } else {
      this.reservationsService.cancelReservation(this.resaId).subscribe(
        () => {
          this.isCancel = true;
          setTimeout(() => {
            modal.close();
            window.location.reload();
          }, 2000);
        },
        (error) => {
          this.notCancelableError = error.error.message;
          this.isError = true;
        }
      );
    }
  }
  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  getDisplayName(facility: any): string {
    if (this.isWhiteLabel) {
      return this.capitalizeFirstLetter(this.reservation.activityName);
    }
    return facility.name;
  }
  downloadInvoice(): void {
    this.reservationsService.getInvoice(this.reservation.id).subscribe({
      next: (response: any) => {
        const url = response['invoiceLink'];
        const link = document.createElement('a');
        link.href = url;
        link.download = 'facture';
        link.click();
      },
      error: (error) => {
        this.invoiceErrorMsg = error.message;
        this.modalService.open(this.errorInvoice, { centered: true });
        console.error('Erreur:\n' + error.message);
      }
    });
  }

  canShowDownloadInvoice(): boolean {
    const reservationDate = new Date(this.reservation.start);
    const currentDate = new Date();
    const differenceInDays = Math.ceil(
      (currentDate.getTime() - reservationDate.getTime()) /
      (1000 * 60 * 60 * 24)
    );
    return (
      this.reservation.status === Status.Confirmed && differenceInDays >= 8
    );
  }
}
