<div class="d-flex flex-column login-div">
  <app-top-navbar *ngIf="!isWhiteLabel && !isMobileResolution" [country]="country">
    <div class="row" app-top-navbar-right>
      <app-button-account></app-button-account>
    </div>
  </app-top-navbar>

  <app-top-navbar *ngIf="!isWhiteLabel && isMobileResolution"
                  [idSelector1Langage]="'dropdownCountry20'"
                  [idSelector2Langage]="'dropdownLangage20'"
                  [isTopBarFixed]="false"
                  [isMobileResolution]="isMobileResolution"
                  [country]="country"
                  [yellow]="false">

    <div class="d-flex top-nav-bar" *ngIf="isMobileResolution" app-top-navbar-right>
      <app-button-account [yellow]="false" [border]="false"></app-button-account>
    </div>
  </app-top-navbar>

    <router-outlet></router-outlet>

    <!-- Page succes confirmation mail -->
    <ng-container *ngIf="firstConnexion">
        <div class="d-flex flex-column justify-content-center success-send-mail success-confirmation-mail">

            <img class="buddy  align-self-center" ngSrc="v1677166288/assets/buddy/buddy_clin_doeil_165x165.png" alt="Buddy" width="165" height="165">

            <p class="check-title align-self-center">
                {{ 'account.welcome' | translate }}
            </p>
            <p class="sublabel sublabel-medium mx-3">
                {{ 'account.subwelcome' | translate }}
            </p>
        </div>
    </ng-container>
    <!-- Fin page succes confirmation mail -->


    <!-- footer -->
    <ng-container *ngIf="!isWhiteLabel && !firstConnexion">
        <app-assistance [isMobileResolution]="isMobileResolution"></app-assistance>
    </ng-container>
    <!-- fin footer -->
</div>
