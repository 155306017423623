export class NewMail{
    public email: string;
    public constructor(email: string){
        this.email = email;

    }
}

export class NewMailCode{
    public email: string;
    public code: string;
    public constructor(email: string, code: string){
        this.email = email;
        this.code = code;
    }
}
