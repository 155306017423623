<div class="modal-body">
  <div class="row">
    <div class="">
      <div class="m-0 align-items-center">
        <div class="m-0 expansion-title-spe card-header ">{{'select_tarif.who_comes' | translate}}</div>
        <div class="expansion-title-tarif-st   ">{{'select_tarif.who_comes_st' | translate}} </div>
      </div>
    </div>
  </div>
  <form *ngIf="tarificationPlacesForm" [formGroup]="tarificationPlacesForm">
    <hr class="mt-2 mb-3" />
    <p>{{'select_tarif.verification' | translate}}</p>
    <hr class="mt-2 mb-3" />

    <div class="mb-3" *ngIf="hasSubscriptionPrice()">
      <div class="d-flex block-dimen">{{'select_tarif.your_price' | translate}}</div>
      <div class="card-header">{{subscriptionPrice()}}</div>
    </div>

    <div *ngIf="hasSubscriptionPrice()">
      <div class="d-flex block-dimen">{{'select_tarif.partner_price' | translate}}</div>
    </div>

    <div class="d-flex align-items-center tarifs-supply" *ngFor="let prix of servPrice.prices; let i = index">
      <div class="m-0 card-header">{{prix.name}}<br/>
        {{ prix.price / 100 | currency:'EUR':'symbol':'1.2-2':'fr' }}
      </div>

      <div class="m-0 number-input  d-inline-block ">
        <button (click)="managePlaceSelection('-', prix)" type="button"></button>
        <input  type="number" class="quantity " min="0" name="num" [value]="0" formControlName="{{prix.name}}" disabled readonly>
        <button (click)="managePlaceSelection('+', prix)" type="button" class="plus"></button>
      </div>

    </div>
    <hr class="mt-2 mb-3" />

    <div>
      <div class="d-flex block-dimen">
        <div class="card-header block-wi ">{{'select_tarif.place' | translate}}</div>
        <div class=" card-header">{{placePrise}} / {{totalCapacity}} </div>
      </div>
    </div>

  </form>
</div>
<div class="modal-footer validate-block">

  <div>
    <button class="btn-sup" type="submit" (click)="reset()">{{'select_tarif.reset' | translate}}</button>
  </div>
  <div>
    <button [disabled]="placePrise < totalCapacity" [class.validate-btn]="placePrise < totalCapacity" [class.booking-btn]="placePrise >= totalCapacity" type="submit" (click)="validatePlacesSelected()">{{'select_tarif.validate' | translate}}</button>
  </div>
</div>
