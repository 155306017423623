import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Chracteristics } from '../models/characteristic';
import { Service } from '../models/service';
import { ServiceInformation } from '../models/serviceInformation';
import { ServicesSlots } from '../models/slots';
import { CentersService } from '../service/center.service';
import { SharedService } from '../service/shared.service';
import { BreakpointService } from '../../shared/services/breakpoint.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Output() submitFilter = new EventEmitter<any>();
  @Output() closeFilterModal = new EventEmitter<void>();
  @Input() sport: string;
  @Input() filtercharacteristic;
  @Input() servicetype;
  @Input() sportTypeSelected;
  @Input() isPartner;

  @Input() radius: string;
  @Input() price;

  filtercharchteristicId = [];
  public nbOfParticipants = 1;
  public contentOpen = true;
  public contentOpenTypes = true;
  public contentOpenSub = true;
  public contentOpenEquipment = true;
  public isFitness = false;
  public serviceTypeObject;
  public aqua = false;
  public filterItems;
  public distance;
  public filtreActivity = [];
  public filtreActivityResource = [];
  public checkboxesEquipmentList = [
    {
      label: 'Accessible en fauteuil roulant',
      isChecked: false
    },
    {
      label: 'Location du matériel',
      isChecked: false
    },
    {
      label: 'Parking gratuit',
      isChecked: false
    },
    {
      label: 'Wifi gratuite',
      isChecked: false
    }
  ];
  error: any;
  selectedDate: string;
  selectedSlot: ServicesSlots;
  loading: boolean;
  servicesAvailable: Service[];
  characteristic: Chracteristics[] = [];
  serviceInof: ServiceInformation;
  serviceInformation: ServiceInformation[] = [];
  resaForm: UntypedFormGroup;
  selectedSport: any;
  typeSelected: boolean;
  sportSelected: boolean;
  partySize = 1;
  selectedType: string;
  types: any[];
  filters: any[];
  selectedFilters: any[];
  servicesSlots: ServicesSlots[];
  serviceTypeFiltre: any[];
  private filter = {
    sport: {},
    typesSport: null,
    subActivity: [],
    maxPrice: null,
    maxDistance: null,
    nbParticapants: null,
    isPartner: true
  };
  isMobileResolution = true;
  public formArray: UntypedFormArray;

  constructor(
    private centerService: CentersService,
    private fb: UntypedFormBuilder,
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private breakpointService: BreakpointService,

  ) {
    this.breakpointService.isMobile().subscribe(isMobile => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(isMobileResolution => {
      this.isMobileResolution = isMobileResolution;
    });
  }

  ngOnInit(): void {
    if (this.price === '') {
      this.price = 90;
    }
    if (this.sport !== 'golf') {
      this.distance = 50;
    } else {
      this.distance = 150;
    }
    if (this.radius !== '') {
      this.distance = Number(this.radius);
    }
    this.resaForm = this.fb.group({
      sport: this.selectedSport,
      resaType: '',
      filterChoices: new UntypedFormArray([]),
      partySize: 1,
    });
    const filters = [];
    const formArray: UntypedFormArray = this.resaForm.get('filterChoices') as UntypedFormArray;
    if (this.filtercharacteristic !== undefined) {
      for (const filter of this.filtercharacteristic) {
        filters.push(filter.id);
        formArray.push(new UntypedFormControl(filter.id));
        this.filtercharchteristicId.push(filter.id);
      }
    }
    if (this.sportTypeSelected === undefined && this.sport) {
      this.centerService.getFilterByActivity(this.sport).subscribe(res => {
        this.types = res;
        if (this.types.length < 2) {
          this.onSelectDefautType();
        }
        if (this.servicetype !== '') {
          this.initActivity(this.servicetype);
        }
      });
    }

    if (this.sport === 'tennis' && this.servicetype === '') {
      this.servicetype = 'tennis-court-rental';
    }
  }

  public toogleContentSports(): void {
    this.contentOpen = !this.contentOpen;
  }

  public toogleContentTypesSports(): void {
    this.contentOpenTypes = !this.contentOpenTypes;
  }

  public toogleContentSubSports(): void {
    this.contentOpenSub = !this.contentOpenSub;
  }

  public toogleContentEquipment(): void {
    this.contentOpenEquipment = !this.contentOpenEquipment;
  }

  public changePrice(value): void {
    this.price = value;
  }

  public changeDistance(val: number): void {
    this.distance = val;
  }
  public checkInputSport(event): void {
    this.isFitness = true;
    this.filter.sport = event.target.value;
  }

  public checkedInputTypeSport(event): void {
    this.aqua = true;
    this.filter.typesSport = event.target.value;
  }

  public checkedInputSubActivity(event): void {
    this.aqua = true;
    this.filter.subActivity = event.target.value;
  }

  public modifyNbParticipants(val: string): void {
    val === 'plus' ? this.nbOfParticipants-- : this.nbOfParticipants++;
    if (this.nbOfParticipants < 1) {
      this.nbOfParticipants = 1;
    }
  }

  public onSubmitFilter(): void {
    this.filter.nbParticapants = `${this.nbOfParticipants}`;
    this.filter.maxDistance = `${this.distance}`;
    this.filter.maxPrice = `${this.price} `;
    this.filter.subActivity = this.getCharachteristicInfo(this.filtercharchteristicId);
    this.filter.sport = this.serviceTypeObject;
    this.filter.isPartner = this.isPartner;
    const filterItems = Object.keys(this.filter).filter(item => this.filter[item] !== null);
    const filter = filterItems.reduce((acc, val) => {
      return [
        ...acc,
        this.filter[val],
      ];
    }, []);
    this.submitFilter.emit([...filter]);
  }

  getCharachteristicInfo(characteristicValue): any[] {
    const tabCharach = [];
    for (const tag of characteristicValue) {
      if (tag) {
        for (const filter of this.filters) {
          const val = filter.options.find(f => f.id === tag);
          if (val !== undefined) {
            tabCharach.push(val);
          }
        }
      }
    }
    return tabCharach;
  }
  public closeFilter(): void {
    this.closeFilterModal.emit();
  }
  onItemChange(id): void {

    const vali = this.filtreActivity.find(f => f.id === id.id);
    this.filtreActivityResource = vali.serviceCharacteristicTypes;
  }
 
  onCheckChange(event): void {
    const formArray: UntypedFormArray = this.resaForm.get('filterChoices') as UntypedFormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm

      formArray.push(new UntypedFormControl(event.target.value));
      this.filtercharchteristicId.push(event.target.value);
    }
    /* unselected */
    else {
      // find the unselected element
      let i = 0;
      this.filtercharchteristicId.forEach((ctrl) => {
        if (ctrl === event.target.value) {
          // Remove the unselected element from the arrayForm
          this.filtercharchteristicId.splice(i, 1);

        }
      });
      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value === event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  onResaTypeChange(event, nameActivity): void {
    this.filtercharchteristicId = [];
    this.filtercharacteristic = [];
    this.filters = [];
    this.resaForm.controls.filterChoices = new UntypedFormArray([]);
    this.selectedType = nameActivity;
    this.filters = this.types[event.target.id].resourceCharacteristicTypes;
    this.serviceTypeObject = this.types[event.target.id];
    const characteristicArray = this.types[event.target.id].serviceCharacteristicTypes;
    const arrFiltre = this.types[event.target.id].resourceCharacteristicTypes.concat(characteristicArray);
    const foo = new Map();
    for (const tag of arrFiltre) {
      foo.set(tag.id, tag);
    }
    const final = [...foo.values()];
    this.filters = final;


  }

  initActivity(activityId: string): void {

    this.resaForm.controls.filterChoices = new UntypedFormArray([]);
    const val = this.types.find(r => r.id === activityId);
    this.selectedType = val.name;
    this.filters = val.resourceCharacteristicTypes;
    this.serviceTypeObject = this.types[activityId];
    const characteristicArray = val.serviceCharacteristicTypes;
    const arrFiltre = val.resourceCharacteristicTypes.concat(characteristicArray);
    const foo = new Map();
    for (const tag of arrFiltre) {
      foo.set(tag.id, tag);
    }
    const final = [...foo.values()];
    this.filters = final;
    this.resaForm.controls.resaType.setValue(activityId);
  }

  onSelectDefautType(): void {
    this.resaForm.controls.filterChoices = new UntypedFormArray([]);
    this.selectedType = this.types[0].name;
    this.filters = this.types[0].resourceCharacteristicTypes;
    const arrFiltre = this.types[0].resourceCharacteristicTypes.concat(this.types[0].serviceCharacteristicTypes);
    const foo = new Map();
    for (const tag of arrFiltre) {
      foo.set(tag.id, tag);
    }
    const final = [...foo.values()];
    this.filters = final;
    this.resaForm.controls.resaType.setValue(this.types[0].id);
  }

  getValue(id): boolean {
    const index = this.filtercharacteristic.find(key => key.id === id);
    if (index === undefined) {
      return false;
    }
    else {
      return true;
    }
  }

  setOnlyClubReservable(): void {
    this.isPartner = !this.isPartner;
  }


}
