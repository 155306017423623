import {inject} from "@angular/core";
import { ResolveFn } from '@angular/router';
import {CartInformationService} from "../service/cart-information.service";
import {BookingService} from "../service/booking.service";
import {CartResponse} from "../models/cart-response";

export const PaiementCartResolver: ResolveFn<CartResponse> = (route, state) => {
  const cartId = route.paramMap.get('cartId');
  const cartInformationService: CartInformationService = inject(CartInformationService);
  const bookingService: BookingService = inject(BookingService);

  if(cartInformationService.cartData == null){
    return bookingService.getCart(cartId);
  } else {
    return cartInformationService.cartData;
  }
};
