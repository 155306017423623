import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { RegisterService } from '../register.service';
import { Credentials } from '../../../marketplace/models/credentials';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  isMobileResolution = false;
  membership: UntypedFormControl;
  memberId: UntypedFormControl;
  email: UntypedFormControl;
  password: UntypedFormControl;
  firstName: UntypedFormControl;
  lastName: UntypedFormControl;
  dateOfBirth: UntypedFormControl;
  errorMessage: any;
  error = false;
  country = 'fr';
  language = '';
  errorAuthMsg = '';
  isRegister: boolean;
  centerName = '';
  constructor(
    public registerService: RegisterService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.email = new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ]);
    this.membership = new UntypedFormControl('', [Validators.required]);
    this.password = new UntypedFormControl('', [Validators.required]);
    this.firstName = new UntypedFormControl('', [Validators.required]);
    this.lastName = new UntypedFormControl('', [Validators.required]);
    this.dateOfBirth = new UntypedFormControl('', [Validators.required]);
    this.memberId = new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^\d{6}$/)
    ]);
  }

  isMailValid(): boolean {
    this.email.markAsTouched();
    return this.email.valid;
  }
  isfirstNameValid(): boolean {
    this.firstName.markAsTouched();
    return this.firstName.valid;
  }
  islastNameValid(): boolean {
    this.lastName.markAsTouched();
    return this.lastName.valid;
  }
  isdateOfBirthValid(): boolean {
    this.dateOfBirth.markAsTouched();
    return this.dateOfBirth.valid;
  }

  isPasswordValid(): boolean {
    this.password.markAsTouched();
    return this.password.valid;
  }

  isOptionMember(): boolean {
    return this.membership.valid;
  }

  isMemberIdValid(): boolean {
    this.memberId.markAsTouched();
    if (
      this.membership.value == 'membre_pacha' ||
      this.membership.value == 'frequence'
    ) {
      return this.memberId.valid;
    } else return true;
  }

  getMemberIdValue(): string {
    if (
      this.membership.value == 'membre_pacha' ||
      this.membership.value == 'frequence'
    ) {
      return this.memberId.value;
    } else return '';
  }

  signIn() {
    this.error = false;
    this.errorMessage = null;
    const isEmailValid = this.isMailValid();
    const isPasswordValid = this.isPasswordValid();
    const isfirstNameValid = this.isfirstNameValid();
    const islastNameValid = this.islastNameValid();
    const isdateOfBirthValid = this.isdateOfBirthValid();
    const isMemberIdValid = this.isMemberIdValid();
    const isOptionMember = this.isOptionMember();
    if (
      isEmailValid &&
      isPasswordValid &&
      isfirstNameValid &&
      islastNameValid &&
      isdateOfBirthValid &&
      isMemberIdValid &&
      isOptionMember
    ) {
      this.isRegister = true;
      this.registerService
        .registerUser(
          'foresthill',
          new Credentials(
            this.email.value,
            this.password.value,
            this.firstName.value,
            this.lastName.value,
            this.dateOfBirth.value,
            this.getMemberIdValue()
          )
        )
        .subscribe({
          next: () => {
            this.isRegister = true;
            this.router.navigate(['account']);
          },
          error: (error) => {
            console.error(error);
            this.error = true;
            this.isRegister = false;
            switch (error.status) {
              case 401:
                this.errorMessage = this.translate.instant(
                  'register.error_register'
                );
                break;
              case 400:
                this.errorMessage = this.translate.instant(
                  'register.error_bad_request'
                );
                break;
              case 404:
                this.errorMessage = error.error.message;
                break;
              case 500:
                this.errorMessage = this.translate.instant(
                  'register.error_server'
                );
                break;
              default:
                this.errorMessage = this.translate.instant(
                  'register.error_default'
                );
                break;
            }
            this.errorAuthMsg = this.errorMessage; // Use the more specific error message
          }
        });
    }
  }

  getErrorMessage(form: UntypedFormControl, inputName: string): string {
    if (form.touched) {
      if (form.hasError('required')) {
        switch (inputName) {
          case 'email':
            return this.translate.instant('assistance.email_required');
          case 'password':
            return this.translate.instant('assistance.password_required');
          case 'firstname':
            return this.translate.instant('assistance.firstname_required');
          case 'lastname':
            return this.translate.instant('assistance.lastname_required');
          case 'dateofbirth':
            return this.translate.instant('assistance.dateofbirth_required');
          case 'isMemberIdValid':
            return this.translate.instant('assistance.memberid_required');
          case 'membership':
            return this.translate.instant('assistance.membership_required');
        }
      }

      if (form.hasError('email')) {
        return this.translate.instant('assistance.email_format');
      }

      if (form.hasError('pattern')) {
        return this.translate.instant('assistance.memberId_format');
      }
    }

    return '';
  }
}
