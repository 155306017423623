<div class="assistance-div container"
    [ngClass]="{'mt-3 mb-4 mx-4': isMobileResolution, 'mt-5 mb-5': !isMobileResolution}">
    <div [ngClass]="{'ms-4': !isMobileResolution}">
        {{ 'assistance.assistance' | translate }}
    </div>

    <div class="d-flex justify-content-between flex-wrap" [ngClass]="{'mt-2 mb-4': isMobileResolution}">
        <div>
            <ul class="assistance-ul">
                <li class="assistance-li" [ngClass]="{'mb-2': isMobileResolution, 'my-1': !isMobileResolution}">
                    <img class="question-mark" ngSrc="v1677166288/assets/icon/question-mark.png" alt="question mark" width="32" height="32">
                    <a class="custom-link" [href]="lienUtile">{{ 'assistance.help' | translate }}</a>
                </li>

                <li class="assistance-li" [ngClass]="{'mb-2': isMobileResolution, 'my-1': !isMobileResolution}">
                    <img class="question-mark" ngSrc="v1677166288/assets/icon/question-mark.png" alt="question mark" width="32" height="32">
                    <a class="custom-link" [href]="lienUtile2">{{ 'assistance.how_anybuddy_works' | translate }}</a>
                </li>
            </ul>
        </div>
        <div class="w-100" *ngIf="isMobileResolution"></div>
        <div class="d-flex justify-content-between flex-wrap">
            <ul class="assistance-ul">
                <li class="assistance-li"[ngClass]="{'mb-2': isMobileResolution, 'my-1': !isMobileResolution}">
                    <img class="question-mark" ngSrc="v1677166288/assets/icon/question-mark.png" alt="question mark" width="32" height="32">
                    <a class="custom-link" [href]="lienUtile3">{{ 'assistance.when_can_I_book' | translate }}</a>
                </li>
                <li class="assistance-li"[ngClass]="{'mb-2': isMobileResolution, 'my-1': !isMobileResolution}" >
                    <img class="question-mark" ngSrc="v1677166288/assets/icon/question-mark.png" alt="question mark" width="32" height="32">
                    <a class="custom-link" [href]="lienUtile4">{{ 'assistance.mobile_incompatible' | translate }}</a>
                </li>
            </ul>

        </div>
    </div>
</div>
