
export class CenterRatings {
    public static fromJson(json): CenterRatings {
      return new CenterRatings(
        json.centerId,
        json.clubRating,
        json.clubRatingCount,
        json.resourceRating,
        json.resourceRatingCount,
        json.serviceRating,
        json.serviceRatingCount,
        json.staffRating,
        json.staffRatingCount,
        json.data
      );
    }

    constructor(
      public centerId: string,
      public clubRating: number,
      public clubRatingCount: number,
      public resourceRating: number,
      public resourceRatingCount: number,
      public serviceRating: number,
      public serviceRatingCount: number,
      public staffRating: number,
      public staffRatingCount: number,
      public data: string,
    ) { }

  }
