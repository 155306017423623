
import {
  Component,
  EventEmitter, Injectable,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NgbCalendar, NgbDatepickerI18n, NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DATE_I18N_VALUES } from '../center-calendar/date-trad';
import { Candidates } from '../models/candidates';
import { TypeGround } from '../search/search.component';
import { DatetimeService, FormatDate } from '../service/datetime.service';
import { SearchService } from '../service/search.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { TagManagerService } from "../service/tag-manager.service";

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private userPreferenceService: UserPreferenceService) {
    super();
  }
  getWeekdayLabel(weekday: number): string {
    return DATE_I18N_VALUES[this.userPreferenceService.getCountry()].weekdays[weekday - 1];
  }
  getWeekdayShortName(weekday: number): string {
    return DATE_I18N_VALUES[this.userPreferenceService.getCountry()].weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return DATE_I18N_VALUES[this.userPreferenceService.getCountry()].weekLabel;
  }
  getMonthShortName(month: number): string {
    return DATE_I18N_VALUES[this.userPreferenceService.getCountry()].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
@Component({
  selector: 'app-menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class MenuTopComponent implements OnInit, OnChanges {
  activitySlug = '';
  cityCodePostale = '';
  city: Candidates[] = [];
  centerInfo: Candidates[] = [];
  sport: string;
  @Input() ville: string;
  @Input() dateInfo: string;
  @Input() charachterics = '';
  @Input() price = '';
  @Input() servicetype = '';
  @Input() sportSelected;
  @Output() isSport = new EventEmitter<boolean>();
  public isEspagnole = false;
  public today = new Date().toISOString();
  public minCalendar = new Date().toISOString().slice(0, 10);
  public day: string;
  public dayDisplay: string;
  public heureActuel: string;
  public hours: string[];
  public date: { year: number, month: number };
  public slotSelected: string;
  public model: NgbDateStruct;
  public startDate: NgbDateStruct;
  public placeholderSport: string;
  private typeGround = TypeGround;
  public country: string;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private localize: LocalizeRouterService,
    private translate: TranslateService,
    private calendar: NgbCalendar,
    private datetimeService: DatetimeService,
    private userPreferenceService: UserPreferenceService,
    private tagManager: TagManagerService
  ) { }

  ngOnChanges(): void {
    this.searchService.getGeonameBySlug(this.ville).subscribe(res => {
      this.cityCodePostale = res.name;
    });
  }

  ngOnInit(): void {
    this.country = '';
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
    this.startDate = this.calendar.getToday();
    this.model = this.calendar.getToday();
    if (this.activateRoute.snapshot.queryParamMap.get('date')) {
      this.initDateInfosWithQuery(this.activateRoute.snapshot.queryParamMap.get('date'));
    } else {
      this.initDateInfos();
    }
    this.setLanguage();
    if (this.activateRoute.snapshot.url[0].path === 'reservation-sport') {
      this.sport = 'sport';
      this.placeholderSport = this.translate.instant('menuComponent.sport');
    } else {
      this.sport = this.activateRoute.snapshot.url[0].path.split('-')[2];
      this.placeholderSport = this.sport;
    }
  }

  private setLanguage(): void {
    if (this.userPreferenceService.getLang() === 'es') {
      this.isEspagnole = true;
    }
  }

  private initDateInfosWithQuery(dateInfos: string): void {
    const dateTime: string[] = dateInfos.split('T');
    const [date, slot] = dateTime;
    const newDate: Date = new Date(date);
    const hourData = this.datetimeService.initTimeSlot(newDate, true)
    this.hours = hourData.hours;
    this.day = date;
    const formatDate = this.datetimeService.formatDateDisplay(date, this.userPreferenceService.getLocale(), FormatDate.short);
    this.dayDisplay = formatDate;
    this.heureActuel = slot;
    this.model = this.datetimeService.formatDateStrucModel(dateInfos);
    this.slotSelected = slot;
  }

  private initDateInfos(): void {
    this.model = this.calendar.getToday();
    const now: Date = new Date();
    const hourData = this.datetimeService.initTimeSlot(now, true)
    this.hours = hourData.hours;
    const date = this.datetimeService.formatDate(this.model);
    this.day = date;
    const formatDate = this.datetimeService.formatDateDisplay(date, this.userPreferenceService.getLocale(), FormatDate.short);
    this.dayDisplay = formatDate;
    this.heureActuel = hourData.startingHours;
    this.slotSelected = hourData.startingHours;
  }

  keyPressCity(event): void {
    const row = this.city.find(c => c.name === event.target.value);
    if (row !== undefined) {
      this.onProductChanged(event);
    }
    if (event.target.value.length > 2) {
      this.searchService.searchQueryByType(event.target.value, 'City').subscribe(res => {
        this.city = res.candidates;
      });
    }
  }

  onfoucus(): void {
    this.isSport.emit(true);
  }

  keyPressClub(event): void {
    if (event.target.value.length > 2) {
      this.searchService.searchQueryByType(event.target.value, 'Activity').subscribe(res => {
        this.centerInfo = res.candidates;

      });
    }
  }

  onProductChanged(event): void {
    const row = this.city.find(c => c.name === event.target.value);
    this.cityCodePostale = row.name;
    this.ville = row.slug;
    const fullCity = this.ville.split('-');
    fullCity.pop();
    const city = fullCity.join('-');
    this.tagManager.pushSearchCity({
      city: city,
    })

    if (this.userPreferenceService.getCountry().toLowerCase() !== row.countryCode.toLowerCase()) {
      this.userPreferenceService.setLocaleFromCountry(row.countryCode);
    }
    if (this.sport === 'sport') {
      const translatedPath = this.localize.translateRoute(`reservation-sport`);
      if (this.userPreferenceService.getCountry() !== 'fr') {
        this.router.navigate([`${this.userPreferenceService.getCountry()}/${translatedPath}/` + city]).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate([`${translatedPath}/` + city]).then(() => {
          window.location.reload();
        });
      }
    } else {
      const ground: TypeGround = this.translate.instant('keySport.' + this.sport) === 'tennis'
        ? this.typeGround.Court : this.typeGround.Terrain;
      const sp = this.translate.instant('keySport.' + this.sport);
      const translatedPathSport: any = this.localize.translateRoute(`reservation-${ground}-${sp}`);
      if (this.userPreferenceService.getCountry() !== 'fr') {
        this.router.navigate([`${this.userPreferenceService.getCountry()}/${translatedPathSport}/${city}`]).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate([`${translatedPathSport}/${city}`]).then(() => {
          window.location.reload();
        });
      }
    }
  }

  onClubChanged(event): void {
    const row = this.centerInfo.find(c => c.name === event.target.value);
    this.sport = row.slug;
    this.router.navigate(['/reservation-' + row.slug + '/' + this.ville], { queryParams: { activity: row.slug } }).then(() => {
      window.location.reload();
    });
  }

  onTimeChanged(newTime: string): void {
    this.heureActuel = newTime;
    this.slotSelected = newTime;
    const today = this.datetimeService.formatDate(this.model);
    if (this.sport === 'sport') {
      this.router.navigate(['/reservation-' + this.sport + '/' + this.ville],
        { queryParams: { date: today + 'T' + this.heureActuel } });
    }
    else {
      const [path, city] = this.activateRoute.snapshot.url;
      const translatedPathSport: any = this.localize.translateRoute(`${path}`);
      if (this.userPreferenceService.getCountry() !== 'fr') {
        this.router.navigate([`${this.userPreferenceService.getCountry()}/${translatedPathSport}/${city}`],
          { queryParams: { activity: this.sport, date: today + 'T' + this.heureActuel } }).then(() => {
            window.location.reload();
          });
      } else {
        this.router.navigate([`${translatedPathSport}/${city}`],
          { queryParams: { activity: this.sport, date: today + 'T' + this.heureActuel } }).then(() => {
            window.location.reload();
          });
      }
    }
  }

  onDateChanged(): void {
    const today = this.datetimeService.formatDate(this.model);
    const formatDate = this.datetimeService.formatDateDisplay(today, this.userPreferenceService.getLocale(), FormatDate.short);
    this.day = formatDate;
    this.dayDisplay = formatDate;
    if (formatDate !== this.datetimeService.formatDateDisplay(new Date() + '', this.userPreferenceService.getLocale(), FormatDate.short)) {
      const hourData = this.datetimeService.initTimeSlot(new Date(this.day), true)
      this.hours = hourData.hours;
    } else {
      const hourData = this.datetimeService.initTimeSlot(new Date(), true)
      this.hours = hourData.hours;
    }
    if (this.sport === 'sport') {
      this.router.navigate(['/reservation-' + this.sport + '/' + this.ville],
        { queryParams: { activity: this.sport, date: today + 'T' + this.heureActuel } });
    } else {
      const translatedPathSport: any = this.localize.translateRoute(`${this.activateRoute.snapshot.url[0]}`);
      if (this.userPreferenceService.getCountry() !== 'fr') {
        this.router.navigate([`${this.userPreferenceService.getCountry()}/${translatedPathSport}/${this.activateRoute.snapshot.url[1]}`],
          { queryParams: { activity: this.sport, date: today + 'T' + this.heureActuel } }).then(() => {
            window.location.reload();
          });
      } else {
        this.router.navigate([`${translatedPathSport}/${this.activateRoute.snapshot.url[1]}`],
          { queryParams: { activity: this.sport, date: today + 'T' + this.heureActuel } }).then(() => {
            window.location.reload();
          });
      }
    }
  }

  public onCalendarOpen(event: boolean, slotDrop: NgbDropdown): void {
    if (event) {
      if (slotDrop.isOpen()) {
        slotDrop.close();
      }
    }
  }

  public onTimeSlotOpen(event: boolean, calendarDrop: NgbDropdown): void {
    if (event) {
      if (calendarDrop.isOpen()) {
        calendarDrop.close();
      }
    }
  }
}
