export class Blog {
  constructor(
    public title: string,
    public slug: string,
    public image: string,
    public intro: string,
  ) { }

  public static fromJson(json: object): Blog {
    if (json) {
      return new Blog(
        json['title']['rendered'],
        json['link'].replace('https://blog.anybuddyapp.com/', '').replace('/', ''),
        json['jetpack_featured_media_url'],
        json['excerpt']['rendered']
      );
    } else {
      return null;
    }
  }

  public static RelatedfromJson(json: object): Blog {
    return new Blog(
      json['title'],
      json['url'].replace('https://blog.anybuddyapp.com/', '').replace('/', ''),
      json['img']['src'],
      json['excerpt']
    );
  }
}
