<ng-container>
  <app-loader *ngIf="loading"></app-loader>
</ng-container>

<app-top-navbar
  *ngIf="!isMobileResolution"
  [isMobileResolution]="isMobileResolution"
  [country]="country">
  <div
    class="d-flex justify-content-between align-items-center ms-auto"
    app-top-navbar-right
    *ngIf="!isMobileResolution">
    <a [routerLink]="['/' + country, 'on-boarding']">
      <button class="navbar-btn navbar-btn-light me-0">
        {{'menuComponent.add_your_center' | translate}}
      </button>
    </a>
    <app-button-account-disconnected></app-button-account-disconnected>
  </div>
</app-top-navbar>


<div class="container" *ngIf="!loading && !error">
    <h1 class="title mt-5 mb-5" [innerHTML]="title"></h1>
    <div class="d-flex mb-4">
      <img class="m-auto front-picture" [src]="picture" alt="{{title}}">
    </div>
    <div class="wordpress" [innerHTML]="rawContent"></div>
</div>

<div class="container mt-4" *ngIf="!loading && !error">
  <app-blog-carousel [blogs]="relatedBlogs" [isMobileResolution]="isMobileResolution"></app-blog-carousel>
</div>

<div *ngIf="error" class="d-flex">
  <span class="m-auto text-center error">
    {{error}}
  </span>
</div>

<div class="d-flex flex-column section-footer"
    [ngClass]="{'mt-5': isMobileResolution, 'mt-2 mb-3 mx-5 px-5 ': !isMobileResolution}">
    <app-footer [isMobileResolution]="isMobileResolution"></app-footer>
</div>
