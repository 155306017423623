import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ResetPasswordService } from './reset-password.service';
import {ActivatedRoute, Router} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  errorMessage = '';
  error = false;
  isReset = true;
  email: UntypedFormControl;

  constructor(
    private resetPasswordService: ResetPasswordService,
    private router: Router,
    private translate: TranslateService,
    private activateRoute: ActivatedRoute,
    )
    { }

  ngOnInit(): void {
    const mode = this.activateRoute.snapshot.data['mode'];
    if(mode !== undefined && mode === 'retrieve'){
      this.isReset = false;
    } else {
      this.isReset = true;
    }

    this.email = new UntypedFormControl('', [Validators.required, Validators.email,]);


  }
  isMailValid(): boolean {
    this.email.markAsTouched();
    return this.email.valid;
  }
  onReset() {
    this.error = false;
    this.errorMessage = null;
    const isEmailValid = this.isMailValid();
    if (isEmailValid) {
      this.resetPasswordService.resetPassword('foresthill', this.email.value).subscribe({
        next: () => {
          this.router.navigate(['/account']);
        },
        error: error => {
          this.handleErrorMessage(error);
        }
      });
    }
  }
  getErrorMessage(form: UntypedFormControl, inputName: string): string {
    if (form.touched) {
      if (form.hasError('required') && inputName === 'email') {
        return this.translate.instant('assistance.email_required');
      }else if (form.hasError('email')) {
        return this.translate.instant('assistance.email_format');
      }
      else {
        return '';
      }
    }
  }

  onRetrieve() {
    this.error = false;
    this.errorMessage = null;
    const isEmailValid = this.isMailValid();
    if (isEmailValid) {
      this.resetPasswordService.retrievePassword('foresthill', this.email.value).subscribe({
        next: () => {
          this.router.navigate(['/account']);
        },
        error: error => {
          this.handleErrorMessage(error);
        }
      });
    }
  }

  handleErrorMessage(error): void {
    this.error = true;
    if (error.error instanceof ErrorEvent) {
      console.error('Une erreur s\'est produite:', error.error.message);
    } else {
      switch (error.status) {
        case 400:
          this.errorMessage = this.translate.instant('reset_error.error_reset');
          break;
        case 401:
          this.errorMessage = this.translate.instant('reset_error.error_not_allowed');
          break;
        case 403:
          this.errorMessage = this.translate.instant('reset_error.error_not_allowed2');
          break;
        case 404:
          this.errorMessage = this.translate.instant('reset_error.error_not_found');
          break;
        case 500:
          this.errorMessage = this.translate.instant('reset_error.error_server');
          break;
        default:
          this.errorMessage = this.translate.instant('reset_error.error_unknown');
          break;
      }
    }
  }

  handlePasswordAction(): void {
    if (this.isReset) {
      this.onReset();
    } else {
      this.onRetrieve();
    }
  }
}
