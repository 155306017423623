import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID, OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Center} from '../../models/center';
import {Places} from '../../models/places';
import {ServiceInformation} from '../../models/serviceInformation';
import {PriceService,} from '../../service/shared.service';
import {formatCurrency, getCurrencySymbol} from '@angular/common';
import {Prices} from "../../models/prices";

/* eslint-disable */
@Component({
  selector: 'app-multiple-prices',
  templateUrl: './multiple-prices.component.html',
  styleUrls: ['./multiple-prices.component.scss'],
  providers: [PriceService]
})
export class MultiplePricesComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() center: Center;
  @Input() placesSelected: Places[] = [];
  @Input() servPrice: ServiceInformation;
  @Input() startReset = false;
  @Output() newPlacesSelected = new EventEmitter<Places[]>();
  tarificationPlacesForm: UntypedFormGroup;
  selectedPlaces: Array<Places> = [];
  totalCapacity = 0;
  placePrise = 0;

  constructor(
    public modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    @Inject(LOCALE_ID) public locale: string
  ) {
  }

  ngOnInit() {
    this.init(!this.startReset);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.servPrice && changes.servPrice.currentValue) {
      //this.servPrice = changes.servPrice.currentValue;
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  closeDialog() {
    this.modalService.dismissAll();
  }

  private init(withSelectedPlaces: boolean) {
    this.totalCapacity = this.servPrice.totalCapacity;
    if(withSelectedPlaces){
      this.placePrise = this.servPrice.totalCapacity;
    } else {
      this.placePrise = 0;
    }
    const prices = this.servPrice.prices;
    const group = {};
    prices.forEach((prix) => {
      const name = prix.name;
      const id = prix.id;
      if (withSelectedPlaces) {
        const placeType = this.placesSelected.find(
          (el) => el.priceRuleId === id
        );
        if (placeType === undefined) {
          group[name] = new UntypedFormControl(0);
        } else {
          group[name] = new UntypedFormControl(placeType['nbPlace']);
        }
      } else {
        group[name] = new UntypedFormControl(0);
      }
      const price = prix.price;
      group[price] = new UntypedFormControl();
    });
    this.tarificationPlacesForm = new UntypedFormGroup(group);
    if (this.hasSubscriptionPrice()) {
      const userPlace = this.servPrice.userPrices[0];
      this.managePlaceSelection('+',userPlace, 1);
    }
  }

  isTarificationPlaceFormControlInvalid(name: string): boolean{
   return (!this.tarificationPlacesForm ||  !this.tarificationPlacesForm.controls || !this.tarificationPlacesForm.controls[name])
  }

  managePlaceSelection(selectedOperation: string, price: Prices, incrementPlace?: number): void {
    if (this.isTarificationPlaceFormControlInvalid(price.name)) {
      console.error('Les contrôles ne sont pas définis');
      return
    }
    const incrementUnit = incrementPlace ? incrementPlace : (price.nbPlaces ? price.nbPlaces : 1);
    switch (selectedOperation) {
      case '+':
        this.incrementPlace(price.name , price.id, price.price, incrementUnit)
        break;
      case '-':
        this.decrementPlace(price.name , price.id, incrementUnit)
        break;
      default:
        console.log('Invalid operation type ')
        break
    }
  }

  incrementPlace(name: string, idPrice: string, price: number, incrementUnit: number){
    let customObj = new Places();
    customObj.nbPlace = 0;
    console.log(this.servPrice.availablePlaces)
    if (this.placePrise + incrementUnit <= this.servPrice.totalCapacity) {
      this.placePrise += incrementUnit;
      let place = this.tarificationPlacesForm.controls[name].value;
      this.tarificationPlacesForm.controls[name].setValue(place+1);
      let val = this.selectedPlaces.find((r) => r.priceRuleId == idPrice);
      if (val) {
        val.nbPlace++;
      } else {
        customObj.priceRuleId = idPrice;
        customObj.nbPlace++;
        customObj.name = name;
        customObj.price = price;
        this.selectedPlaces.push(customObj);
      }

    }
  }
  decrementPlace(name: string, idPrice: string, incrementUnit: number): void{
    let place = this.tarificationPlacesForm.controls[name].value;
    let canDecrement = true;
    if(this.hasSubscriptionPrice() && this.servPrice.userPrices[0].name === name){
      canDecrement = place > 1
    } else {
      canDecrement = place >= 1
    }

    if (canDecrement) {
      this.tarificationPlacesForm.controls[name].setValue(place-1);
      this.placePrise -= incrementUnit;
      if (this.selectedPlaces) {
        let val = this.selectedPlaces.find((r) => r.priceRuleId == idPrice);
        if (val && val.nbPlace) {
          val.nbPlace = val.nbPlace - 1;
        }
      }
    }
  }

  public reset(): void {
    Object.keys(this.tarificationPlacesForm.controls).forEach((key) => {
      this.tarificationPlacesForm.get(key).setValue(0);
    });
    this.placePrise = 0;
    this.selectedPlaces = [];
    if(this.servPrice.userPrices && this.servPrice.userPrices.length>0){
      const price = this.servPrice.userPrices[0]
      this.placePrise = 1;
      this.selectedPlaces.push({
        nbPlace: 1,
        priceRuleId : price.id,
        name : price.name,
        price : price.price
      })
    }
  }

  public validatePlacesSelected(): void {
    if (this.selectedPlaces == null || this.selectedPlaces.length == 0) {
      this.selectedPlaces = this.placesSelected;
    }
    this.newPlacesSelected.emit(this.selectedPlaces.filter(p => p.nbPlace > 0));
    this.closeDialog();
  }

  hasSubscriptionPrice(): boolean {
    return this.servPrice.userPrices && this.servPrice.userPrices.length > 0;
  }

  subscriptionPrice(): string {
    if (this.hasSubscriptionPrice()) {
      const priceFmt = formatCurrency(
        this.servPrice.userPrices[0].price / 100,
        this.locale,
        getCurrencySymbol(this.center.currency, 'wide'),
        this.center.currency
      );
      return `${this.servPrice.userPrices[0].name} : ${priceFmt}`;
    } else {
      return null;
    }
  }
}
