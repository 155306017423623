<div *ngIf="showError" class="row">
  <div class="col-12 col-md-6 mt-5 mx-auto ">
    <h1 class="mb-3">{{ 'login.error_valid' | translate}}</h1>
    <p style="color: red">{{errorMsg}}</p>
    <button [routerLink]="'/'" class="btn-custom mt-5">{{ 'login.go_home' | translate}}</button>
  </div>

</div>
<div *ngIf="showVerifMail">
  <div class="col-12 col-md-6 mt-5 mx-auto ">
    <h1 class="mb-3 text-center">{{ 'login.confirmed_mail' | translate}}</h1>
    <div class="d-flex">
      <img class="mx-auto my-2" width="102" height="98" ngSrc="v1677168593/assets/logo/logo-simple.png">
    </div>
    <button [routerLink]="'/login'" class="btn-custom mt-5">{{ 'login.login_title' | translate}}</button>
  </div>
</div>
