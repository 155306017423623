<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons"rel="stylesheet"/>
<div class="d-flex flex-column login-div">
    <nav class="custom-navbar" [ngClass]="{'mt-4 ms-2 mb-3': isMobileResolution, 'my-5': !isMobileResolution}">
        <div class="row">
            <div [ngClass]="{'col-9': isMobileResolution, 'col-10': !isMobileResolution}"></div>
            <div class="col" (click)="onPreviousPage()">
                <img class="navbar-icon" ngSrc="v1677169402/assets/icon/close-circle-fill.png" alt="Menu" width="48" height="48">
            </div>
        </div>
    </nav>
    <div class="section-2-picto" style="text-align: center;">
        <img class="connection-logo" ngSrc="v1677169402/assets/buddy/buddy_165x165.png" width="165" height="165"
            loading="lazy" alt="Anybuddy logo">
    </div>

        <div class="align-self-center font-weight-bold">
            {{ 'resetpw.change_pw' | translate }}</div>

    <h2 [ngClass]="{'my-5 align-self-center': !isMobileResolution}"></h2>
    <p class="link-text">
        {{ 'resetpw.pw_text' | translate }}
    </p>

    <form class="d-flex flex-column" [formGroup]="RequestResetForm" (ngSubmit)="ResetPassword()" >
        <input class="custom-input form-control form-control-lg"
        [ngClass]="RequestResetForm.controls['password'].invalid ? 'is-invalid' : ''"
        placeholder="{{ 'resetpw.new_Password' | translate }}" type="password" required formControlName="password"
        (blur)="onBlur($event)" >

        <div *ngIf="focused && RequestResetForm.controls['password'].touched &&RequestResetForm.controls['password'].touched && RequestResetForm.controls['password'].invalid">
        <div class="text-ctr"
        [ngClass]="focused && RequestResetForm.controls['password'].touched &&RequestResetForm.controls['password'].hasError('required') || RequestResetForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
            <i class="material-icons">
           {{ RequestResetForm.controls['password'].hasError('required') ||
           RequestResetForm.controls['password'].hasError('minlength') ? 'close' : 'done' }}
        </i>
        {{ 'resetpw.nbcaracter' | translate }}
        </div>

        <div class="text-ctr"
        [ngClass]="focused && RequestResetForm.controls['password'].touched &&RequestResetForm.controls['password'].hasError('required') || RequestResetForm.controls['password'].hasError('hasNumber') ? 'text-danger' : 'text-success'">
       <i class="material-icons">
       {{ RequestResetForm.controls['password'].hasError('required') ||
       RequestResetForm.controls['password'].hasError('hasNumber') ? 'close' :
        'done' }}</i>

         {{ 'resetpw.nbNumber' | translate }}
         </div>

        <div class="text-ctr"
         [ngClass]="focused && RequestResetForm.controls['password'].touched &&RequestResetForm.controls['password'].hasError('required') || RequestResetForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
         <i class="material-icons">
        {{ RequestResetForm.controls['password'].hasError('required') ||
        RequestResetForm.controls['password'].hasError('hasCapitalCase') ? 'close' :'done' }}
        </i>
        {{ 'resetpw.nbCapitalCarcter' | translate }}
        </div>

        <div class="text-ctr"
        [ngClass]="focused && RequestResetForm.controls['password'].touched &&RequestResetForm.controls['password'].hasError('required') || RequestResetForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
        <span class="material-icons">
        {{ RequestResetForm.controls['password'].hasError('required') ||
        RequestResetForm.controls['password'].hasError('hasSmallCase') ? 'close' :'done' }}
       </span>
        {{ 'resetpw.nbSmalCaracter' | translate }}
        </div>

        <div class="text-ctr"
        [ngClass]="focused && RequestResetForm.controls['password'].touched &&RequestResetForm.controls['password'].hasError('required') || RequestResetForm.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
        <i class="material-icons">
        {{ RequestResetForm.controls['password'].hasError('required') ||
        RequestResetForm.controls['password'].hasError('hasSpecialCharacters') ? 'close' : 'done' }}
        </i>
        {{ 'resetpw.nbSpecialCaracter' | translate }}
        </div>
    </div>

    <div class="d-flex align-self-center"
     [ngClass]="{'mb-2': isMobileResolution, 'mb-5': !isMobileResolution }">
    </div>
    <input matInput class="custom-input form-control form-control-lg"
    placeholder="{{ 'resetpw.confirm_Password' | translate }}" type="password" formControlName="confirmPassword"
    [ngClass]="RequestResetForm.controls['confirmPassword'].invalid ? 'is-invalid' : ''">

    <div class="errorInfo"
    *ngIf="RequestResetForm.controls['confirmPassword'].hasError('required') && RequestResetForm.controls['confirmPassword'].touched=== true">
    {{ 'resetpw.passwordrequired' | translate }}
</div>

<div class="errorInfo"
    *ngIf="RequestResetForm.controls['confirmPassword'].hasError('NoPasswordMatch')&& RequestResetForm.controls['confirmPassword'].touched=== true">
    {{ 'resetpw.passwornotmuch' | translate }}
</div>

<div *ngIf="errorMsg" class="errorInfos" [ngClass]="{'mb-2': isMobileResolution, 'mb-5': !isMobileResolution }">
    {{errorMsg}}
</div>

<div class="d-flex align-self-center" [ngClass]="{'mb-2': isMobileResolution, 'mb-5': !isMobileResolution }">

</div>

        <button type="submit" class="fill-btn" [disabled]="RequestResetForm.invalid">
            {{ 'resetpw.pw_confirm' | translate }}
        </button>
</form>


