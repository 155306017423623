import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { UserPreferenceService } from '../service/user-preferences.service';

@Component({
  selector: 'app-button-account',
  templateUrl: './button-account.component.html',
  styleUrls: ['./button-account.component.scss']
})
export class ButtonAccountComponent implements OnInit {

  @Input() country: string;
  @Input() border = true;
  @Input() yellow = false;
  @Input() isMarket = true;
  language = '';
  isLoggedIn = false;
  errorMsg: string;

  constructor(private userPreferenceService: UserPreferenceService,
    private route: Router,
    private authFire: AuthFirebaseService,
  ) {
    this.authFire.isTokenGetted.subscribe(
      show => {
        this.isLoggedIn = this.authFire.isLoggedIn
      }, error => {
        this.isLoggedIn = false
        console.error(error)
      }
    )
  }

  ngOnInit(): void {
    this.country = this.userPreferenceService.getCountry();
    if (this.country !== 'fr') {
      this.language = this.country;
    }
    this.userPreferenceService.getlocalChangeCountry().subscribe(
      value => {
        this.country = value;
        if (this.country !== 'fr') {
          this.language = this.country;
        }
      });
  }

  logout(): void {
    this.route.navigate(['/logout']);
  }
}
