<div class="row" *ngIf="sportDataAvailable">
  <div class="d-flex align-items-center pb-4"  *ngIf="sportsToDisplay.length === 0">
    <h3 class="caption-sport-title align-self-center">
      {{ 'sport.no_sport' | translate }}
    </h3>
  </div>
  <div *ngIf="sportsToDisplay.length > 0" class="col-12 row m-0 p-0">
    <ng-container *ngFor="let sport of sportsToDisplay; let i = index">
      <div class="col-6 col-md-4 col-lg-3 card-parent mb-3"  >
        <a class="link" [routerLink]="['/' + country + '/' + translatedPath + '/' + slug]" (mouseover)="sportHover(sport.id)" (touchend)="sportHover(sport.id)" (click)="sportClick(sport.id)">
          <div class="row card-sport" >
            <div class="col-6 p-0 d-flex">
              <img class="sport-image" loading="lazy" width="80" height="83" [src]="sport.icon" alt="{{sport.name}}">
            </div>
            <div class="col-6 p-0 texts">
              <h4 class="title">{{sport.name}}</h4>
              <div class="d-flex text">
                {{'homeComponent.see_clubs' | translate}}
                <div class="icone-container">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/></svg>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-container>
  </div>
</div>
