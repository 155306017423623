<div *ngIf="true">
  <div>
    <h3 style="font-size: 1.4rem">
      Vous apprécierez aussi:
    </h3>
  </div>
  <carousel *ngIf="blogs !== null && blogs.length > 0" [arrowsOutside]="arrowOutside" [cellsToShow]="cardShown" class="carousel-custom">
    <div class="carousel-cell d-flex p-0" *ngFor="let blog of blogs" [style.background]="'url(' + (blog.image ? blog.image : 'https://softlead.ro/assets/media/default_no_photo.png') + ')'" routerLink="{{'/blog/'+ blog.slug}}">
      <div class="calc d-flex w-100">
        <div class="mt-auto text-container">
          <div class="title" [innerHTML]="blog.title"></div>
        </div>
      </div>
    </div>
  </carousel>
</div>
<div *ngIf="blogs !== null && blogs.length === 0" class="d-flex">
  <span class="m-auto text-center">
    Aucun article en lien n'a été trouvé.
  </span>
</div>
