import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { User } from '../models/user';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { SharedService } from '../service/shared.service';
import { TagManagerService } from '../service/tag-manager.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { UserService } from '../service/user.service';
import { BreakpointService } from '../../shared/services/breakpoint.service';
import { AppContextService } from '../../shared/services/app-context.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  isMobileResolution = false;
  isWhiteLabel: boolean;
  firstConnexion = false;
  hasLogin = true;
  user: User;
  listReservation: any;
  country = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private authFirebaseService: AuthFirebaseService,
    private tagManager: TagManagerService,
    private breakpointService: BreakpointService,
    private appContextService: AppContextService
  ) {
    this.isWhiteLabel = this.appContextService.getisWhiteLabel();
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution =
      this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const redirectUrl = params['redirectUrl'];
      if (redirectUrl) {
        this.router.navigateByUrl(redirectUrl);
      }
    });

    this.firstConnexion = false;
    if (!this.authFirebaseService.getIsLoggedIn) {
      this.router.navigate(['/login']);
    } else {
      this.hasLogin = true;
      this.getUser();
    }
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
    this.tagManager.pushMyProfile({
      user_id: this.authFirebaseService.getUserDataId()
    });
  }

  getUser(): void {
    this.userService.getMe().subscribe({
      next: (res) => {
        this.user = res;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
