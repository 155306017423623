<div class="container-menu-top">
    <div class="filter-container">
        <div class="section">
            <input class="inputVille" list="b" [value]="cityCodePostale" autocomplete="off"
            (keypress)="keyPressCity($event)" id="city" role="combobox" name="query" p
            laceholder="ville" (change)="keyPressCity($event)">
            <datalist id="b">
                <div *ngFor="let city of city"><option [value]="city.name" [id]="city.id"></div>
            </datalist>
        </div>
        <div class="section">
            <div #calendarDrop="ngbDropdown" ngbDropdown [autoClose]="false" (openChange)="onCalendarOpen($event, slotDrop)" class="select-custom">
                <span id="dropdownBasic1" ngbDropdownToggle class="date-picker">{{ dayDisplay }}</span>
                <div class="calendar-container dropdown-container" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <div ngbDropdownItem class="calendar">
                        <ngb-datepicker #dp [(ngModel)]="model" [minDate]="startDate" [startDate]="model"></ngb-datepicker>
                        <div class="buttons-container">
                            <button class="btn-close" (click)="calendarDrop.close()" ></button>
                            <button class="btn-apply" [ngStyle]="{'background-color':'#8c6fff'}" (click)="onDateChanged()" (click)="calendarDrop.close()"> {{'menuComponent.apply' | translate}} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section time">
            <div #slotDrop="ngbDropdown" ngbDropdown (openChange)="onTimeSlotOpen($event, calendarDrop)"  class="select-custom">
                <span id="dropdownBasic1" ngbDropdownToggle class="date-picker">{{ slotSelected }}</span>
                <div class="items-container dropdown-container" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <div *ngFor="let hour of hours"
                        ngbDropdownItem class="items"
                        (click)="onTimeChanged(hour)">
                            {{ hour }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
