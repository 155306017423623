/* eslint-disable */
export class Places {

  public static fromJson(json): Places {
    return Places.constructWithParam(
      json.priceRuleId,
      json.nbPlace,
      json.name,
      json.price,
    );
  }

  public priceRuleId: string
  public nbPlace: number
  public name: string
  public price: number

  constructor() {
  }

  private static constructWithParam(
    priceRuleId: string,
    nbPlace: number,
    name: string,
    price: number) {
    const place = new Places();
    place.priceRuleId = priceRuleId;
    place.nbPlace = nbPlace;
    place.name = name;
    place.price = price;
    return place;
  }
}
