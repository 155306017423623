import { Injectable } from '@angular/core';
import {CartResponse} from "../models/cart-response";

@Injectable({
  providedIn: 'root'
})
export class CartInformationService {
  public cartData: CartResponse;
  constructor() {}
}
