<div class="main-container">
    <div class="container">
        <div class="header-container">
            <div class="price-icon-container">
                <img class="h-auto" ngSrc="v1677169402/assets/icon/price-tag-3-fill.png" alt="price-tag" width="32" height="32">
            </div>
            <h4 class="title mb-5"> {{ 'voucher.have_code' | translate }} ?</h4>
        </div>
        <div class="form-container">
            <form #f="ngForm" (ngSubmit)="onSubmit(f)"  class="form">
                <input type="text" class="input"  name="promo" ngModel required placeholder="{{placeholderInput}}">
                <p *ngIf="error" class="text-danger">{{ 'voucher.enter_promo_code' | translate }}</p>
                <button class="submit">{{ 'voucher.confirm' | translate }}</button>
              </form>
        </div>
        <div class="others-container mt-5" *ngIf="isEspagnole">
            <h4 class="title"> {{ 'voucher.other_code' | translate }} </h4>
            <div class="others-wrapper">
                <div class="wrapper" (click)="openModalClub(mymodal, 'Gymlib', 'promo3')">
                    <div class="gymlib club"><img class="h-auto" ngSrc="v1677169402/assets/promotion/promo3.png" width="100" height="67"
                        style="
                                width: 77%;
                                margin-top: 25%;
                                margin-left: 8%;
                    "/></div>
                    <span class="club-name">Gymlib</span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #mymodal let-modal >
    <div class="modal-header">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="arrow-back" (click)="closeModal(modal)"><path fill="none" d="M0 0h24v24H0z"/><path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/></svg>
    </div>
   <div class="modal-body">
       <!-- <app-external-code [imgPartner]="imgPartner" [partner]="partner"
                          [startDate]="startDate" [duration]="duration"
                          [price]="stateService.total" [centerId]="centerId" [activity]="activity"
       ></app-external-code> -->
    </div>
</ng-template>
