import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ACTIVITY_LIST } from '../models/activity-list';
import { TypeGround } from '../search/search.component';
import { ActivityService } from '../service/activity.service';
import { SelectedSportService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import {TagManagerService} from "../service/tag-manager.service";
@Component({
  selector: 'app-sports-carousel',
  templateUrl: './sports-carousel.component.html',
  styleUrls: ['./sports-carousel.component.scss'],
  providers: [NgbCarouselConfig]
})
export class SportsCarouselComponent implements OnInit {
  sports: any = ACTIVITY_LIST.filter(menuItem => menuItem);
  sportsToDisplay = [];
  sportsByFive = [];

  @ViewChild('carousel') carousel: NgbCarousel;

  @Input() isMobileResolution: boolean;
  @Input() pageClub: boolean;
  @Input() pageReservation: boolean;
  @Input() pageCommunity: boolean;
  @Input() activities: any;
  @Input() coords: string;
  @Input() sportAvaibility: string;
  @Input() sportSelect;
  @Input() redirection: boolean;
  @Input() slug: string;
  @Input() city: string;
  @Input() homePage: boolean;
  @Input() url: string;

  @Output() newSportSelectedEvent = new EventEmitter<string>();
  dates = [];
  nbSportsRow = 0;
  sportDataAvailable = false;
  lessThanFourSports = false;
  lessThanSixSports = false;
  selected = false;
  selectedSport = '';
  activeId: string;
  translatedPath: any;
  country: string;
  public selectedSportIndex: any;

  private readonly typeGround = TypeGround;


  constructor(
    config: NgbCarouselConfig,
    private userPreferenceService: UserPreferenceService,
    private activityService: ActivityService,
    private selectedSportService: SelectedSportService,
    private translate: TranslateService,
    private localize: LocalizeRouterService,
    private tagManager: TagManagerService
  ) {
    config.interval = 0;
    config.keyboard = false;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
    if (this.sportAvaibility !== undefined && this.sportAvaibility !== 'sports') {
      this.selectedSport = this.sportAvaibility;
    }
    if (this.city !== undefined) {
      this.getActivitiesByCity(this.city);
    } else {
      if (!this.pageClub && !this.pageReservation) {
        if (this.homePage) {
          this.userPreferenceService.getlocalChangeCountry().subscribe(country => {
            this.country = country !== 'fr' ? country : '';
            this.getActivitiesbyCountry(country);
          });
        }
        else {
          if (this.coords !== undefined) {
            this.getActivities(this.coords);
          } else {
            this.getActivities('48.8534,2.3488');
          }
        }
      } else {
        if (this.pageReservation && this.activities.length === 1) {
          this.newSportSelectedEvent.emit(this.activities[0].id);
        }
        else {
          this.selectSportsRegistered();
        }
      }
      this.selectedSportService.changeEmitted$.subscribe(
        selectedSport => {
          this.selectedSport = selectedSport;
        });
    }

  }

  getActivities(location: string): void {
    this.activityService.getActivities(location)
      .subscribe(res => {
        const key = 'activities';
        this.activities = res[key];
        this.selectSportsRegistered();
      });
  }

  getActivitiesbyCountry(countryCode: string): void {
    this.activityService.getActivitiesByCountry(countryCode)
      .subscribe(res => {
        this.sportsToDisplay = [];
        const key = 'activities';
        this.activities = res[key];
        this.selectSportsRegistered();
      });
  }

  getActivitiesByCity(city: string): void {
    this.activityService.getActivitiesByCity(city)
      .subscribe(res => {
        const key = 'activities';
        this.activities = res[key];
        this.selectSportsRegistered();
      });
  }

  selectSportsRegistered(): void {
    for (const sport of this.activities) {
      if (sport.id !== 'footgolf'
        && sport.id !== 'table-tennis'
        && sport.id !== 'racquetball'
        && sport.id !== 'beach-tennis'
        && sport.id !== 'futsal'
      ) {
        this.sportsToDisplay.push(sport);
      }
    }
    if (this.sportsToDisplay.length < 4) { this.lessThanFourSports = true; }
    if (this.sportsToDisplay.length < 6) { this.lessThanSixSports = true; }
    if (!this.isMobileResolution && !this.lessThanSixSports) {
      this.createShortSportsList();
    } else {
      this.sportDataAvailable = true;
    }
    this.activeId = this.getActiveSport();
  }

  getImageUrl(sportInfo): string {
    return sportInfo.icon;
  }

  createShortSportsList(): void {
    let j = 0;
    for (let i = 0; i < this.sportsToDisplay.length; i = i + 5) {
      this.sportsByFive[j] = [];
      if (this.sportsToDisplay[i]) { this.sportsByFive[j].push(this.sportsToDisplay[i]); }
      if (this.sportsToDisplay[i + 1]) { this.sportsByFive[j].push(this.sportsToDisplay[i + 1]); }
      if (this.sportsToDisplay[i + 2]) { this.sportsByFive[j].push(this.sportsToDisplay[i + 2]); }
      if (this.sportsToDisplay[i + 3]) { this.sportsByFive[j].push(this.sportsToDisplay[i + 3]); }
      if (this.sportsToDisplay[i + 4]) { this.sportsByFive[j].push(this.sportsToDisplay[i + 4]); }
      j++;
    }
    let k = 0;
    j = 0;
    for (const sp of this.sportsByFive[this.sportsByFive.length - 1]) {
      if (!sp) {
        this.sportsByFive[this.sportsByFive.length - 1][k] = this.sports[j];
        j++;
      }
      k++;
    }
    this.sportDataAvailable = true;
  }

  swipe(e): void {
    if (e === 'swiperight') {
      this.carousel.prev();
    } else {
      this.carousel.next();
    }
  }

  clickEvent(): void {
    this.selected = !this.selected;
  }

  onSportSelected(value: string): void {
    if (value !== '') {
      this.newSportSelectedEvent.emit(value);
      this.selectedSport = value;
      this.selectedSportIndex = 0;
      this.sportClick(value);
    }
  }

  getActiveSport(): string {
    const i = this.sportsToDisplay.findIndex(sp => sp.id === this.sportSelect);
    const index = i === 0 ? this.sportsToDisplay.length - 1 : i - 1;
    return `ngb-slide-${index}`;
  }

  sportHover(sport: string): void {
    let ground: TypeGround = this.typeGround.Terrain;
    if (this.translate.instant('keySport.' + sport) === 'fitness') {
      ground = this.typeGround.Session;
    }
    else if (this.translate.instant('keySport.' + sport) === 'tennis') {
      ground = this.typeGround.Court;
    }
    // this.localize.changeLanguage(this.userPreferenceService.getLang());
    this.translatedPath = this.localize.translateRoute(`reservation-${ground}-${this.translate.instant('keySport.' + sport)}`);
  }

  sportClick(sport: string) {
    this.tagManager.pushSelectSport({
      'activity_id': sport,
    })
  }

}
