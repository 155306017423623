import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {RouterService} from "./router.service";
import {UserPreferenceService} from "../../marketplace/service/user-preferences.service";
@Injectable({
  providedIn: 'root',
})
export class WhiteLabelNavbarService {

  private contentNavigationBarSubject = new BehaviorSubject(new NavbarContent(false, 'fr', true));
  public contentNavigationBarObservable = this.contentNavigationBarSubject.asObservable();
  constructor(
    private routerService: RouterService,
    private userPreferenceService: UserPreferenceService,
  ) {
    this.routerService.currentUrlObservable.subscribe(() => {
      this.sendShowNavBarData();
    });
   }

  sendShowNavBarData(navbarContent?: NavbarContent): void {
    const country = this.userPreferenceService.getCountry();
    const navbarContentToPush = navbarContent ? navbarContent : new NavbarContent(true, country, true);
    this.contentNavigationBarSubject.next(navbarContentToPush);
  }

}

export class NavbarContent {
  constructor(
    public showNavbar: boolean,
    public country: string,
    public isTopBarFixed: boolean,
    public idSelector1Langage = 'dropdownCountry',
    public idSelector2Langage = 'dropdownLangage'
  ) { }
}
