import { Component, ElementRef, Injectable, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../service/user.service';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, EmailAuthProvider } from '@angular/fire/auth';
import { TagManagerService } from "../service/tag-manager.service";

@Component({
  selector: 'app-my-preferences',
  templateUrl: './my-preferences.component.html',
  styleUrls: ['./my-preferences.component.scss']
})

@Injectable({
  providedIn: 'root',
})
export class MyPreferencesComponent {

  errorMsg: string;
  msgMarketing: string;
  errorMsgMarketing = false;
  marketingForm: UntypedFormGroup;
  marketingType: Array<any> = [
    { id: 'sms', text: 'by_sms' },
    { id: 'mail', text: 'by_mail' },
    { id: 'pushnotif', text: 'by_push' },
  ];
  emailInvalid = false;
  phoneInvalid = false;
  passwordInvalid = false;
  updateMailForm: UntypedFormGroup;
  updatePhoneForm: UntypedFormGroup;
  updatePhoneValidationCodeForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  errorFlowMsg: string;
  errorPasswordMsg: string;
  updateType: string;
  verificationId: string;
  captcha = false;
  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private modal: NgbModal,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private fireService: AuthFirebaseService,
    private tagManager: TagManagerService,
  ) {
    const isMarket = this.activatedRoute.snapshot.queryParams['market'];
    if (isMarket === false) {
      this.isMarket = isMarket;
    }
    this.initForm();
  }

  @ViewChild('deleteAccountModal') deleteAccountModal: ElementRef;
  @ViewChild('deleteAwaitingAccount') deleteAwaitingAccount: ElementRef;
  @ViewChild('deleteAccountResult') deleteAccountResult: ElementRef;

  @ViewChild('updateMailModal') updateMailModal: ElementRef;
  @ViewChild('updatePhoneModal') updatePhoneModal: ElementRef;
  @ViewChild('updatePhoneCodeModal') updatePhoneCodeModal: ElementRef;
  @ViewChild('updatingEmailAwaiting') updatingEmailAwaiting: ElementRef;
  @ViewChild('updatingPhoneAwaiting') updatingPhoneAwaiting: ElementRef;
  @ViewChild('updatingAccountResult') updatingAccountResult: ElementRef;
  @ViewChild('updatingAccountResultPhone') updatingAccountResultPhone: ElementRef;

  @ViewChild('errorModal') errorModal: ElementRef;
  @ViewChild('passwordModal') passwordModal: ElementRef;
  isMarket = true;



  initForm(): void {
    this.marketingForm = this.fb.group({
      selectedMarketingCommunication: new UntypedFormArray([])
    });

    this.updateMailForm = this.fb.group({
      newMail: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    this.updatePhoneForm = this.fb.group({
      newPhone: new UntypedFormControl('', [Validators.required])
    });

    this.updatePhoneValidationCodeForm = this.fb.group({
      validationCode: new UntypedFormControl('', [Validators.required])
    });

    this.passwordForm = this.fb.group({
      password: new UntypedFormControl('', [Validators.required])
    });

    this.userService.getUserPreferences().subscribe({
      next: (preferences) => {
        this.initPreferences(preferences);
      },
      error: (err) => console.error('Observer got an error: ' + err),
      complete: () => console.log('Observer got a complete notification')
    });
  }

  initPreferences(preferences: any): void {
    const selectedMarketing = (this.marketingForm.controls.selectedMarketingCommunication as UntypedFormArray);
    this.addInitPreference(selectedMarketing, preferences.mailEnabled, 'mail');
    this.addInitPreference(selectedMarketing, preferences.pushEnabled, 'pushnotif');
    this.addInitPreference(selectedMarketing, preferences.smsEnabled, 'sms');
  }

  updatePreferences(preferences: any): void {
    const selectedMarketing = (this.marketingForm.controls.selectedMarketingCommunication as UntypedFormArray);
    selectedMarketing.clear();
    this.initPreferences(preferences);
  }

  addInitPreference(marketingForm: UntypedFormArray, preference: boolean, value: string): void {
    if (preference === true) {
      marketingForm.push(new UntypedFormControl(value));
    }
  }

  onCheckboxChange(event: any): void {
    const selectedMarketing = (this.marketingForm.controls.selectedMarketingCommunication as UntypedFormArray);
    if (event.target.checked) {
      selectedMarketing.push(new UntypedFormControl(event.target.value));
    } else {
      const index = selectedMarketing.controls
        .findIndex(x => x.value === event.target.value);
      selectedMarketing.removeAt(index);
    }
  }

  isChecked(id: any, control: AbstractControl): boolean {
    const formArray = control as UntypedFormArray;
    return formArray.value.includes(id);
  }

  saveMarketingData(): void {
    const data = {
      smsEnabled: this.marketingForm.controls.selectedMarketingCommunication.value.includes('sms'),
      mailEnabled: this.marketingForm.controls.selectedMarketingCommunication.value.includes('mail'),
      pushEnabled: this.marketingForm.controls.selectedMarketingCommunication.value.includes('pushnotif')
    };
    this.userService.updateUserPreferences(data).subscribe({
      next: (preferences) => {
        this.updatePreferences(preferences);
        this.msgMarketing = this.translate.instant('my_preferences.change_save');
        this.errorMsgMarketing = false;
      },
      error: (err) => {
        console.error('Observer got an error: ' + err);
        this.msgMarketing = err.message;
        this.errorMsgMarketing = true;
      },
    });
  }

  askAccountDeletion(): void {
    this.modal.open(this.deleteAccountModal);
  }

  openModal(template): void {
    this.closeModal();
    this.modal.open(template, { centered: true, windowClass: 'my-modal-content' });
  }

  closeModal(): void {
    this.modal.dismissAll();
  }

  deleteAccount(): void {
    this.userService.deleteUser(this.fireService.getUserDataId()).subscribe({
      next: () => {
        this.tagManager.pushDeleteAccount({
          userId: this.fireService.getUserDataId(),
        })
        this.openModal(this.deleteAccountResult);
      },
      error: (error) => {
        this.errorMsg = error.message;
        this.openModal(this.deleteAccountResult);
      }
    });
  }

  closeResultModal(): void {
    if (this.errorMsg) {
      this.closeModal();
      setTimeout(() => {
        this.errorMsg = '';
      }, 500);
    } else {
      this.closeModal();
      this.logout();
    }
  }

  logout(): void {
    this.route.navigate(['/logout']);
  }

  openUpdateMailModal() {
    this.openModal(this.updateMailModal)
  }

  reAuthenticate(type: string) {
    if(type !== "email" && type !== "phone"){
      this.errorFlowMsg = 'invalid type'
      this.openErrorModal()
      return
    }
    this.updateType = type;
    this.passwordForm.controls.password.setValue('');
    this.updateMailForm.controls.newMail.setValue('');
    this.errorFlowMsg = '';
    const user = this.fireService.getUserFire();
    if (user) {
      const userProviders = user.providerData;
      if (userProviders.length > 0) {
        const userPasswordProvider = userProviders.find(v => v.providerId == 'password')
        if (userPasswordProvider == undefined || (userPasswordProvider != undefined && userProviders.length > 1)) {
          this.reAuthenticateWithProvider(userProviders)
        } else {
          this.openPassworProviderModal()
        }
      } else {
        this.errorFlowMsg = 'no provider found'
        this.openErrorModal()
      }
    } else {
      this.errorFlowMsg = 'no user found'
      this.openErrorModal()
    }
  }

  openPhoneModal() {
    this.captcha = false;
    this.updatePhoneForm.controls.newPhone.setValue('');
    this.updatePhoneValidationCodeForm.controls.validationCode.setValue('');
    this.openModal(this.updatePhoneModal)
  }

  openPassworProviderModal() {
    this.openModal(this.passwordModal)
  }

  selectProvider(providers) {
    const isGoogle = providers.find(v => v.providerId === 'google.com') != undefined
    const isApple = providers.find(v => v.providerId == 'apple.com') != undefined
    const isFacebook = providers.find(v => v.providerId == 'facebook.com') != undefined
    if (isGoogle) {
      return new GoogleAuthProvider()
    } else if (isApple) {
      return new OAuthProvider('apple.com')
    } else if (isFacebook) {
      return new FacebookAuthProvider()
    }
  }

  reAuthenticateWithProvider(providers) {
    const provider = this.selectProvider(providers)
    const user = this.fireService.getUserFire();
    if (user) {
      this.fireService._reauthenticateWithPopup(provider).then(
        () => {
          if(this.updateType === "email"){
            this.openUpdateMailModal()
          } else {
            this.openPhoneModal()
          }
        }
      ).catch(
        reason => {
          this.errorFlowMsg = reason
          this.openErrorModal()
        }
      )
    } else {
      this.errorFlowMsg = 'no user found';
      this.openErrorModal()
    }
  }

  openErrorModal() {
    this.openModal(this.errorModal)
  }

  checkPassword() {
    this.passwordInvalid = false
    if (this.passwordForm.valid) {
      this.reAuthenticateWithPassword()
    } else {
      this.passwordInvalid = true;
    }
  }

  reAuthenticateWithPassword() {
    this.errorPasswordMsg = '';
    const user = this.fireService.getUserFire()
    if (user) {
      this.fireService._reauthenticateWithCredential(
        EmailAuthProvider.credential(user.email, this.passwordForm.controls.password.value)
      ).then(() => {
        if(this.updateType === "email"){
        this.openUpdateMailModal()
        } else {
          this.openPhoneModal()
        }
      }
      ).catch(
        reason => {
          this.errorPasswordMsg = reason
          this.openErrorModal()
        }
      )
    } else {
      this.errorFlowMsg = 'No user'
      this.openErrorModal()
    }
  }

  updateMail(): void {
    this.emailInvalid = false;
    this.errorMsg = ''
    if (this.updateMailForm.valid) {
      const newMail = this.updateMailForm.controls.newMail.value
      this.openModal(this.updatingEmailAwaiting)
      this.fireService._updateEmail(newMail).then(
        () => {
          this.openModal(this.updatingAccountResult)
          this.fireService._sendVerificationMail()
        }
      ).catch(reason => {
        this.errorMsg = reason
        this.openModal(this.updatingAccountResult)
      })
    } else {
      this.emailInvalid = true;
    }
  }

  updatephone() {
    this.phoneInvalid = false;
    this.errorMsg = ''
    if (this.updatePhoneForm.valid) {
      const recaptchaContainer = document.getElementById('recaptcha-container');
      const recaptchaParameters = {
        size: 'normal',
        callback: (_) => {
          this.openModal(this.updatePhoneCodeModal)
        }
      };
      const phoneNumber = this.updatePhoneForm.controls.newPhone.value
      this.captcha = true;
      this.fireService._verifyPhoneNumber(recaptchaContainer, recaptchaParameters, phoneNumber).then(
        (verificationId) => {
          this.verificationId = verificationId
        }
      ).catch(reason => {
        this.errorMsg = reason
        this.openModal(this.updatingAccountResultPhone)
      })
    } else {
      this.phoneInvalid = true;
    }
  }

  valideCode() {
    const code = this.updatePhoneValidationCodeForm.controls.validationCode.value
    this.fireService._getPhoneAuthCredential(this.verificationId, code).then(
      (credential) => {
        this.openModal(this.updatingPhoneAwaiting)
        this.fireService._updatePhoneNumber(credential).then(
          () => {
            this.userService.updateProfilePhone(this.updatePhoneForm.controls.newPhone.value).subscribe(() => {
              console.log('phone updated')
              setTimeout(() => {
                this.route.navigate(['/logout'])
              }, 2000)
            })
            this.openModal(this.updatingAccountResultPhone)
          }).catch(reason => {
            this.errorMsg = reason
            this.openModal(this.updatingAccountResultPhone)
          })
        }
    ).catch(reason => {
      this.errorMsg = reason
      this.openModal(this.updatingAccountResultPhone)
    })
  }
}
