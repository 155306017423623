import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
/* eslint-disable */
declare var require: any
@Injectable({
    providedIn: 'root'
})
export class MapService {

  public L = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
     if (isPlatformBrowser(this.platformId)){
      this.L  =  require('leaflet');
    }
  }
}
