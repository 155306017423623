import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

/* eslint-disable */
@Injectable({
  providedIn: 'root'
})
export class BrowserStorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: object) { }

  getItem(key: string): string {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.getItem(key);
    }
  }

  setItem(key: string, value: string) {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(key, value);
    }
  }

  setObject(key: string, value: any) {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  removeItem(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem(key);
    }
  }

  clear() {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.clear();
    }
  }
}
