<div class="modal-header text-center">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true align">&times;</span>
    <i class="fas fa-times-circle"></i>
  </button>
  <span class="modal-title title w-100">{{ 'moredetails.header' | translate }}</span>
</div>

<div class="modal-body">
  <div class="info-header">
    <p class="sub-title">{{session.name}}</p>
    <div>
     <div class="d-flex">
       <div>
         {{ 'moredetails.period' | translate }}
         {{session.startTime| date:'HH:mm' }} -
         {{session.duration }}min
       </div>


       <div *ngIf="!session.availablePlaces" class="price">
         <span *ngIf="selectedSport==='golf' || selectedSport==='fitness'"> {{'select_activity.starting_from' | translate }} {{ session.price  / 100 | currency:session.currency:'symbol':'1.2-2':'fr' }} </span>
         <span *ngIf="selectedSport!=='golf' && selectedSport!=='fitness'">{{ session.price  / 100 | currency:session.currency:'symbol':'1.2-2':'fr' }} </span>
       </div>


     </div>
      <div class="d-flex" *ngIf="session.availablePlaces">
        <div>
          {{ 'moredetails.group' | translate : {players:session.totalCapacity} }} - {{session.availablePlaces}} {{ 'center_availabilities.places_available' | translate }}
        </div>
        <div class="price">
          <span *ngIf="selectedSport==='golf' || selectedSport==='fitness'"> {{'select_activity.starting_from' | translate }} {{ session.price  / 100 | currency:session.currency:'symbol':'1.2-2':'fr' }} </span>
          <span *ngIf="selectedSport!=='golf' && selectedSport!=='fitness'">{{ session.price  / 100 | currency:session.currency:'symbol':'1.2-2':'fr' }} </span>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <p class="sub-title">{{ 'moredetails.moreInfo' | translate }}</p>
  <div class="text">
    <p class="markdownCustom mb-0" [innerHtml]="session.description"></p>
  </div>
</div>

<div class="modal-footer d-flex">
  <button class="btn btn-block book-button" (click)="bookOrChooseTarif()">
    <p class="m-0" *ngIf="session.prices.length>0">{{'select_activity.book_tarif' | translate }}</p>
    <p class="m-0" *ngIf="session.prices.length===0">{{'select_activity.book' | translate }}</p>
  </button>
</div>
