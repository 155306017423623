import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../marketplace/service/shared.service';
import { UserPreferenceService } from '../../marketplace/service/user-preferences.service';
import {WhiteLabelCenterService} from "../service/white-label-center.service";

@Component({
  selector: 'app-footer-white-lab',
  templateUrl: './footer-white-lab.component.html',
  styleUrls: ['./footer-white-lab.component.scss']
})
export class FooterWhiteLabComponent implements OnInit {
  @Input() isMobileResolution: boolean;
  centerLogo: string;
  languageChoosed;

  public languageFooter: string;
  constructor(
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private ngbModale: NgbModal,
    private whiteLabelCenterService: WhiteLabelCenterService,
  ) {
    this.languageFooter = this.userPreferenceService.getCountry();
  }

  ngOnInit(): void {
    this.centerLogo = this.whiteLabelCenterService.getWhiteLabelCenterLogo();
    this.languageFooter = this.userPreferenceService.getCountry();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
    this.userPreferenceService.getChoosedLanguage().subscribe(l => {
      this.languageChoosed = l;
    });
  }

  openLangageModal(modal: TemplateRef<any>): void {
    this.ngbModale.open(modal, { windowClass: 'custom-modal' });
  }
}
