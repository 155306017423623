/* eslint-disable */
export class Feedback {

  public static fromJson(json: object): Feedback {
    return new Feedback(
      json['id'],
      json['anybuddyRating'],
      json['clubRating'],
      json['serviceRating'],
      json['comment'],
      json['discoverMsg'],
    );
  }
  constructor(
    public id: string,
    public anybuddyRating: number,
    public clubRating: number,
    public serviceRating: number,
    public comment: string,
    public discoverMsg?: string,
  ) {
  }
}
