import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Center } from '../models/center';
import { CentersService } from './center.service';
import { TagManagerService } from "./tag-manager.service";

@Injectable()
export class FavoriteService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private centersService: CentersService,
    private tagManger: TagManagerService,
  ) { }

  favortieKey = 'favorites';

  isFavorite(centerId: string): boolean {
    this.createFavoriteIfnotExist();
    if (isPlatformBrowser(this.platformId)) {
      const favorites = JSON.parse(localStorage.getItem(this.favortieKey));
      return favorites.indexOf(centerId) !== -1;
    }
  }

  createFavoriteIfnotExist(): void {
    if (isPlatformBrowser(this.platformId) && !localStorage.getItem(this.favortieKey)) {
      localStorage.setItem(this.favortieKey, JSON.stringify([]));
    }
  }

  addToFavorite(centerId: string): void {
    this.createFavoriteIfnotExist();
    const favorites = JSON.parse(localStorage.getItem(this.favortieKey));
    favorites.push(centerId);
    this.tagManger.pushAddToWishList({
      center_id: centerId,
    })
    localStorage.setItem(this.favortieKey, JSON.stringify(favorites));
  }

  removeFromFavorite(centerId: string): void {
    this.createFavoriteIfnotExist();
    if (isPlatformBrowser(this.platformId)) {
      const favorites = JSON.parse(localStorage.getItem(this.favortieKey));
      const index = favorites.indexOf(centerId);
      if (index !== -1) {
        favorites.splice(index, 1);
        this.tagManger.pushRemoveToWishList({
          center_id: centerId,
        })
      }
      localStorage.setItem(this.favortieKey, JSON.stringify(favorites));
    }
  }

  addOrRemoveFavoriteIfExist(centerId: string): void {
    if (this.isFavorite(centerId)) {
      this.removeFromFavorite(centerId);
    } else {
      this.addToFavorite(centerId);
    }
  }

  getFavoriteCenter(): Array<Center> {
    if (isPlatformBrowser(this.platformId)) {
      this.createFavoriteIfnotExist();
      const favoriteList = [];
      const favorites = JSON.parse(localStorage.getItem(this.favortieKey));
      favorites.forEach(favorite => {
        this.centersService.getCenter(favorite).subscribe({
          next: (center) => {
            favoriteList.push(center);
          },
          error: () => { console.error('error element not find'); }
        });
      });
      return favoriteList;
    }
  }
}
