import { Service } from './service';

export class ServicesSlots {
  public static fromJson(json): ServicesSlots {
    return new ServicesSlots(
      json.startDateTime,
      json.services
    );
  }

  constructor(
    public startDateTime: string,
    public services: Service[],
  ) { }
}
