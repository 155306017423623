import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Feedback } from '../models/feedback';
import { FeedbackService } from '../service/feedback.service.';
import { UserPreferenceService } from '../service/user-preferences.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  feedback: Feedback;
  id: string;
  hasSavingPhasePassed = false;
  hasFullFiveStars = false;
  selectedLanguage: string;
  error = false;
  selectedDiscoverMethod = 0;
  discoverMessage = null;
  showOtherInput = false;

  constructor(
    private feedbackService: FeedbackService,
    private route: ActivatedRoute,
    private userPreferenceService: UserPreferenceService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.feedbackService.getFeedback(id).subscribe({
          next: (feedback: Feedback) => {
            this.feedback = feedback;
          }, error: (error) => {
            this.feedback = new Feedback(id, null, null, null, null, null);
          }
        }
      );
    }
    this.selectedLanguage = this.userPreferenceService.getLang();
  }

  hasFullFiveStarsFeedback(): void {
    if (this.feedback.serviceRating === 5 && this.feedback.clubRating === 5 && this.feedback.anybuddyRating === 5) {
      this.hasFullFiveStars = true;
    }
  }

  publish(currentId: string): void {
    this.hasFullFiveStarsFeedback();
    this.saveFeedback(currentId);
    this.setEndStyle();
  }

  saveFeedback(elementToHide: string): void {
    this.feedback.discoverMsg = this.discoverMessage;
    this.feedbackService.create(this.feedback).subscribe({
        next: () => {
          this.hasSavingPhasePassed = true;
          document.getElementById(elementToHide).hidden = true;
        }, error: () => {
          this.hasSavingPhasePassed = true;
          this.error = true;
          document.getElementById(elementToHide).hidden = true;
          document.getElementById('error').hidden = false;
        }
      }
    );
  }

  setEndStyle(): void {
    document.getElementById('page').classList.add('pageFinal');
    document.getElementById('card').classList.add('cardFinal');
  }

  showNext(currentId: string, nextId: string): void{
    document.getElementById(currentId).hidden = true;
    document.getElementById(nextId).hidden = false;
  }

  showPrevious(currentId: string, previousId: string): void{
    document.getElementById(currentId).hidden = true;
    document.getElementById(previousId).hidden = false;
  }

  changeSelectedOption(event: any): void {
    const selectedOption = event.target.value;
    this.discoverMessage = null;
    if (selectedOption === '6') {
      this.showOtherInput = true;
    } else {
      this.showOtherInput = false;
    }
    switch (selectedOption) {
      case '1':
        this.discoverMessage = this.translateService.instant('feedback.google_pub');
        break;
      case '2':
        this.discoverMessage = this.translateService.instant('feedback.instagram_pub');
        break;
      case '3':
        this.discoverMessage = this.translateService.instant('feedback.facebook_pub');
        break;
      case '4':
        this.discoverMessage = this.translateService.instant('feedback.friends_pub');
        break;
      case '5':
        this.discoverMessage = this.translateService.instant('feedback.seo');
        break;
    }
  }
}
