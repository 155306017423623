import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripePromise: Promise<Stripe>;
  private elementsPromise: Promise<StripeElements>;

  constructor() {
    this.stripePromise = loadStripe(environment.stripeKey);
    this.elementsPromise = this.stripePromise.then((stripe) =>
      stripe.elements()
    );
  }

  getStripe(): Promise<Stripe> {
    return this.stripePromise;
  }

  getElements(): Promise<StripeElements> {
    return this.elementsPromise;
  }
}
