import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NewReservation } from '../models/new-reservation';
import { Reservation } from '../models/reservation';
import { Card } from '../paiement/card.interface';
import { CardUpdateService } from './card-update.service';
import {CartResponse} from "../models/cart-response";
/* eslint-disable */
@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private cards: Card[] = [];
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private bookingUrl = environment.bookingApiUrl + '/v1'
  private bookingUrlcard = environment.bookingApiUrl + '/v2'
  private organiserUrl = environment.organiserApiUrl + '/v1'

  constructor(
    private http: HttpClient,
    private cardUpdateService: CardUpdateService) {
    this.cardUpdateService.cardUpdated$.subscribe(() => {
      this.refreshCards();
    });
  }

  createCart(data) : Observable<CartResponse>{
    return this.http.post<CartResponse>(`${environment.bookingApiUrl}/v1/carts`, JSON.stringify(data) ,{ headers: this.headers});
  }
  updateCart(data, cartId: string, itemId: string) : Observable<CartResponse>{
    return this.http.patch<CartResponse>(`${environment.bookingApiUrl}/v1/carts/${cartId}/items/${itemId}`, JSON.stringify(data) ,{ headers: this.headers});
  }
  checkoutCart(data, cartId: string) : Observable<CartResponse>{
    return this.http.patch<CartResponse>(`${environment.bookingApiUrl}/v1/carts/${cartId}/checkout`, JSON.stringify(data), { headers: this.headers})
  }
  getCart(cartId : string) : Observable<CartResponse>{
    return this.http.get<CartResponse>(`${environment.bookingApiUrl}/v1/carts/${cartId}`, { headers: this.headers})
  }


  checkout(reservation: NewReservation): Observable<Reservation> {
    console.log(reservation)
    return this.http.post(`${environment.bookingApiUrl}/v2/reservations`, JSON.stringify(reservation), { headers: this.headers })
      .pipe(
        tap((res: Reservation) => console.log(`added checkout w/ id=${res.id}`))
      );
  }

  addCustomerSource(source: string): Observable<any> {
    return this.http.post(this.bookingUrl + '/customer/sources', JSON.stringify({ source: source }), { headers: this.headers })
      .pipe(
        tap((res: any) => console.log(`added customer w/ id=${res}`))
      );
  }
  refreshCards(): void {
    this.getCustomerCards().subscribe(newCards => {
      this.cards = newCards;
    });
  }
  getCustomerCards(): Observable<any> {
    return this.http.get(this.bookingUrlcard+ '/customer/sources',{ headers: this.headers })
      .pipe(
        tap((res: any) => console.log(`Fetched customer cards`))
      );
  }
  getCards(): Card[] {
    return this.cards;
  }
  deleteCard(id: string): Observable<any> {
    return this.http.delete(this.bookingUrlcard + `/customer/sources/${id}`, { headers: this.headers })
      .pipe(
        tap((res: any) => console.log(`deleted card w/ id=${id}`))
      );
  }

  getBookingShareLink(resaId): Observable<any> {
    console.log(this.organiserUrl + `/events/${resaId}/share`)
    return this.http.get(this.organiserUrl + `/events/${resaId}/share`);
  }

  addParticipant(token: string, referal?: string): Observable<any> {
    let httpParams = ''
    if (referal) {
      httpParams = `?referralUserCode=${referal}`
    }

    return this.http
      .patch(this.organiserUrl + `/invites/events/${token}${httpParams}`, {});
  }

  validateCart(cartId: string) {
    return this.http.post(this.bookingUrl + `/carts/validate/${cartId}`, null)
  }
}
