import { LocationObject } from './location';

export class MapElement {

  public static fromJson(json): MapElement {

    return new MapElement(
      json.id,
      json.name,
      json.shortDescription,
      json.longDescription,
      json.headerPhoto,
      json.rating,
      json.ratingCount,
      json.location ? new LocationObject(json.location.lat, json.location.lon) : LocationObject.empty(),

    );
  }

  public static fromJsonMaps(json): MapElement[] {
    return json.map(element => MapElement.fromJson(element))
  }

  constructor(
    public id: string,
    public name: string,
    public shortDescription: string,
    public longDescription: string,
    public headerPhoto: string[],
    public rating: number,
    public ratingCount: number,
    public location: LocationObject,
  ) { }

}
