import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

interface CheckVoucherParams {
  type: string;
  code1: string;
  email?: string;
  code2?: string;
  email2?: string;
  code3?: string;
  email3?: string;
  code4?: string;
  email4?: string;
  startDate?: string;
  duration?: string;
  price?: number;
  centerId?: string;
  activity?: string;
}

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  private readonly uri = `${environment.bookingApiUrl}/v2`;

  constructor(private http: HttpClient) { }

  checkVoucher(params: CheckVoucherParams): Observable<any> {
    const httpParams = Object.entries(params).reduce(
      (acc, [key, value]) => (value ? acc.append(key, value) : acc),
      new HttpParams()
    );
    return this.http.get(`${this.uri}/vouchers`, { params: httpParams });
  }

  applyVoucher(promoType: string, code1: string, email?: string): Observable<any> {
    const params = new HttpParams()
      .set('type', promoType)
      .set('code1', code1)
      .set('email', email || '');

    return this.http.post(`${this.uri}/vouchers`, {}, { params });
  }
}
