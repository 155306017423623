<!-- LOADER -->
<ng-container *ngIf="isMobileResolution">
    <app-loader *ngIf="!mapReady"></app-loader>
</ng-container>

<div class="map-container">
    <button class="search" (click)="onSearch()">Rechercher ici</button>
    <div class="map-border" #mapBorder>
        <div id="map"></div>
    </div>
    <ng-container *ngIf="displayMenu">
        <app-menu-bottom
            [sport]="sport" 
            [city]="city"
            (isFilter)="isFilter($event)">
        </app-menu-bottom>
    </ng-container>
</div>

<ng-template *ngIf="displayMenu" #filterContent let-modal>
    <div class="modal-header">
      <div class="filtre">{{ 'filters.filters' | translate}}</div>
      <button type="button"style="
      background: white;
      border: aliceblue;
  " aria-label="Close" (click)="modal.dismiss('Cross click')"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"/></svg></button>
    </div>
    <div class="modal-body">
        <app-filter (submitFilter)="submitFilter($event)" (closeFilterModal)="modal.dismiss('Cross click')" [sport]="sport" [radius]="radius" [price]="price" [filtercharchteristic]="filtercharchteristic" [servicetype]="service"></app-filter>
    </div>
</ng-template>