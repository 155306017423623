import { AfterViewInit, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Icon, Map, Marker } from 'leaflet';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MapService } from '../../../map.service';
import { Center } from '../../models/center';
import { SharedService } from '../../service/shared.service';
import { isPlatformBrowser } from '@angular/common';
import { BreakpointService } from '../../../shared/services/breakpoint.service';


@Component({
  selector: 'app-localisation',
  templateUrl: './localisation.component.html',
  styleUrls: ['./localisation.component.scss']
})
export class LocalisationComponent implements AfterViewInit {

  @Input() isMobileResolution = false;
  @Input() center: Center;
  @Input() googleMapLink: string;
  @Input() hideTitle = false;

  public mapReady = false;

  private map: Map;
  public layer = {
    default: {
      url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    }
  };

  constructor(
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private mapService: MapService,
    @Inject(PLATFORM_ID) private platformId,
    private breakpointService: BreakpointService,

  ) {
    this.breakpointService.isMobile().subscribe(isMobile => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.initMap();
    }
  }

  private initMap(): void {
    const { lat, lon } = this.center.location;
    this.map = this.mapService.L.map('map', {
      center: [lat, lon],
      zoom: 14
    });

    this.mapService.L.tileLayer(this.layer.default.url, {
      attribution: this.layer.default.attribution
    }).addTo(this.map);

    const iconMarker = 'assets/images/Ping.png';

    const icon: Icon = this.mapService.L.icon({
      iconUrl: iconMarker,
      iconSize: [32, 32],
      iconAnchor: [36 / 2, 36],
      popupAnchor: [0, -32],
      tooltipAnchor: [0, -32]
    });

    const marker: Marker = this.mapService.L.marker([lat, lon], { icon });
    marker.addTo(this.map);
    setTimeout(() => {
      this.map.invalidateSize();
    }, 400);
  }

}
