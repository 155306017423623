import { isPlatformBrowser } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Center } from '../models/center';
import { CentersService } from '../service/center.service';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { BreakpointService } from '../../shared/services/breakpoint.service';

@Component({
  selector: 'app-center-list',
  templateUrl: './center-list.component.html',
  styleUrls: ['./center-list.component.scss']
})

export class CenterListComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  public sport: string;
  public isLoaded = false;
  public centers: Center[];
  public addressName: '';
  public states: string[] = [];
  public centerByState = {};
  public goDownloadInfo = false;
  public isMobileResolution: boolean;
  public isScroll = false;
  public country = '';
  public logoYellow: boolean;

  private listenerFn: () => void;
  private isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private centerService: CentersService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private userPreferenceService: UserPreferenceService,
    private deviceService: DeviceDetectorService,
    private cdr: ChangeDetectorRef,
    private breakpointService: BreakpointService,

  ) {
    this.breakpointService.isMobile().subscribe(isMobile => {
      this.isMobileResolution = isMobile;
    });
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
        this.logoYellow = !this.isMobileResolution;
      }
    );
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.getSport();
    this.getCenters();
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      if (window.innerWidth <= 768 || this.deviceService.isMobile() || this.deviceService.isTablet()) {
        this.isMobileResolution = true;
      }
      else {
        this.isMobileResolution = false;
      }
      this.logoYellow = !this.isMobileResolution;
      this.listenerFn = this.renderer.listen('body', 'scroll', (event) => {
        this.onScroll(event, this.isMobileResolution);
      });
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.listenerFn) {
      this.listenerFn();
    }
  }

  private getSport(): void {
    const url: string[] = this.activatedRoute.snapshot.routeConfig.path.split('-');
    this.sport = url[1];
  }

  private getCenters(): void {
    this.centerService.getCenterBySport(this.sport)
      .subscribe(centers => {
        this.isLoaded = true;
        this.centers = centers.sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0));
        this.centerByState = this.initCentersStates(this.centers);
        this.states = Object.keys(this.centerByState).sort();
        this.isLoaded = true;
      });
  }

  private initCentersStates(centers: Center[]): { [key: string]: Center[] } {
    const centerByState = centers.reduce((acc, center) => {
      const { state } = center.address;
      if (state in acc) {
        return {
          ...acc,
          [state]: [...acc[state], center]
        };
      }
      return {
        ...acc,
        [state]: [center]
      };
    }, {});
    return centerByState;
  }

  public onScroll(event, mobileResolution): void {
    if (!mobileResolution) {
      if (event.srcElement.scrollTop > 200) {
        this.isScroll = true;
      }
      else if (event.srcElement.scrollTop <= 200) {
        this.isScroll = false;
      }
    }
  }

  public goDownload(): void {
    this.goDownloadInfo = true;
  }

  public closeDownload(event: boolean): void {
    this.goDownloadInfo = event;
  }

  public getPath(center: Center): string {
    const countryPrefix = center.address.country.toLowerCase() === 'fr' ? '' : center.address.country.toLowerCase() + '/';
    return countryPrefix + 'club-' + center.id;
  }

}
