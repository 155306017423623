import {MatchData} from "./matchData";
import {Paging} from "./paging";
export class PaginatedMatch {

  public static fromJson(json): PaginatedMatch {
    return new PaginatedMatch(
      json.data,
      json.paging,
    );
  }

  constructor(
    public data: MatchData[],
    public paging: Paging,
  ) {
  }
}
