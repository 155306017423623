import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private previousUrl = '';
  private currentUrl = '';

  private currentUrlSubject = new BehaviorSubject(this.currentUrl);
  public currentUrlObservable = this.currentUrlSubject.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe(value => {
      if(value instanceof NavigationEnd){
        this.previousUrl = this.currentUrl;
        this.currentUrl = this.router.url.toString();
        this.currentUrlSubject.next(this.currentUrl);
      }
    });
    this.previousUrl = this.currentUrl;
    this.currentUrl = this.router.url.toString();
    this.currentUrlSubject.next(this.currentUrl);
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }

  getCurrentUrl(): string {
    return this.currentUrl;
  }

  getUrl(): string {
    return this.router.url.toString();
  }

  previousPage(): void {
    const url = this.previousUrl === '' ? '/' : this.previousUrl;
    this.router.navigateByUrl(url);
  }
}
