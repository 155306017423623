import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Center } from '../../models/center';
import { Point } from '../../models/point';
import { CentersService } from '../../service/center.service';
import { SharedService } from '../../service/shared.service';
import { UserPreferenceService } from '../../service/user-preferences.service';
import { BreakpointService } from '../../../shared/services/breakpoint.service';
/* eslint-disable */
@Component({
  selector: 'app-center-recommendations',
  templateUrl: './center-recommendations.component.html',
  styleUrls: ['./center-recommendations.component.scss']
})
export class CenterRecommendationsComponent implements OnInit {
  @Input() isMobileResolution: boolean;
  @Input() center: Center;

  centerRecommandations: any = [];
  selectedDate: any;
  timeFormatted: any;
  height: number;
  selectedSport: string;
  recommendedCenters: Center[];
  country: string = "";

  constructor(
    private centerService: CentersService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private breakpointService: BreakpointService,

  ) {
    this.breakpointService.isMobile().subscribe(isMobile => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
      });
  }

  ngOnInit(): void {
    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }
    this.getCenters();
  }

  getCenters(): void {
    const lat = this.center.location.lat;
    const lon = this.center.location.lon;
    let pt = new Point(+lat, +lon)
    this.centerService.getCenters(pt, this.selectedDate).subscribe(centers => {
      this.recommendedCenters = centers['centers'];
      this.onDisplayMoreRecommendations();
    });
  }


  randomCenter(items): any {
    const i = Math.floor(Math.random() * items.length);
    const center = (items.splice(i, 1));
    return center[0];
  }

  // peut etre limiter le nombre de clubs ajoutés ?
  onDisplayMoreRecommendations(): void {
    this.centerRecommandations.push(this.randomCenter(this.recommendedCenters));
    this.centerRecommandations.push(this.randomCenter(this.recommendedCenters));
    this.centerRecommandations.push(this.randomCenter(this.recommendedCenters));
    if (this.recommendedCenters.length >= 4) {
      this.centerRecommandations.push(this.randomCenter(this.recommendedCenters));
    }
    // this.centerRecommandations.push(this.randomCenter(this.recommendedCenters));
    this.height = 370 * (this.centerRecommandations.length / 4);
  }
}
