import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SeoService } from '../service/seo.service';
import { WordPressService } from '../service/wordpress.service';
import { Blog } from "../models/blog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { UserPreferenceService } from "../service/user-preferences.service"
import { BreakpointService } from '../../shared/services/breakpoint.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  styles: ['.wp-block-heading {font-size: 1.5rem !important; color: #1da1f2}']
})
export class BlogComponent implements OnInit {
  isMobileResolution = false;
  title: string;
  rawContent: SafeHtml;
  relatedBlogs: Blog[] = [];
  loading = true;
  error = null;
  picture: string;
  country = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private wordpressService: WordPressService,
    private seo: SeoService,
    private sanitizer: DomSanitizer,
    private userPreferenceService: UserPreferenceService,
    private breakpointService: BreakpointService,

  ) {
    this.router.events.subscribe((data) => {
      if (this.route.url && this.route.snapshot.params.slug){
        this.eventLoadPage(this.route.snapshot.params.slug);
        document.body.scrollTop = 0;
      }
    });
    this.breakpointService.isMobile().subscribe(isMobile => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.country = this.userPreferenceService.getCountry();
  }

  ngOnInit(): void {
    this.title = 'Blog';
    this.rawContent = '';
    this.country = this.userPreferenceService.getCountry();
    if (this.route.url && this.route.snapshot.params.slug) {
      this.eventLoadPage(this.route.snapshot.params.slug);
    }
  }

  eventLoadPage(slug : any): void {
    this.wordpressService.loadPost(slug).subscribe({
      next: (post) => {
        if (post) {
          this.title = post.title;
          this.rawContent = this.sanitizer.bypassSecurityTrustHtml(post.content);
          this.seo.generateTags({
            title: post.meta.title ? post.meta.title : post.title,
            description: post.meta.description ? post.meta.description : post.content
          });
          this.relatedBlogs = post.related;
          this.picture = post.image;
          this.loading = false;
        } else {
          this.router.navigate(['/blog']);
        }
      },
      error: (error) => {
        this.loading = false;
        this.error = error.message;
        console.error(error);
      }
    })
  }
}
