<ngx-json-ld [json]="jsonld"></ngx-json-ld>
<div class="spinner-border spinner" role="status" *ngIf="loading">
  <span class="sr-only"></span>
</div>

<div *ngIf="dataAvailable">
  <div
    class="d-flex flex-column"
    id="top"
    *ngIf="!displayReviewsPage"
    [ngClass]="{
      'center-div-mobile': isMobileResolution,
      'center-div-desktop mx-auto': !isMobileResolution
    }"
  >
    <!-- top  -->
    <nav class="navbar navbar-light justify-content-between mt-1">
      <p
        class="center-name-text align-self-center mb-0 mx-2"
        *ngIf="isMobileResolution"
      >
        {{ center.name }}
      </p>

      <button class="custom-link-black custom-img mx-2" (click)="openShareModal()">
        <img
          class="icon-nav share-icon me-2"
          ngSrc="v1677166288/assets/icon/share-fill.png"
          alt="{{ 'center.share' | translate }}"
          width="36"
          height="36"
        />
        <span *ngIf="!isMobileResolution">
          {{ 'center.share' | translate }}
        </span>
      </button>
    </nav>

    <div
      class="d-flex flex-column top-div header-club"
      [style]="headerBackground"
      [ngClass]="{ 'header-club-desktop': !isMobileResolution }"
    ></div>

    <!-- main  -->
    <div class="d-flex flex-column" *ngIf="dataAvailable">
      <div
        class="custom-card"
        [ngClass]="{ 'card-desktop': !isMobileResolution }"
      >
        <div class="d-flex flex-column card-body mt-4 mb-4">
          <div
            [ngClass]="{
              'ms-4 me-4': !isMobileResolution,
              'mx-4': isMobileResolution
            }"
          >
            <div class="no-padding-div">
              <div class="title-container">
                <h4 class="card-title mb-1">{{ center.name }}</h4>
              </div>
              <a
                class="card-subtitle grey-text mb-2"
                [href]="googleMapLink"
                rel="noreferrer"
                target="_blank"
                >{{ center.address.line1 | titlecase }}
                {{ center.address.postalCode }}
                {{ center.address.city | titlecase }}
              </a>
            </div>
            <div class="col-2 no-padding-div fav-div" *ngIf="false">
              <button class="custom-img custom-link-black">
                <img
                  class="fav-img me-2"
                  alt="{{ 'center.fav' | translate }}"
                  ngSrc="v1677166288/assets/icon/heart-fill.png"
                  *ngIf="fav; else notFav"
                  width="36"
                  height="36"
                />
                <ng-template #notFav>
                  <img
                    class="fav-img me-2"
                    alt="{{ 'center.not_fav' | translate }}"
                    ngSrc="v1677166288/assets/icon/heart-line.png"
                    width="36"
                    height="36"
                  />
                </ng-template>
                <span *ngIf="!isMobileResolution">
                  {{ 'center.fav' | translate }}
                </span>
              </button>
            </div>
          </div>
          <ul
            ngbNav
            #nav="ngbNav"
            class="nav-tabs"
            [(activeId)]="activeNav"
            [ngClass]="{
              'mt-5': !isMobileResolution,
              'mt-3': isMobileResolution
            }"
          >
            <!-- infos club  -->
            <li [ngbNavItem]="1" class="center-nav-item">
              <a
                [routerLink]="['/club', centerId, 'info']"
                ngbNavLink
                class="center-nav-link"
                [ngStyle]="{
                  'border-bottom':
                    activeNav === 1 ? ' 3px solid rgb(30,163,153)' : 'green'
                }"
              >
                <h2 class="second-title">
                  {{ 'center.info_club' | translate }}
                </h2>
              </a>
              <ng-template ngbNavContent>
                <div
                  class="d-flex flex-column"
                  *ngIf="dataAvailable && center && center.isPartner"
                >
                  <div
                    class="d-flex flex-column mx-4"
                    [ngClass]="{
                      'my-5': !isMobileResolution,
                      'mt-4 mb-3': isMobileResolution
                    }"
                  >
                    <h3 class="expansion-header mb-4 card-title">
                      {{ 'center.the_club' | translate }}
                    </h3>
                    <div class="text mb-3">
                      <div class="descr" [innerHtml]="center.longDescription"></div>
                      <span 
                        *ngIf="
                          !center.shortDescription ||
                          center.shortDescription === ''
                        "
                      >
                        {{ center.no_description | translate }}
                      </span>
                    </div>

                    <div
                      class="d-flex justify-content-start mb-3 underline"
                      *ngIf="isMobileResolution"
                    >
                      <button
                        class="custom-link-black custom-img underline align-self-start"
                        (click)="
                          displayLongDescription = !displayLongDescription
                        "
                      >
                        <span *ngIf="!displayLongDescription">
                          {{ 'center.see_more_info' | translate }}
                        </span>
                        <span *ngIf="displayLongDescription">
                          {{ 'center.see_more_info' | translate }}
                        </span>
                      </button>
                      <img
                        class="amenity-img custom-img align-self-center mb-0"
                        alt="{{ 'center.see_more_info' | translate }}"
                        ngSrc="v1677166288/assets/arrow/arrow-down.png"
                        *ngIf="!displayLongDescription"
                        width="36"
                        height="36"
                      />
                      <img
                        class="amenity-img custom-img align-self-center mb-0"
                        alt="{{ 'center.see_less_info' | translate }}"
                        ngSrc="v1677166288/assets/arrow/arrow-up.png"
                        *ngIf="displayLongDescription"
                        width="36"
                        height="36"
                      />
                    </div>
                    <div class="text mb-3" *ngIf="displayLongDescription">
                      <div [innerHtml]>{{ center.shortDescription }}</div>
                    </div>
                  </div> 
                  <h2 class="mb-2 horizontal-line"></h2>
                  <div *ngIf="!loading && dataAvailable">
                    <app-amenities
                      [amenities]="amenities"
                      [isMobileResolution]="isMobileResolution"
                    >
                    </app-amenities>
                  </div>
                  <h2 class="mb-2 horizontal-line"></h2>
                  <button  class="my-2 fab" [routerLink]="['/club', centerId, 'reservation']">
                    {{ 'center.booking' | translate }}
                  </button>
                  <h2 class="mb-2 horizontal-line"></h2>

                  <div *ngIf="!loading && dataAvailable">
                    <app-localisation
                      [center]="center"
                      [markers]="markers"
                      [centerCoord]="centerCoord"
                      [googleMapLink]="googleMapLink"
                      [isMobileResolution]="isMobileResolution"
                    ></app-localisation>
                  </div>
                </div>
              </ng-template>
            </li>

            <!-- réservation  -->
            <li
              [ngbNavItem]="2"
              [disabled]="!center.isPartner"
              class="center-nav-item"
            >
              <a
                ngbNavLink
                [routerLink]="['/' + country, 'club', centerId, translatedPathBooking]"
                class="center-nav-link"
                [ngStyle]="{
                  'border-bottom':
                    activeNav === 2 ? ' 3px solid rgb(30,163,153)' : 'green'
                }"
              >
                <h2 class="second-title">{{ 'center.booking' | translate }}</h2>
              </a>
              <ng-template ngbNavContent>
                <div>
                  <h1 class="first-title mt-2 mb-4">{{ metaTitle }}</h1>
                </div>
                <app-select-activity-form
                  class="select-activity-form"
                  [activities]="center.activities"
                  [slotDate]="dateSlots"
                  [sportAvaibility]="selectedSport"
                  [centerId]="centerId"
                  [center]="center"
                  (newSportSelectedEvent)="newSportSelected($event)"
                  [isWhiteLabel]="isWhiteLabel"
                >
                </app-select-activity-form>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-share" *ngIf="isShareOpen">
    <div
      class="modal-share__bg"
      (click)="closeShareModal()"
      *ngIf="isShareOpen"
    ></div>

    <div class="modal-share__container">
      <div class="modal-share__close modal-close" (click)="closeShareModal()">
        <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
          <g fill="#979BAA" fill-rule="evenodd">
            <path
              d="M-.3137085 2.51471863L2.51471863-.3137085 22.3137085 19.48528137l-2.82842713 2.82842713z"
            />
            <path
              d="M19.48528137-.3137085l2.82842713 2.82842713L2.51471863 22.3137085-.3137085 19.48528137z"
            />
          </g>
        </svg>
      </div>

      <div class="socials-wrapper">
        <h3>{{ 'detail.share' | translate }}</h3>

        <div class="social-btns">
          <div class="social-btn fb" (click)="fbShare()">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.3246158 24C.5928363 24 0 23.406796 0 22.675301V1.324605C0 .592925.5929295 0 1.3246158 0H22.675477C23.406977 0 24 .592925 24 1.324605v21.350696C24 23.406889 23.406884 24 22.675477 24h-6.115824v-9.294123h3.119608l.467117-3.622084h-3.586725V8.771321c0-1.048677.291203-1.763316 1.794992-1.763316l1.918011-.000838V3.767545c-.331713-.044141-1.470264-.14276-2.79488-.14276-2.765359 0-4.658598 1.687978-4.658598 4.787819v2.671189H9.691561v3.622084h3.127617V24H1.3246158z"
                fill="#FFF"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <div class="social-btn twitter" (click)="twitterShare()">
            <svg width="24" height="20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.172742 3.162152c1.01708-.624052 1.797499-1.613579 2.16517-2.792343-.952281.577826-2.004579.998194-3.12872 1.223546C19.313259.612495 18.032752 0 16.615601 0c-2.717379 0-4.921993 2.260744-4.921993 5.048754 0 .395811.042261.780065.126783 1.149874C7.728121 5.987721 4.100722 3.979776 1.670717.921632c-.424019.748285-.666315 1.616468-.666315 2.54099 0 1.750812.869167 3.296497 2.190527 4.202239-.805776-.024558-1.566473-.254243-2.231379-.62983v.062116c0 2.447093 1.697482 4.488263 3.9514 4.950524-.412749.118454-.848037.177681-1.297412.177681-.316957 0-.626871-.030336-.926923-.089563.62687 2.005056 2.444092 3.465511 4.599401 3.504514-1.684804 1.355002-3.809121 2.162514-6.115161 2.162514-.397253 0-.79028-.023113-1.174855-.069339C2.179257 19.163597 4.768445 20 7.547808 20c9.057932 0 14.009508-7.692308 14.009508-14.364753 0-.219573-.004226-.439147-.012678-.654387.962141-.712171 1.7975-1.600578 2.455362-2.613218-.881845.401589-1.831308.673167-2.827258.79451z"
                fill="#FFF"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <div class="social-btn messenger" (click)="messengerShare()">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 0C5.372571 0 0 4.974593 0 11.111111c0 3.496667 1.744994 6.61563 4.47205 8.65237V24l4.085888-2.242444c1.090435.301777 2.24564.464666 3.442062.464666 6.627429 0 12-4.974592 12-11.111111C24 4.974593 18.627429 0 12 0zm1.192547 14.962963l-3.055901-3.259259-5.962733 3.259259L10.732919 8l3.130435 3.259259L19.751553 8l-6.559006 6.962963z"
                fill="#FFF"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <div class="social-btn email">
            <a href="mailto:?subject='Découvre Anybuddy !'">
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
