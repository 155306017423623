import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { BreakpointService } from '../../shared/services/breakpoint.service';
import { ActivityService } from '../service/activity.service';
import { CommunityService } from '../service/community.service';
import { SearchService } from '../service/search.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {
  communities = [];
  isMobileResolution = false;
  isPopupMobile = false;
  isScroll = true;
  country = 'fr';
  isBrowser: boolean;
  city = 'paris-75000';
  language = '';
  isSearching = false;
  success = false;
  selectedSport = '';
  location: string;
  activities: any[] = [];
  addressSearch: string;

  @ViewChild('errorLocate') errorLocate: TemplateRef<any>;

  private subs: Subscription[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private communityService: CommunityService,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    private breakpointService: BreakpointService,
    private searchService: SearchService,
    private activityService: ActivityService
  ) {
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
      this.isPopupMobile = isMobileResolution;
    });
  }

  ngOnInit(): void {
    this.locate();
    this.activityService.getActivitiesByCountry(this.country).subscribe({
      next: (res) => {
        console.log('res', res);
        this.activities = res;
      },
      error: (err) => {
        console.log('err', err);
      }
    });
    this.country = this.userPreferenceService.getCountry();

    const sub: Subscription = this.userPreferenceService
      .getlocalChangeCountry()
      .subscribe((value) => {
        this.country = value;
      });
    this.subs.push(sub);
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.scroll, true);
    }
    this.searchCommunity();
  }

  searchCommunity(): void {
    this.isSearching = true;
    this.communities = [];
    this.communityService
      .searchCommunities(this.location, this.city, null, this.selectedSport)
      .subscribe({
        next: (res) => {
          this.communities = res.data;
          this.isSearching = false;
          this.success = true;
        },
        error: (err) => {
          console.log('err', err);
          this.isSearching = false;
          this.success = false;
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      window.addEventListener('scroll', this.scroll, true);
      if (
        window.innerWidth <= 768 ||
        this.deviceService.isMobile() ||
        this.deviceService.isTablet()
      ) {
        this.isMobileResolution = true;
      } else {
        this.isMobileResolution = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  switchAddressByCountry(): string {
    let address: string;
    switch (this.country) {
      case 'es':
        address = 'huelva-21001';
        break;
      case 'fr':
        address = 'paris-75000';
        break;
      case 'be':
        address = 'bruxelles-1000';
        break;
      case 'ch':
        address = 'geneve-1200';
        break;
      default:
        address = 'paris-75000';
    }
    return address;
  }

  scroll = (event: any): void => {
    if (
      event.srcElement.scrollTop >= 700 &&
      this.isMobileResolution === false
    ) {
      this.isScroll = false;
    } else if (
      event.srcElement.scrollTop <= 625 &&
      this.isMobileResolution === false
    ) {
      this.isScroll = true;
    }
  };

  public locate(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const post =
              position.coords.latitude + ',' + position.coords.longitude;
            this.searchService.getGeoname(post).subscribe((res) => {
              this.addressSearch = res['slug'];
            });
            this.city = null;
            this.location = post;
          },
          (error) => {
            this.city = this.switchAddressByCountry();
            this.location = null;
          }
        );
      } else {
        this.city = this.switchAddressByCountry();
        this.location = null;
      }
    }
  }

  newSportSelected(sport: string): void {
    console.log('newSportSelected#4', sport);
    this.selectedSport = sport;
    this.searchCommunity();
  }

  goTolink(networkLink: string): void {
    if (networkLink) {
      window.open(networkLink, '_blank');
    }
  }
}
