import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Availabilities } from '../models/availability';
import { MapElement } from '../models/map-element';

@Injectable({
  providedIn: 'root'
})
export class AvailabilitiesService {
  private availabilitiesUrl = environment.bookingApiUrl + '/v1/availabilities';
  private centerAvailabilitiesUrl: string =
    environment.bookingApiUrl + '/v2/availabilities';
  private centerMapUrl: string =
    environment.bookingApiUrl + '/v2/availabilities/map-centers';

  public dataCenter$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient) {}

  public getCentersAvailabilities(
    city,
    sport,
    serviceType,
    date,
    service,
    charachterics,
    price,
    radius,
    isPartner = true,
    isSession?
  ): Observable<Availabilities> {
    let activity = '';
    const today = new Date().toISOString().slice(0, 10);
    const heureActuel = new Date().toLocaleTimeString('en-US', {
      hour12: false,
      hour: 'numeric',
      minute: 'numeric'
    });
    if (sport !== 'Sport' && sport !== '') {
      activity = '&activities=' + sport;
    }
    let datefrom;
    let dateTo;
    let serviceInfo = '';
    let priceInfo = '';
    let charachtericsFilter = '';
    let radiusFilter = '';
    const onlyClubPartner = `&isPartner=${isPartner}`;
    if (date !== '' && date !== undefined) {
      if (date.length === 16) {
        if (date.slice(10, 11) === 'T') {
          datefrom = '&date.from=' + date;
          dateTo = '&date.to=' + date.slice(0, 10) + 'T23:59';
        } else {
          datefrom = '&date.from=' + today + 'T' + heureActuel;
          dateTo = '&date.to=' + today + 'T23:59';
        }
        if (date.slice(11, 13) === '24') {
          datefrom = '&date.from=' + date.slice(0, 10) + 'T00:00';
          dateTo = '&date.to=' + date.slice(0, 10) + 'T23:59';
        }
      } else {
        if (date.length >= 11 && date.length < 16) {
          datefrom = '&date.from=' + date.slice(0, 10) + 'T' + heureActuel;
          dateTo = '&date.to=' + date.slice(0, 10) + 'T23:59';
        } else if (date.length < 10) {
          datefrom = '&date.from=' + today + 'T00:00';
          dateTo = '&date.to=' + today + 'T23:59';
        } else if (date.length === 10) {
          datefrom = '&date.from=' + date + 'T' + heureActuel;
          dateTo = '&date.to=' + date + 'T23:59';
        }
      }
    } else {
      datefrom = '&date.from=' + today + 'T00:00';
      dateTo = '&date.to=' + today + 'T23:59';
    }
    if (serviceType !== '') {
      serviceType = '&serviceTypes=' + serviceType;
    }
    if (service !== '') {
      serviceInfo = '&serviceTypes=' + service;
    }
    if (price !== '') {
      priceInfo = '&price.min=0&price.max=' + price;
    }
    if (charachterics !== '') {
      charachtericsFilter = '&characteristics=' + charachterics;
    }
    if (radius !== '') {
      radiusFilter = '&radius=' + radius * 1000;
    }

    if (isSession) {
      // today date
      let day = new Date();
      // date in 7 days
      const maxDay = new Date();
      if (date !== '' && date !== undefined) {
        day = new Date(date);
        maxDay.setDate(day.getDate() + 7);
        dateTo = '&date.to=' + maxDay.toISOString().slice(0, 10) + 'T23:59';
      } else {
        maxDay.setDate(day.getDate() + 7);
        dateTo = '&date.to=' + maxDay.toISOString().slice(0, 10) + 'T23:59';
      }
    }
    return this.http
      .get<Availabilities>(
        this.centerAvailabilitiesUrl +
          '?city=' +
          city +
          activity +
          serviceType +
          datefrom +
          dateTo +
          serviceInfo +
          priceInfo +
          charachtericsFilter +
          radiusFilter +
          onlyClubPartner +
          '&limit=30'
      )
      .pipe(map((availabilities) => Availabilities.fromJson(availabilities)));
  }

  public getCentersMap(
    city,
    sport,
    serviceType,
    date,
    service,
    charachterics,
    price,
    radius,
    isPartner = true,
    isSession?
  ): Observable<MapElement[]> {
    let activity = '';
    const today = new Date().toISOString().slice(0, 10);
    const heureActuel = new Date().toLocaleTimeString('en-US', {
      hour12: false,
      hour: 'numeric',
      minute: 'numeric'
    });
    if (sport !== 'Sport' && sport !== '') {
      activity = '&activities=' + sport;
    }
    let datefrom;
    let dateTo;
    let serviceInfo = '';
    let priceInfo = '';
    let charachtericsFilter = '';
    let radiusFilter = '';
    const onlyClubPartner = `&isPartner=${isPartner}`;
    if (date !== '' && date !== undefined) {
      if (date.length === 16) {
        if (date.slice(10, 11) === 'T') {
          datefrom = '&date.from=' + date;
          dateTo = '&date.to=' + date.slice(0, 10) + 'T23:59';
        } else {
          datefrom = '&date.from=' + today + 'T' + heureActuel;
          dateTo = '&date.to=' + today + 'T23:59';
        }
        if (date.slice(11, 13) === '24') {
          datefrom = '&date.from=' + date.slice(0, 10) + 'T00:00';
          dateTo = '&date.to=' + date.slice(0, 10) + 'T23:59';
        }
      } else {
        if (date.length >= 11 && date.length < 16) {
          datefrom = '&date.from=' + date.slice(0, 10) + 'T' + heureActuel;
          dateTo = '&date.to=' + date.slice(0, 10) + 'T23:59';
        } else if (date.length < 10) {
          datefrom = '&date.from=' + today + 'T00:00';
          dateTo = '&date.to=' + today + 'T23:59';
        } else if (date.length === 10) {
          datefrom = '&date.from=' + date + 'T' + heureActuel;
          dateTo = '&date.to=' + date + 'T23:59';
        }
      }
    } else {
      datefrom = '&date.from=' + today + 'T00:00';
      dateTo = '&date.to=' + today + 'T23:59';
    }
    if (serviceType !== '') {
      serviceType = '&serviceTypes=' + serviceType;
    }
    if (service !== '') {
      serviceInfo = '&serviceTypes=' + service;
    }
    if (price !== '') {
      priceInfo = '&price.min=0&price.max=' + price;
    }
    if (charachterics !== '') {
      charachtericsFilter = '&characteristics=' + charachterics;
    }
    if (radius !== '') {
      radiusFilter = '&radius=' + radius * 1000;
    }

    if (isSession) {
      // today date
      let day = new Date();
      // date in 7 days
      const maxDay = new Date();
      if (date !== '' && date !== undefined) {
        day = new Date(date);
        maxDay.setDate(day.getDate() + 7);
        dateTo = '&date.to=' + maxDay.toISOString().slice(0, 10) + 'T23:59';
      } else {
        maxDay.setDate(day.getDate() + 7);
        dateTo = '&date.to=' + maxDay.toISOString().slice(0, 10) + 'T23:59';
      }
    }
    return this.http
      .get<MapElement[]>(
        this.centerMapUrl +
          '?city=' +
          city +
          activity +
          serviceType +
          datefrom +
          dateTo +
          serviceInfo +
          priceInfo +
          charachtericsFilter +
          radiusFilter +
          onlyClubPartner +
          '&limit=500'
      )
      .pipe(map((element) => MapElement.fromJsonMaps(element)));
  }

  getAvaibilitiesPagination(url: string): Observable<Availabilities> {
    return this.http
      .get<Availabilities>(url)
      .pipe(map((availabilities) => Availabilities.fromJson(availabilities)));
  }

  getAvailabilities(
    centerId = '',
    // "lat,lon". Example: 33.8670,151.1957
    location = '',
    // the sort type: bestNearby = only show the best availabilities, allNearby = all results (default)
    sort = '',
    // 2016-12-21
    date = '',
    // 15:00
    time = '',
    activity = '',
    // 15:00
    timeMin = '',
    // 18:00
    timeMax = '',
    priceMin: number = null,
    priceMax: number = null
  ): Observable<Availabilities> {
    return this.http
      .get<Availabilities>(`${this.availabilitiesUrl}?centerId=${centerId}&location=${location}&sort=${sort}&date=${date}&time=${time}
    &activity=${activity}&timeMin=${timeMin}&timeMax=${timeMax}&priceMin=${priceMin}&priceMax=${priceMax}`);
  }

  getAvailabilitiesByCenterId(centerId: string): Observable<Availabilities> {
    return this.http.get<Availabilities>(
      `${this.availabilitiesUrl}?centerId=${centerId}`
    );
  }

  getAvailabilitiesByDate(
    centerId = '',
    date = '',
    activity = ''
  ): Observable<Availabilities> {
    return this.http.get<Availabilities>(
      `${this.availabilitiesUrl}?centerId=${centerId}&date=${date}&activity=${activity}`
    );
  }

  getAvailabilitiesByActivity(
    centerId = '',
    activity = ''
  ): Observable<Availabilities> {
    return this.http.get<Availabilities>(
      `${this.availabilitiesUrl}?centerId=${centerId}&activity=${activity}`
    );
  }

  getAvailabilitiesByDateAndTime(
    centerId = '',
    date = '',
    activity = '',
    time = ''
  ): Observable<Availabilities> {
    return this.http.get<Availabilities>(
      `${this.availabilitiesUrl}?centerId=${centerId}&location=${location}&date=${date}&time=${time}&activity=${activity}`
    );
  }
}
