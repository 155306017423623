import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  CacheMechanism,
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader
} from '@gilsdav/ngx-translate-router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isMarketplaceDomain } from './shared/services/app-context.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => {
      if (isMarketplaceDomain()) {
        return import('./marketplace/markeplace.module').then(
          (m) => m.MarketplaceModule
        );
      } else {
        return import('./whitelabel/whitelabel.module').then(
          (m) => m.WhitelabelModule
        );
      }
    }
  }
];
export function localizeLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings
): ManualParserLoader {
  return new ManualParserLoader(
    translate,
    location,
    {
      ...settings,
      alwaysSetPrefix: false,
      useCachedLang: false,
      cacheMechanism: CacheMechanism.Cookie
    },
    ['fr', 'es', 'be', 'ch'],
    'ROUTES.'
  );
}
@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: LocalizeParser,
        useFactory: localizeLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    }),
    RouterModule.forRoot(routes, { initialNavigation: 'disabled' }),
    LocalizeRouterModule.forRoot(routes, {
      initialNavigation: true,
      parser: {
        provide: LocalizeParser,
        useFactory: localizeLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    })
  ],
  exports: [RouterModule, LocalizeRouterModule]
})

export class AppRoutingModule {}
