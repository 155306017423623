import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  private resetUrl: string = environment.organiserApiUrl + '/v1/auth/reset_password';
  private retrieveUrl: string = environment.organiserApiUrl + '/v1/auth/retrieve_password';
  private headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  resetPassword(provider: string,email: string): Observable<any> {
    const json = {
      email: email,
      provider: provider
    }
    return this.http.post(`${this.resetUrl}`, JSON.stringify(json), { headers: this.headers, observe: 'response' });
  }

  retrievePassword(provider: string,email: string): Observable<any> {
    const json = {
      email: email,
      provider: provider
    }
    return this.http.post(`${this.retrieveUrl}`, JSON.stringify(json), { headers: this.headers, observe: 'response' });
  }
}
