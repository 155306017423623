<div class="d-flex flex-column mt-4 mx-4"
    [ngClass]="{'mt-4 mb-5': !isMobileResolution,  'mt-4 mb-3': isMobileResolution}">

    <h3 class="expansion-header card-title"
        [ngClass]="{'mt-5 mb-4': !isMobileResolution,  'mb-4': isMobileResolution}">
        {{'center_recommendations.recommendation' | translate}}
    </h3>
</div>
<div>
    <div class="container-fluid center-scroll-div mb-3" *ngIf="isMobileResolution; else desktop">
        <div class="d-flex flex-row flex-nowrap pb-2">
            <ng-container *ngFor="let center of centerRecommandations"  class="card card-container">
                <div class="col-6 d-flex flex-column ms-3 me-0 ps-2 pe-0" style="width: 260px!important;">
                    <div class="card-img-container" style="width: auto !important;">
                        <a *ngIf="center && center!==null" [routerLink]="['/' + center.getPath()]">
                            <img class="center-picture" src="{{ center.headerPhoto['280x140'] }}" alt="Card image cap" width="280" height="140">
                        </a>
                        <div class="rating-container">
                            <app-star-rating
                                [rating]="center.rating"
                                [ratingCount]="center.ratingCount"
                                [white]="true"
                                [small]="true">
                            </app-star-rating>
                        </div>
                    </div>
                    <div class="ms-2">
                        <a *ngIf="club && club!==null" [routerLink]="['/' + center.getPath()]">
                            <h4 class="card-title center-name mb-2">{{center.name}}</h4>
                        </a>
                        <a *ngIf="club && club!==null" [routerLink]="['/' + center.getPath()]">
                            <h5 class="card-subtitle grey-text mb-1">{{center.formattedAddress}}</h5>
                        </a>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-1 empty-col">
        </div>
    </div>

    <ng-template #desktop>
        <div class="center-scroll-row d-flex justify-content-between flex-wrap">
            <ng-container *ngFor="let club of centerRecommandations; let i = index" class="card card-container">
                <div class="center-scroll-col d-flex flex-column">
                    <div class="card-img-container">
                    <a [routerLink]="['/' + club.getPath()]">
                        <img class="center-picture" src="{{ club.headerPhoto['280x140'] }}" alt="Card image cap" width="280" height="140">
                    </a>
                    <div class="rating-container">
                        <app-star-rating
                            [rating]="club.rating"
                            [ratingCount]="club.ratingCount"
                            [white]="true"
                            [small]="true">
                        </app-star-rating>
                    </div>
                    </div>
                    <div class="ms-2 txt-container">
                        <a [routerLink]="['/' + club.getPath()]">
                            <h4 class="center-name mb-2 mt-2">{{club.name}}</h4>
                        </a>
                        <a [routerLink]="['/' + club.getPath()]">
                            <h5 class="card-subtitle grey-text mb-1">{{club.formattedAddress}}</h5>
                        </a>
                    </div>
                </div>
            </ng-container>
        </div>

    </ng-template>
</div>

<div class="d-flex justify-content-start mb-5 underline" *ngIf="!isMobileResolution">
    <button class="custom-link-grey custom-img underline align-self-start" (click)="onDisplayMoreRecommendations()">
        <span>
            {{'center_recommendations.nearby_clubs' | translate}}
        </span>
    </button>
    <img class="amenity-img custom-img align-self-center mb-0"
        alt="{{'center_recommendations.nearby_clubs' | translate}}"
        ngSrc="v1677166288/assets/arrow/arrow-right.png" width="96" height="96" alt="arrow right">
</div>
