import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MarketplaceRoutingModule } from './markeplace-routing.module';
import {MarketplaceComponent} from "./markeplace.component";
import {RouterOutlet} from "@angular/router";

@NgModule({
  declarations: [
    MarketplaceComponent,
  ],
    imports: [
        CommonModule,
        MarketplaceRoutingModule,
        RouterOutlet,
    ]
})

export class MarketplaceModule { }

