import { Component, OnInit } from '@angular/core';
import { ActivatedRoute , Router} from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SeoService } from '../service/seo.service';
import { WordPressService } from '../service/wordpress.service';
import { UserPreferenceService } from "../service/user-preferences.service";
import { BreakpointService } from '../../shared/services/breakpoint.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  isMobileResolution: boolean;
  title: string;
  rawContent: string;
  country = '';

  constructor(private breakpointService: BreakpointService,private router: Router,private route: ActivatedRoute, private deviceService: DeviceDetectorService, private wordpressService: WordPressService, private seo: SeoService, private userPreferenceService: UserPreferenceService) {
    this.router.events.subscribe((data) => {
      if (this.route.url && this.route.snapshot.url[0]) {
        this.eventLoadPage(this.route.snapshot.url[this.route.snapshot.url.length - 1].path);
      } 
  });
    this.breakpointService.isMobile().subscribe(isMobile => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.country = this.userPreferenceService.getCountry();
  }

  ngOnInit(): void {
    this.country = this.userPreferenceService.getCountry();
    if (this.route.url && this.route.snapshot.url[0]) {
      this.eventLoadPage(this.route.snapshot.url[this.route.snapshot.url.length - 1].path);
    } else {
      //todo: 404
    }
  }

  eventLoadPage(slug : any): void {
    this.wordpressService.loadPage(slug).subscribe(post => {
      if (post) {
        this.title = post.title;
        this.rawContent = post.content;
        this.seo.generateTags({
          title: this.title,
          description: this.title
        });
      }
    })
  }
}
