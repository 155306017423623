export class Credentials {
    public static fromJson(json): Credentials {
     const username = 'username';
     const password = 'password';
     const firstName = 'firstName';
     const lastName ='lastName';
     const dateOfBirth= 'dateOfBirth';
     const memberId = 'memberId';
     return new Credentials(

        json[username],
        json[password],
        json[firstName],
        json[lastName],
        json[dateOfBirth],
        json[memberId]
      );
    }

    constructor(
      public username: string,
      public password: string,
      public firstName?: string,
      public lastName?: string,
      public dateOfBirth?: string,
      public memberId?: string,
    ) { }
  }
