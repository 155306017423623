<!-- favorite list -->
<div class="container">
  <div class="header">
    <h1 class="title"> {{ 'my_bookings.my_account' | translate }} </h1>
  </div>
  <hr>
  <div class="title-container mt-5 mb-2">
    <h2 class="sub-title">{{ 'myProfile.profile' | translate }}</h2>
    <p class="text">{{ 'myProfile.profile_subtext' | translate }}</p>
  </div>
  <ng-container *ngIf="!dataAvailable">
    <app-loader></app-loader>
  </ng-container>
  <div *ngIf="dataAvailable">
    <div class="mb-2 mt-4" *ngIf="user.phoneNumber">
      <img class="icon" src="assets/images/icons/avatar.svg">
      {{user.firstName}}
      {{user.lastName}}
    </div>
    <div class="my-2 d-block d-md-flex" *ngIf="user.phoneNumber">
      <div class="">
        <img class="icon" src="assets/images/icons/phone.svg">
        {{user.phoneNumber}}
      </div>
      <div class="col-12 col-md mx-auto ms-md-3 me-md-0 mt-2 mt-md-0 d-flex">
        <button class="btn btn-save mx-auto mx-md-0" (click)="reAuthenticate('phone')">{{ 'my_preferences.updatePhone' | translate}}</button>
      </div>
    </div>

    <div class="my-2 d-block d-md-flex" *ngIf="user.email">
      <div class="">
        <img class="icon" src="assets/images/icons/envelope.svg">
        {{user.email}}
      </div>
      <div class="col-12 col-md mx-auto ms-md-3 me-md-0 mt-2 mt-md-0 d-flex">
        <button class="btn btn-save mx-auto mx-md-0" (click)="reAuthenticate('email')">{{ 'my_preferences.updateMail' | translate}}</button>
      </div>
    </div>

    <div class="mt-3" *ngIf="subscriptions.length>0">
      <div class="title-container">
        <h2 class="sub-title mb-3">{{ 'myProfile.subscription' | translate }}</h2>
      </div>

      <div *ngFor="let sub of subscriptions">
        <div class="card p-2">
          <div class="d-block d-md-flex">
            <div class="">
              <h3 class="card-title">{{sub.centerName}}</h3>
            </div>
            <div class="ms-0 ms-md-3">
              <h4 class="formule card-title">{{sub.name}}</h4>
            </div>
          </div>
          <p class="fst-italic">{{sub.description}}</p>
          <div *ngIf="sub.startDate && sub.endDate">
            <p> {{ 'myProfile.from' | translate }} <span class="fw-bolder">{{sub.startDate| date: 'dd/MM/yyyy'}}</span> {{ 'myProfile.to' | translate }} <span class="fw-bolder">{{sub.endDate | date: 'dd/MM/yyyy'}}</span> ( <span class="fst-italic">{{getStatus(sub)}}</span> )</p>
          </div>
          <div *ngIf="sub.memberId">
            {{ 'myProfile.memberId' | translate }} : <span class="fw-bolder">{{sub.memberId}}</span>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>


<ng-template #updateMailModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingEmailTitle' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{'Veuillez entrer votre nouvelle adresse email'}}
    </p>
    <form [formGroup]="updateMailForm" class="pb-1 card-padding d-flex">
      <input class="newMail" [formControlName]="'newMail'">
    </form>
    <p class="error text-center" *ngIf="emailInvalid">{{ 'my_preferences.invalidField' | translate}}</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-dark m-auto col-5" (click)="updateMail()">{{ 'my_preferences.update' | translate}}</button>
  </div>
</ng-template>

<ng-template #updatingEmailAwaiting>
  <div class="modal-body">
    <p class="w-100 text-center">{{ 'my_preferences.updatingEmail' | translate}}</p>
    <div class="loader m-auto"></div>
  </div>
</ng-template>

<ng-template #updatingPhoneAwaiting>
  <div class="modal-body">
    <p class="w-100 text-center">{{ 'my_preferences.updatingPhone' | translate}}</p>
    <div class="loader m-auto"></div>
  </div>
</ng-template>

<ng-template #updatingAccountResult class="my-modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingEmailTitle' | translate}}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{ errorMsg ? errorMsg : ('my_preferences.updatingEmailSuccess' | translate) }}
    </p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger m-auto col-5" (click)="closeResultModal()">{{ 'menuComponent.close' | translate}}</button>
  </div>
</ng-template>

<ng-template #errorModal class="my-modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.error_valid' | translate}}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">{{ errorFlowMsg }}</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger m-auto col-5" (click)="closeResultModal()">{{ 'menuComponent.close' | translate}}</button>
  </div>
</ng-template>

<ng-template #passwordModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.checkPassword' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">{{'my_preferences.password' | translate}}</p>
    <form [formGroup]="passwordForm" class="pb-1 card-padding d-flex">
      <input class="newMail" type="password" [formControlName]="'password'">
    </form>
    <p class="error text-center" *ngIf="passwordInvalid">{{ 'my_preferences.invalidField' | translate}}</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-dark m-auto col-5" (click)="reAuthenticateWithPassword()">{{ 'my_preferences.check' | translate}}</button>
  </div>
  <p *ngIf="errorPasswordMsg" class="error text-center">{{errorPasswordMsg}}</p>
</ng-template>


<ng-template #updatePhoneModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingPhoneTitle' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{'my_preferences.enterNewPhone' | translate}}
    </p>
    <form [formGroup]="updatePhoneForm" class="pb-1 card-padding d-flex">
      <input class="newMail" [formControlName]="'newPhone'" [placeholder]="'+33XXXXXXXXX'"  [disabled]="captcha">
    </form>
    <p class="error text-center" *ngIf="emailInvalid">{{ 'my_preferences.invalidField' | translate}}</p>

    <div *ngIf="captcha">
      <p class="text-center">{{'my_preferences.completeCaptcha' | translate}}</p>
    </div>
    <div class="d-flex">
      <div class="mx-auto" id="recaptcha-container"></div>
    </div>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-dark m-auto col-5" [disabled]="captcha" (click)="updatephone()">{{ 'my_preferences.update' | translate}}</button>
  </div>
</ng-template>

<ng-template #updatePhoneCodeModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingPhoneTitle' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="text-center">{{'my_preferences.verifCode' | translate}}</p>
    <form [formGroup]="updatePhoneValidationCodeForm" class="pb-1 card-padding d-flex">
      <input class="newMail" [formControlName]="'validationCode'">
    </form>
    <p class="error text-center" *ngIf="emailInvalid">{{ 'my_preferences.invalidField' | translate}}</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-dark m-auto col-5" (click)="valideCode()">{{ 'my_preferences.update' | translate}}</button>
  </div>
</ng-template>

<ng-template #updatingAccountResultPhone class="my-modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'my_preferences.updatingPhoneTitle' | translate}}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{ errorMsg ? errorMsg : ('my_preferences.updatingPhoneSuccess' | translate) }}
    </p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger m-auto col-5" (click)="closeResultModal()">{{ 'menuComponent.close' | translate}}</button>
  </div>
</ng-template>
