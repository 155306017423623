import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TopNavBarComponent} from "../top-navbar/top-navbar.component";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SharedService} from "../service/shared.service";
import {BreakpointService} from "../../shared/services/breakpoint.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {UserPreferenceService} from "../service/user-preferences.service";
import {TagManagerService} from "../service/tag-manager.service";

@Component({
  selector: 'app-community-page',
  templateUrl: './community-page.component.html',
  styleUrls: ['./community-page.component.scss']
})
export class CommunityPageComponent implements OnInit {

  @ViewChild(TopNavBarComponent, { static: false }) public navbar: TopNavBarComponent;
  @ViewChild('downlodApp') downlodApp: TemplateRef<any>;
  country = 'fr';
  isMobileResolution = false;
  isPopupMobile = false;
  language = '';
  constructor(
    private modalService: NgbModal,
    private sharedService: SharedService,
    private breakpointService: BreakpointService,
    private deviceService: DeviceDetectorService,
    private userPreferenceService: UserPreferenceService,
    private tagManager: TagManagerService
  ) {
    this.breakpointService.isMobile().subscribe(isMobile => {
      this.isMobileResolution = isMobile;
    });
    this.isPopupMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
    this.sharedService.changeEmitted$.subscribe(
      isMobileResolution => {
        this.isMobileResolution = isMobileResolution;
        this.isPopupMobile = isMobileResolution;
      });
  }

  ngOnInit(): void {

    this.country = this.userPreferenceService.getCountry();
    if (this.country !== 'fr') {
      this.language = this.country
    }
  }

  downloadApp(): void {
    this.navbar.sidebarShow = false;
    this.modalService.open(this.downlodApp, { centered: true, windowClass: 'customModalDownloadApp' });
  }

  downloadAppClick() {
    this.tagManager.pushDownloadApp();
  }
  public onCloseModal(modal: NgbActiveModal): void {
    modal.close();
  }

}
