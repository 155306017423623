<div class="container-main">
    <div class="row w-100 h-100 m-0 content">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-5 mx-auto max-width">
            <div class="login-card">
                <div class="title-card">
                    <h1 class="title">{{(isReset? 'white_label_reset_password.reset_title' : 'white_label_reset_password.retrieve_title' ) | translate }}</h1>
                </div>
                <ng-container *ngTemplateOutlet="loginForm"></ng-container>
            </div>
        </div>

    </div>
</div>

<ng-template #loginForm>

    <div class="login-form my-5">
        <div class="input-elements col-12 col-md-10">
            <input class="custom-input" placeholder="{{ 'white_label_reset_password.email' | translate }}" type="email"
                [formControl]="email">
            <div class="text-danger">
                <div class="d-flex">
                    <p class="mx-auto error-msg">{{getErrorMessage(this.email, 'email')}}</p>
                </div>
            </div>
        </div>
        <div class="row connexion-option">
            <div class="col-12">
                <div class="text-danger">
                    <div *ngIf="error" class="d-flex">
                        <p class="mx-auto error-msg"> {{ errorMessage }} </p>
                    </div>
                </div>
            </div>
            <div class=" col-12 col-md-8 mx-auto mt-2 mb-3">
                <button class="email-link-btn" (click)="handlePasswordAction()"
                    [ngClass]="{'btn-disabled' : isConnecting}">
                    {{ (isReset? 'white_label_reset_password.reset' : 'white_label_reset_password.retrieve' ) | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>
