<div class="main-container">
    <form action="" [formGroup]="resaForm">
        <div class="d-flex flex-column filters-div"
            [ngClass]="{'mt-2 mb-5': !isMobileResolution, 'mb-4': isMobileResolution}">
            <ngb-accordion #acc="ngbAccordion" activeIds="toggle-1, toggle-2" [destroyOnHide]="false">
                <ngb-panel id="toggle-1" *ngIf="types && types.length>1">
                    <ng-template ngbPanelHeader let-opened="opened">
                        <div class="d-flex align-items-center justify-content-between expansion-header  no-margin mb-4"
                            id="typeDiv">
                            <div class="m-0 expansion-title">{{'filters.type_of' | translate}}
                                {{ sport}}</div>
                            <button ngbPanelToggle class="p-0 btn-arrow">
                                <img class="arrow-up" ngSrc="v1677168593/assets/arrow/arrow-up.png" *ngIf="opened"
                                    alt="arrow-up" width="36" height="36">
                                <img class="arrow-down" ngSrc="v1677168593/assets/arrow/arrow-down.png" *ngIf="!opened"
                                    alt="arrow-down" width="36" height="36">
                            </button>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="sport-type-container col-3">
                            <div class="w-100" *ngFor="let key of types ;let j=index;">
                                <div class="d-flex justify-content-between mb-4" style="
                            min-width: 14vw;">
                                    <label class="checkbox-label" for="{{j}}">
                                        {{ key.name | titlecase }}
                                    </label>
                                    <input class="form-check-input" type="radio" name="resaType" id="{{j}}"
                                        [value]="key.id" formControlName="resaType"
                                        (change)="onResaTypeChange($event,key.name)">
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="toggle-2">
                    <h2 class="mb-2 horizontal-line"></h2>
                    <ng-template ngbPanelHeader let-opened="opened" *ngIf='filters!==undefined'>
                        <div class="d-flex align-items-center justify-content-between expansion-header mt-5 mb-4">
                            <div class="m-0 expansion-title">
                                {{'filters.filters' | translate}}{{ selectedType }}</div>
                            <button ngbPanelToggle class="p-0 btn-arrow">
                                <img class="arrow-up" ngSrc="v1677168593/assets/arrow/arrow-up.png" *ngIf="opened"
                                    alt="arrow-up" width="36" height="36">
                                <img class="arrow-down" ngSrc="v1677168593/assets/arrow/arrow-down.png" *ngIf="!opened"
                                    alt="arrow-down" width="36" height="36">
                            </button>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ng-container *ngFor="let filterType of filters; let j = index;">

                            <div class="expansion-content-div  mb-4" [ngClass]="{'mt-5 ': j !== 0}">
                                <div *ngIf='filterType.options.length>0'
                                    class="d-flex align-items-center justify-content-between expansion-header not-underline">
                                    <div class="m-0 expansion-title">{{ filterType.name | titlecase }}
                                    </div>
                                </div>
                                <div class="expansion-content-div mt-5" style="flex-wrap: wrap;">
                                    <div class="row">
                                        <ng-container *ngFor="let filter of filterType.options; let i = index">
                                            <div class="w-100" *ngIf=" i % 4 === 0"></div>
                                            <div class="col-3">
                                                <div class="d-flex justify-content-between mb-4 mx-2 ">
                                                    <label class="checkbox-label" for=""
                                                        *ngIf="filterType.name !== 'profs'">{{filter.name}}</label>
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="{{filter.id}}" value="{{filter.id}}"
                                                        (change)="onCheckChange($event)"
                                                        [checked]="getValue(filter.id)">
                                                    <label class="custom-control-label" for="{{filter.id}}"></label>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>


                        <div class="expansion-content-div  mt-5 mb-4"
                            *ngIf="sportSelected && (selectedSport === 'golf' || selectedSport === 'fitness')">
                            <div
                                class="d-flex align-items-center justify-content-between expansion-header not-underline">
                                <div class="m-0 expansion-title d-flex justify-content-between">
                                    {{ 'filters.party_size' | translate }}

                                    <button class="custom-img round-btn ms-5" (click)="incrementPartySize(-1)">
                                        -
                                    </button>
                                    <span class="party-size-text mx-4">
                                        {{partySize}}
                                    </span>
                                    <button class="custom-img round-btn" (click)="incrementPartySize(1)">
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
        <div>
            <h5 class="title mb-3 title-padding">{{'filters.club' | translate}}</h5>
            <div class="d-flex mb-2" style="padding: 2px 35px">
              <span class="ms-3"> {{ 'filters.see_clubs_no_reservable' | translate }} </span>
              <div>
                    <input class="form-check-input ms-1"
                        type="radio"
                        name="isPartner"
                        [checked]="!isPartner"
                        (click)="setOnlyClubReservable()">
                </div>
            </div>
        </div>
        <div class="range-section mt-5 mb-5">
            <h5 class="title mb-3 title-padding">{{'filters.distance' | translate}} (km)</h5>
            <div class="d-flex flex-column px-4 mb-2">
                <div class="d-flex justify-content-between">
                    <span>0 km</span>
                    <span>{{ distance }} km</span>
                </div>
                <div class="px-2">
                    <mv-slider [(value)]="distance" [min]="0" [max]="1000" [enabled]="true"
                        (slideStop)="changeDistance($event)"></mv-slider>
                </div>
            </div>
        </div>
        <div class="price-section mt-5 mb-5">
            <h5 class="title mb-3 title-padding">{{'filters.price' | translate}} (€)</h5>
            <div class="d-flex flex-column px-4 mb-2">
                <div class="d-flex justify-content-between">
                    <span>0 €</span>
                    <span>{{ price }} €</span>
                </div>
                <div class="px-2">
                    <mv-slider [(value)]="price" [min]="0" [max]="90" [enabled]="true"
                        (slideStop)="changePrice($event)"></mv-slider>
                </div>
            </div>
        </div>
    </form>
    <div class="p-3 d-flex justify-content-center footer" style="margin-top: 9%;">
        <button class="submit-filter" (click)="onSubmitFilter()">{{'filters.display_result' | translate}} </button>
    </div>
</div>
