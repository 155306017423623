import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Activity } from '../models/activity';

@Injectable({
  providedIn: 'root'
})

export class CommunityService {

  private communityUrl = environment.bookingApiUrl + '/v1/communities';

  constructor(private http: HttpClient) { }

  searchCommunities(location: string, city: string, radius?: number, activityId?: string, limit?: number, before?: string, after?: string): Observable<any> {

    let params = new HttpParams();
    if (location !== undefined && location !== null) {
      params = params.set('location', location);
    }
    if (city !== undefined && city !== null) {
      params = params.set('city', city);
    }
    if (radius !== undefined && radius !== null) {
      params = params.set('radius', String(radius));
    }
    if (activityId !== undefined && activityId !== null) {
      params = params.set('activity', activityId);
    }
    if (limit !== undefined && limit !== null) {
      params = params.set('limit', String(limit));
    }
    if (before !== undefined && before !== null) {
      params = params.set('before', before);
    }
    if (after !== undefined && after !== null) {
      params = params.set('after', after);
    }
    return this.http.get<Activity[]>(`${this.communityUrl}`, { params: params });
  }

}
