import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthFirebaseService } from '../service/auth-firebase.service';
import { SharedService } from '../service/shared.service';
import { UserPreferenceService } from '../service/user-preferences.service';
import { UserService } from '../service/user.service';
import { BreakpointService } from '../../shared/services/breakpoint.service';

@Component({
  selector: 'app-complete-account',
  templateUrl: './complete-account.component.html',
  styleUrls: ['./complete-account.component.scss']
})
export class CompleteAccountComponent implements OnInit {
  isMobileResolution = false;

  form = this.formBuilder.group({
    firstname: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(2)
    ]),
    name: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(2)
    ]),
    email: new UntypedFormControl(null, [Validators.required, Validators.email])
  });

  errorMessage: any;
  error = false;
  errorFirstname = false;
  errorName = false;
  errorMail = false;
  errorLink = false;
  step1 = true;
  loading = false;
  data: any;
  hasError: boolean;
  isCodeTyped: boolean;
  isValid: boolean;
  sendCode: boolean;
  country: string;
  outsideAuth = 'false';
  mode = '';
  connection = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private userService: UserService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private route: ActivatedRoute,
    private authFireBase: AuthFirebaseService,
    private breakpointService: BreakpointService
  ) {
    this.breakpointService.isMobile().subscribe((isMobile) => {
      this.isMobileResolution = isMobile;
    });
    this.isMobileResolution =
      this.deviceService.isMobile() || this.deviceService.isTablet();

    this.sharedService.changeEmitted$.subscribe((isMobileResolution) => {
      this.isMobileResolution = isMobileResolution;
    });
  }

  ngOnInit(): void {
    this.country = '';
    this.route.queryParams.subscribe((params) => {
      if (params.outsideAuth) {
        this.outsideAuth = params.outsideAuth;
      }
      if (params.mode) {
        this.mode = params.mode;
      }
    });

    if (this.userPreferenceService.getCountry() !== 'fr') {
      this.country = this.userPreferenceService.getCountry();
    }

    if (this.outsideAuth === 'true' && this.mode !== 'signIn') {
      //Mettre un contenue où supprimer si ce n'est plus nécessaire
    } else {
      this.connection = true;
    }
    if (window.localStorage.getItem('emailForSignIn')) {
      this.form.controls.email.setValue(
        window.localStorage.getItem('emailForSignIn')
      );
      if (this.outsideAuth === 'true' && this.mode !== 'signIn') {
        //Mettre un contenue où supprimer si ce n'est plus nécessaire
      } else {
        this.checkRegisteredUserData(this.form.controls.email.value);
      }
    }
  }

  completeSignupMail(): void {
    this.error = false;
    this.errorMail = false;

    if (
      this.form.controls.email.hasError('email') ||
      this.form.controls.email.hasError('required')
    ) {
      this.error = true;
      this.errorMail = true;
    }

    if (this.error) {
      return;
    }

    if (this.outsideAuth === 'true' && this.mode !== 'signIn') {
      window.localStorage.removeItem('emailForSignIn');
    } else {
      this.checkRegisteredUserData(this.form.controls.email.value);
    }
  }

  checkRegisteredUserData(email): void {
    this.afAuth
      .isSignInWithEmailLink(window.location.href)
      .then((isSignEmailLink) => {
        if (isSignEmailLink) {
          this.afAuth
            .signInWithEmailLink(email, window.location.href)
            .then((result) => {
              localStorage.removeItem('emailForSignIn');
              result.user.getIdToken().then((res) => {
                this.authFireBase.token = res;
                this.authFireBase.isLoggedIn = true;
                this.userService.getMe().subscribe({
                  next: () => {
                    this.navigateEnd();
                  },
                  error: (error) => {
                    this.error = true;
                    this.errorMessage = error.message;
                  }
                });
              });
            })
            .catch((error) => {
              this.error = true;
              this.errorMessage = error;
            });
        } else {
          this.error = true;
          this.errorMessage =
            'The link provided cannot be used for sign up with link the token is probably missing or invalid';
        }
      })
      .catch((err) => {
        this.error = true;
        this.errorMessage = err;
      });
  }

  navigateEnd(): void {
    this.route.queryParams.subscribe((params) => {
      const redirectUrl = params['redirectUrl'];
      if (redirectUrl) {
        this.router.navigateByUrl(redirectUrl);
      } else {
        this.router.navigate(['/account']);
      }
    });
  }
}
