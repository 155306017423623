
declare interface ReviewData {
    author: string;
    review: string;
    icon: string;
}


export const REVIEWS_LIST: ReviewData [] = [
    { author: 'France 2', review: 'Anybuddy a été créée pour rendre la pratique du sport plus accessible et plus instantanée .'
    , icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677165635/assets/presse/france2.png' },
    { author: 'Le Monde', review: 'Anybuddy : l’application incontournable des réservations de terrains de sport.'
    , icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677165635/assets/presse/lemonde.png' },
    { author: 'Europe 1', review: 'C’est génial comme application, j’ai dit que je voulais un terrain ce soir à 19h30 et j’en ai une dizaine de proposés autour de moi.'
    , icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677165635/assets/presse/europe1.png' },
    { author: '20 minutes', review: '7000 utilisateurs déjà séduits (...) Au vu du succès rencontré à Lille, Anybuddy vise plus haut et se lance en région parisienne'
    , icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677165635/assets/presse/20minutes.png' },
    { author: 'Le Parisien', review: 'Une inscription, un paiement sécurisé et hop : vous voilà raquette en main sur la terre battue de votre choix'
    , icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677165635/assets/presse/leparisien.png' },
    { author: 'CNews', review: 'Les français ressortent leurs raquettes pour taper la balle'
    , icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677165635/assets/presse/cnews.png' },
    { author: 'Grazia', review: 'Une alliée de choc pour nous (re)motiver à taper dans la balle'
    , icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677165635/assets/presse/grazia.png' },
    { author: 'Virgin radio', review: 'La solution incontournable pour jouer sans être licencié.',
      icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677165635/assets/presse/virginradio.png' },
    { author: 'La Voix du Nord', review: 'Pour les clubs, l’enjeu est vital. Anybuddy est opportun car ça permet de remplir des créneaux libres.',
      icon: 'https://res.cloudinary.com/anybuddy/image/upload/f_auto/v1677165635/assets/presse/lavoixdunord.png' }
];
