import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CountryData, COUNTRY_LIST, LANGUAGE_LIST, NewLanguageData, NEW_LANGUAGE_LIST } from '../../marketplace/models/language-data';
import { UserPreferenceService } from '../../marketplace/service/user-preferences.service';

@Component({
  selector: 'app-lang-choice',
  templateUrl: './lang-choice.component.html',
  styleUrls: ['./lang-choice.component.scss']
})
export class LangChoiceComponent implements OnInit {
  selectedLanguage: string;
  selectedCountry: any;
  selectedLang: any;
  langs = LANGUAGE_LIST;
  countries = COUNTRY_LIST.filter(country => country.name === 'France');
  newlang = NEW_LANGUAGE_LIST;

  showedLangList: NewLanguageData[];

  routePath: string;
  selectedLangFooter = '';

  @Input() flagOnly = false;
  @Input() idSelector1 = 'dropdownCountry';
  @Input() idSelector2 = 'dropdownLangage';
  @Input() footer = false;
  @Input() yellow = false;
  @Output() langLocal = new EventEmitter<string>();

  constructor(
    private router: Router,
    private userPreferenceService: UserPreferenceService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.langs = LANGUAGE_LIST;
  }

  ngOnInit(): void {
    this.userPreferenceService.getChoosedCountry().subscribe(
      value => {
        this.selectedCountry = value;
        this.SetSelectedCountry(value.countryCode);
        this.getCountrySpokenLanguages(value.countryCode);
        this.changeHtmlCountryLangTag();
      });
    this.userPreferenceService.getChoosedLanguage().subscribe(
      value => {
        this.selectedLang = value;
        this.changeHtmlCountryLangTag();
      });

    this.selectedLanguage = this.userPreferenceService.getLocale();
  }

  changeHtmlCountryLangTag(): void {
    if (this.selectedLang && this.selectedCountry) {
      this.document.documentElement.lang = this.selectedLang.languageCode + '-' + this.selectedCountry.countryCode;
    }
  }

  onSwitchCountry(code: string): void {
    this.selectedLangFooter = code;
    this.SetSelectedCountry(code);
    this.setDefaultLanguageFromCountry(code);
    this.getCountrySpokenLanguages(code);

    this.userPreferenceService.changeChoosedCountry(this.selectedCountry);
    this.userPreferenceService.changeChoosedLanguage(this.selectedLang);
    this.userPreferenceService.setLocale(
      this.selectedLang.languageCode,
      this.selectedLang.trueLanguageCode,
      this.selectedCountry.countryCode
    );
    this.selectedLangFooter = code;
    this.changeHtmlCountryLangTag();
    this.redirectToHome(this.selectedCountry.countryCode.toLowerCase());
  }

  SetSelectedCountry(countryCode: string): void {
    countryCode = countryCode.toUpperCase();
    this.countries.filter(f => f.countryCode === countryCode).map(country => this.selectedCountry = country);
  }

  setDefaultLanguageFromCountry(countryCode: string): void {
    const langs = this.newlang.filter(lang => lang.countryCode.length === 1 && lang.countryCode[0] === countryCode);
    this.selectedLang = langs[0];
  }

  getCountrySpokenLanguages(countryCode: string): void {
    countryCode = countryCode.toUpperCase();
    this.showedLangList = [];
    this.newlang.forEach(lang => {
      if (lang.countryCode.includes(countryCode)) {
        this.showedLangList.push(lang);
      }
    });
  }


  onSwitchLang(trueLanguageCode: string): void {
    this.newlang.filter(f => f.trueLanguageCode === trueLanguageCode).map(lang => this.selectedLang = lang);
    this.userPreferenceService.changeChoosedLanguage(this.selectedLang);
    this.userPreferenceService.setLocale(
      this.selectedLang.languageCode,
      this.selectedLang.trueLanguageCode,
      this.selectedCountry.countryCode
    );
    this.changeHtmlCountryLangTag();
  }

  redirectToHome(countryCde: string): void {
    const country = countryCde === 'fr' ? '' : countryCde;
    this.router.navigate(['/' + country]);
  }

  isCountryChosen(country: CountryData): boolean {
    return country.countryCode === this.selectedLangFooter;
  }

  isLangageChosen(lang: NewLanguageData): boolean {
    return lang.languageCode === this.selectedLang.languageCode;
  }
}
