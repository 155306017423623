<app-top-navbar [idSelector1Langage]="'dropdownCountry20'"
                [idSelector2Langage]="'dropdownLangage20'"
                [isTopBarFixed]="false"
                [isMobileResolution]="isMobileResolution"
                [country]="country"
                [yellow]="false">
  <div class="d-flex" *ngIf="!isMobileResolution" app-top-navbar-right>
    <button class="navbar-btn navbar-btn-light my-3 " (click)="downloadApp()">
      {{'menuComponent.download_app' | translate}}
    </button>
    <a [routerLink]="['/' + language, 'on-boarding']" style="text-decoration: none">
      <button class="navbar-btn navbar-btn-light me-0 my-3" routerLinkActive="active">
        {{'menuComponent.add_your_center' | translate}}
      </button>
    </a>
    <app-button-account-disconnected [isMobileResolution]="isMobileResolution" [yellow]="false"></app-button-account-disconnected>
  </div>

  <div class="d-flex top-nav-bar" *ngIf="isMobileResolution" app-top-navbar-right>
    <app-button-account-disconnected [isMobileResolution]="isMobileResolution" [yellow]="false"></app-button-account-disconnected>
    <button class="navbar-btn navbar-btn-light" (click)="downloadApp()">
      {{'menuComponent.download_app' | translate}}
    </button>
    <a [routerLink]="['/' + language, 'on-boarding']" class="no-link-underline">
      <div class="lang mt-2" >
        <div class="d-flex justify-content-between">
          <div class="my-3">
                <span class="font-weight-bold no-link-underline" style="font-size: 1.3rem">
                  {{'menuComponent.add_your_center' | translate}}
                </span>
          </div>
          <img class="ms-2" alt="Pro imgage" width="50" height="50" class="ps-1" ngSrc="v1677166288/assets/home/pro-img.PNG">
        </div>
      </div>
    </a>
  </div>
</app-top-navbar>


<app-community></app-community>

<div class="d-flex flex-column section-5-div"
     [ngClass]="{'mt-5': isMobileResolution, 'mt-2 mb-3 mx-5 px-5 ': !isMobileResolution}">
  <app-footer [isMobileResolution]="isMobileResolution"></app-footer>
</div>


<ng-template #downlodApp let-modal>
  <div class="modal-header">
    <div class="filtre"></div>
    <button type="button"style="
      background: white;
      border: aliceblue;
  " aria-label="Close" (click)="onCloseModal(modal)"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"/></svg></button>
  </div>
  <div class="modal-body">
    <div class="buddyText">
      <img width="30%" src="assets/images/buddy_app.svg">
    </div>
    <div class='textPoup'>{{'menuComponent.download_app_store' | translate}}</div>
    <div class="d-flex" style="margin-bottom: 14%; margin-left: 5%;">
      <a (click)="downloadAppClick()" href="{{'store.app_store_link' | translate}}" target="_blank" rel="noopener noreferrer"
         [ngClass]="{'me-3 ms-3': isMobileResolution, 'me-4': !isMobileResolution}">
        <img class="store-img-footer" alt="App Store" loading="lazy" src="{{'store.app_store_badge' | translate}}" width="564" height="168">
      </a>
      <a (click)="downloadAppClick()" href="{{'store.play_store_link' | translate}}" target="_blank"
         rel="noopener noreferrer">
        <img class="store-img-footer" alt="Play Store" loading="lazy" src="{{'store.play_store_badge' | translate}}" width="564" height="168">
      </a>
    </div>
  </div>
