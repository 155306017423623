import { Component, Input, OnInit } from '@angular/core';
import { UserPreferenceService } from '../service/user-preferences.service';

@Component({
  selector: 'app-button-account-disconnected',
  templateUrl: './button-account-disconnected.component.html',
  styleUrls: ['./button-account-disconnected.component.scss']
})
export class ButtonAccountDisconnectedComponent implements OnInit {

  @Input() country: string;
  @Input() border = true;
  @Input() yellow = false;
  @Input() isMobileResolution:boolean;
  language = '';
  errorMsg: string;

  constructor(
    private userPreferenceService: UserPreferenceService,
  ) {
  }

  ngOnInit(): void {
    this.country = this.userPreferenceService.getCountry();
    if (this.country !== 'fr') {
      this.language = this.country;
    }
    this.userPreferenceService.getlocalChangeCountry().subscribe(
      value => {
        this.country = value;
        if (this.country !== 'fr') {
          this.language = this.country;
        }
      });
  }
}
