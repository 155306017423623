<div *ngIf="isMobileResolution" class="d-flex justify-content-center mx-3" (click)="openLangageModal(content)">
  <div class="d-flex justify-content-center">
    <svg class="my-4 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="35" height="35">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z" />
    </svg>
    <div class="my-4 mx-auto">
      <span class="font-weight-bold" style="font-size: 1.3rem">
        {{
        'languagesCountriesLang.' + languageChoosed.languageCode | translate
        }}
        ({{ languageChoosed.trueLanguageCode | uppercase }})
      </span>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close p-0" (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <app-lang-choice [footer]="true"></app-lang-choice>
  </div>
</ng-template>
