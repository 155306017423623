<div class="d-flex flex-column mx-4"
[ngClass]="{'mt-4': !isMobileResolution,  'mt-4 ': isMobileResolution}">
<h3 class="expansion-header mb-4 card-title"
    [ngClass]="{'mt-3': !isMobileResolution}">
    {{'center.amenities' | translate}}
</h3>
<div class="d-flex flex-column mb-3 grey-text"
    *ngIf="isMobileResolution; else amenitiesDesktop">
    <div *ngIf="amenities.length > 0; else no_amenities">
        <ng-container *ngFor="let amenity of amenities">
            <div class="d-flex justify-content-between mb-3">
                <h4 class="card-subtitle align-self-center" *ngIf='amenity && amenity!==null'>
                    {{ 'amenities.' + amenity.code | translate }}</h4>
              <img class="amenity-img custom-img align-self-center"
                   alt="{{ 'amenities.' + amenity.code | translate }}" src="/assets/images/amenities/{{amenity.code}}.svg">

            </div>
        </ng-container>
    </div>
    <ng-template #no_amenities>
        {{'center.no_amenities' | translate}}
    </ng-template>
</div>
<ng-template #amenitiesDesktop>
    <div class="mb-3 grey-text">
        <div *ngIf="amenities.length > 0; else no_amenities">
            <div class="mx-4 mt-3 mb-5">
                <div class="row">
                    <ng-container *ngFor="let amenity of amenities; let i = index">
                        <div class="col-md-3 mb-3">
                            <div class="d-flex" *ngIf="amenity">
                                <h4 class="card-subtitle align-self-center me-2">
                                    {{ 'amenities.' + amenity.code | translate }}
                                </h4>
                                <img class="amenity-img custom-img align-self-center"
                                    alt="{{ 'amenities.' + amenity.code | translate }}"
                                    src="/assets/images/amenities/{{amenity.code}}.svg">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <ng-template #no_amenities>
            <span class="card-subtitle">
                {{'center.no_amenities' | translate}}
            </span>
        </ng-template>
    </div>
</ng-template>
</div>
