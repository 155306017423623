<div style="min-height: 40vh" class="container p-0">
  <div *ngIf="!finished">
    <div  class="d-flex">
      <div class="m-auto text-center">{{'joinResa.waitMsg' | translate}}</div>
    </div>
    <div  class="d-flex mt-5">
      <div class="m-auto loader"></div>
    </div>
  </div>
  <div *ngIf="error" class="mt-5">
    <div class="d-flex mt-5">
      <span class="m-auto text-center">{{'joinResa.errorMsg' | translate}}</span>
    </div>
    <div  class="d-flex mt-5">
      <span class="error m-auto text-center">{{error}}</span>
    </div>
  </div>

  <div class="mt-5 d-flex">
    <button class="btn-rounded m-auto" (click)="goHome()">{{'joinResa.quit' | translate}}</button>
  </div>

</div>
